<div class="pt-5 vstack">
  <div class="d-flex bg-dark justify-content-between bd-highlight">
    <div class="p-2 text-white font-weight-bold">Developer Build</div>
    <div class="p-2">
      <span class="text-white">{{ (environmentKey$ | async) + ' ' }}</span>
      <!-- <a class="text-success"
         (click)="open()">[Change Environment]</a> -->
    </div>
  </div>

  <ng-template #environmentModal
               let-modal>
    <div class="modal-body vstack gap-1">

      @for(environment of environments.keys(); track environment) {
      <app-button (clicked)="environmentSelected(environment)"
                  use="block"
                  [label]="environment"></app-button>
      }

    </div>
  </ng-template>
</div>
