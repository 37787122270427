import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { DEMO_MODE_ACTIONS } from '@store/app';

@Component({
  standalone: true,
  selector: 'app-demo-mode-modal',
  templateUrl: './demo-mode-modal.component.html',
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoModeModalComponent implements AfterViewInit {
  @ViewChild('demoModeModal', { static: false }) modal!: any;

  modalRef: NgbModalRef | undefined;

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalRef!.close();
    this.store.dispatch(DEMO_MODE_ACTIONS.resetDemoModeModal());
  }
}
