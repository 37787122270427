import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DEMO_MODE_ACTIONS } from '@store/app';
import { selectIsDemoUser } from '@store/digi.me';
import { filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkInterceptorService {
  constructor(private store: Store) {}

  init(): void {
    this.store
      .select(selectIsDemoUser)
      .pipe(
        filter((isDemoUser: boolean | undefined) => isDemoUser === true),
        take(1),
      )
      .subscribe(() => {
        document.addEventListener('click', this.handleDemoUserClick.bind(this));
      });
  }

  private handleDemoUserClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const tag = 'A';
    const attribute = 'href';
    const closestAnchor = clickedElement.closest(tag);

    const isLink: boolean =
      (clickedElement?.tagName === tag && clickedElement.hasAttribute(attribute)) ||
      (closestAnchor?.tagName === tag && closestAnchor.hasAttribute(attribute));

    if (isLink) {
      event.preventDefault();
      this.store.dispatch(DEMO_MODE_ACTIONS.showDemoModeModal());
    }
  }

  isLinkWithHref(element: HTMLElement | Element | null): boolean {
    return element?.tagName === 'A' && element.hasAttribute('href');
  }
}
