import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { selectShowForceUpdateModal, selectUpdateUrl } from '@store/digi.me';
import { filter, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-force-update-modal',
  imports: [SharedModule, NgbModalModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class ForceUpdateModalComponent implements OnInit {
  readonly showForceUpdateModal$ = this.store.select(selectShowForceUpdateModal);
  readonly updateUrl$ = this.store.select(selectUpdateUrl);

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.showForceUpdateModal$
      .pipe(
        filter((show) => show),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.showConfirm();
      });
  }

  showConfirm = async () => {
    await Dialog.alert({
      title: $localize`:@@app.update.forced.title:Please update now`,
      message: $localize`:@@app.update.forced.description:This version is no longer supported.`,
      buttonTitle: $localize`:@@app.update.forced.button:Update now`,
    }).finally(() => {
      this.updateUrl$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe((url) => {
        Browser.open({ url }).finally(() => {
          this.showConfirm();
        });
      });
    });
  };
}
