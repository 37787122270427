<ng-template #demoModeModal
             let-modal>
  <div class="modal-header d-flex flex-column align-items-start">
    <div class="d-flex flex-row w-100 justify-content-between">
      <mat-icon fontIcon="report_problem"></mat-icon>
    </div>

    <h5 class="modal-title font-weight-bold"
        id="modal-basic-title"
        i18n="@@app.dialog.demoError.title">Not available in demo mode</h5>
  </div>
  <div class="modal-footer d-flex flex-column">
    <app-button type="secondary"
                use="block"
                class="w-100"
                label="OK"
                i18n-label="@@app.dialog.demoError.btn"
                (clicked)="closeModal()"></app-button>
  </div>
</ng-template>