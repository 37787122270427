{
    "locale": "nl",
    "translations": {
    "App.Docs.Date": "Datum",
    "App.Docs.Details": "Gegevens",
    "App.Docs.Manifest.Author": "Auteur",
    "App.Docs.Manifest.Created": "Datum",
    "App.Docs.Manifest.Source": "Bron",
    "App.Docs.Manifest.Status": "Status",
    "App.Docs.Manifest.Subject": "Patiënt",
    "App.Docs.Manifest.Type": "Type",
    "App.Docs.Reference.Author": "Auteur",
    "App.Docs.Reference.Class": "Klasse",
    "App.Docs.Reference.Created": "Datum",
    "App.Docs.Reference.Download": "Download",
    "App.Docs.Reference.FileLocation": "Locatie",
    "App.Docs.Reference.SecurityLabel": "Beveiliging",
    "App.Docs.Reference.Source": "Aanbieder",
    "App.Docs.Reference.Status": "Status",
    "App.Docs.Reference.Subject": "Patiënt",
    "App.Docs.Reference.Type": "Type",
    "English": "Dutch",
    "Key": "en-NL",
    "account.createdOn": "Account aangemaakt op",
    "app.AlcoholUse.title": "Alcoholgebruik",
    "app.Alerts": "Alerts",
    "app.AllergyIntolerance.reaction-empty": "Geen reacties aanwezig",
    "app.AllergyIntolerance.reactions.empty": "Geen reacties aanwezig",
    "app.AllergyIntolerance.reactions.header": "Reacties",
    "app.AllergyIntolerances.title": "Allergie \/ Intolerantie",
    "app.Appointment.title": "Afspraak",
    "app.Appointments.title": "Afspraken",
    "app.Condition": "Problemen en Diagnosen",
    "app.ConditionEvidences.header": "Ondersteunend bewijs",
    "app.ConditionStages.header": "Stadia",
    "app.ConsentExcept.action": "Actie",
    "app.ConsentExcept.class": "Klasse",
    "app.ConsentExcept.code": "Code",
    "app.ConsentExcept.dataPeriod": "Gegevens Periode",
    "app.ConsentExcept.exceptType": "Type uitzondering",
    "app.ConsentExcept.purpose": "Doel",
    "app.ConsentExcept.securityLabel": "Beveiligingslabel",
    "app.Consents.title": "Toestemmingen",
    "app.ContactSupport": "Neem contact op met ondersteuning",
    "app.Devices.title": "Medische hulpmiddelen",
    "app.Documents.title": "Documenten",
    "app.Dosages.header": "Gebruiksinstructie",
    "app.DrugUse.title": "Drugsgebruik",
    "app.Encounter.title": "Contact",
    "app.Encounters.title": "Contacten",
    "app.Error.message": "Als u vastzit, neem dan contact op met ondersteuning en we zullen ons best doen om u te helpen of probeer later opnieuw.",
    "app.Error.onboard.message": "Er is iets misgegaan. Probeer het opnieuw. Als het probleem blijft bestaan, wacht een uur en probeer het opnieuw. Neem indien nodig contact op met onze supportteam.",
    "app.Error.onboard.title": "Kan uw gegevens niet koppelen",
    "app.Error.onboard.userCancel.button.cancel": "Annuleren",
    "app.Error.onboard.userCancel.button.request": "Uw Zorgverlener Aanvragen",
    "app.Error.onboard.userCancel.message": "We voegen elke week steeds meer zorgverleners toe. Als u die van u niet kon vinden, laat het ons weten en we zullen proberen het te versnellen.",
    "app.Error.onboard.userCancel.title": "Kon uw zorgverlener niet vinden?",
    "app.Error.title": "Er is iets misgegaan",
    "app.Flag.title": "Vlag",
    "app.Flags.title": "Alerts",
    "app.FunctionalOrMentalStatus.title": "Functionele of Mentale Status",
    "app.HealthInsurances.title": "Zorgverzekeringen",
    "app.HealthProfessional.title": "Zorgverlener",
    "app.HealthcareProviders.title": "Zorgaanbieders",
    "app.HomePage.title": "Startpagina",
    "app.Identifier.header": "Identificatienummer",
    "app.Identifiers.header": "Identificatienummer",
    "app.ImagingStudy.accession": "Invoering",
    "app.ImagingStudy.header": "Beeldvormend onderzoek",
    "app.Input.customPeriodDateSelector": "Kies de periode die u wilt delen",
    "app.InputElement.chooseFromDate": "Kies een vanaf datum",
    "app.InputElement.chooseToDate": "Kies een tot datum",
    "app.InputElement.customPeriodDateSelector": "Of selecteer een aangepaste periode",
    "app.InputElement.predefinedPeriodDateMonth": "Deze Maand",
    "app.InputElement.predefinedPeriodDateWeek": "Deze Week",
    "app.InputElement.predefinedPeriodDateYear": "Dit Jaar",
    "app.Intoxications.title": "Intoxicaties",
    "app.Lifestyle.title": "Levensstijl",
    "app.LinkedSources.ConnectButton": "Verbinden",
    "app.LinkedSources.FindProvider": "Zoek jouw zorgverleners",
    "app.LinkedSources.FitnessDescription": "Voeg fitnessapps toe die je al gebruikt om je gezondheidsdata automatisch bij te werken.",
    "app.LinkedSources.FitnessTitle": "Fitness & welzijn",
    "app.LinkedSources.MedicalRecordsTitle": "Medische gegevens",
    "app.LinkedSources.intro": "Breng al je medische gegevens en fitnessdata samen om jouw veilige, universele gezondheidsdossier te creëren.",
    "app.LinkedSources.linkToAnother": "Koppel een andere gegevensbron",
    "app.LinkedSources.logs": "Logboeken",
    "app.LinkedSources.noSourcesText": "Importeer medische gegevens van meer dan 5000 zorgverleners via onze partner, MedMij. Vind jouw zorgverleners en geef veilige toegang met DigiD.",
    "app.LinkedSources.permissions": "Toestemmingen",
    "app.LinkedSources.reauthMessage": "Niet mogelijk om te vernieuwen. Tik om toegang te vernieuwen.",
    "app.LinkedSources.reauthorise": "Verbinden",
    "app.LinkedSources.reauthorizeText": "Als je vaak wordt gevraagd om opnieuw te verbinden, ga dan naar Toegang Beheren en schakel Doorlopende Toegang in voor de betreffende bron.",
    "app.LinkedSources.refresh": "Ververs",
    "app.LinkedSources.refreshAll": "Controleer op nieuwe gegevens",
    "app.LinkedSources.refreshText.attention": "Opnieuw inloggen vereist",
    "app.LinkedSources.refreshText.beforeDay": "Ververst",
    "app.LinkedSources.refreshText.beforeTime": "om",
    "app.LinkedSources.refreshText.missingData": "met mogelijk ontbrekende gegevens",
    "app.LinkedSources.refreshText.paused": "Aanbieder momenteel niet beschikbaar",
    "app.LinkedSources.refreshText.refreshed": "Vernieuwd",
    "app.LinkedSources.refreshText.refreshing": "Vernieuwen",
    "app.LinkedSources.refreshText.warning": "Vernieuwd met waarschuwingen",
    "app.LinkedSources.refreshing": "Vernieuwen...",
    "app.LinkedSources.remove": "Verwijder",
    "app.LinkedSources.remove.info": "Dit verwijdert de bron en de bijbehorende gegevens uit uw account, niet uit de bron zelf.",
    "app.LinkedSources.removeAll": "Alles verwijderen",
    "app.LinkedSources.removeDescription": "Verwijder de koppeling met elk verbonden account en verwijder alle bijbehorende gegevens uit uw digi.me-account.",
    "app.LinkedSources.sourceNotAvailable": "Deze zorgverlener deelt momenteel geen dossiers.",
    "app.LinkedSources.startOver": "Wilt u opnieuw beginnen?",
    "app.LinkedSources.title": "Bronnen",
    "app.LinkedSources.viewErrors": "Bekijk fouten",
    "app.LivingSituation.title": "Woonsituatie",
    "app.NetworkStatus.appNotWork": "Sommige delen van de app werken mogelijk niet zoals verwacht",
    "app.NetworkStatus.noInternet": "Geen internetverbinding gedetecteerd",
    "app.Notifications.header": "Activiteit",
    "app.NutritionAdvice.title": "Voedingsadvies",
    "app.NutritionOrderOralDietComponent.Schedule": "Schema",
    "app.NutritionOrderSupplementComponent.Schedule": "Schema",
    "app.OnboardOrLogIn": "Aanmelden of Inloggen",
    "app.OnboardOrLogIn.description": "De eenvoudigste, meest veilige manier om controle te krijgen over uw gezondheidsgegevens.",
    "app.OnboardOrLogIn.logInButton": "Inloggen",
    "app.OnboardOrLogIn.logInText": "Log nu in om uw gegevens te bekijken.",
    "app.OnboardOrLogIn.logInTitle": "Heeft u een digi.me-account?",
    "app.OnboardOrLogIn.moreButton": "of lees onze handleiding om te beginnen",
    "app.OnboardOrLogIn.onboardButton": "Maak uw account aan",
    "app.OnboardOrLogIn.onboardText": "Vind uw zorgverlener → DigiD Inloggen → Toestemming geven → Beveilig uw digi.me account met e-mail & sms → Verzamel zorggegevens",
    "app.OnboardOrLogIn.onboardTitle": "Nieuw bij digi.me?",
    "app.OnboardOrLogIn.yourRecords": "Welkom bij digi.me",
    "app.Organization.contact.empty": "Geen contact aanwezig",
    "app.Organization.contact.header": "Contactgegevens",
    "app.Organization.contacts": "Contacten",
    "app.Organization.contacts.empty": "Geen contacten aanwezig",
    "app.PatientContacts.title": "Contactpersonen",
    "app.PatientDetails.title": "Patiëntgegevens",
    "app.PortabilityMenuItem": "Portabiliteitsrapport",
    "app.PortabilityReport.chooseFromDate": "Kies een begindatum",
    "app.PortabilityReport.chooseToDate": "Kies een einddatum",
    "app.PortabilityReport.periodMonthText": "Deze Maand",
    "app.PortabilityReport.periodWeekText": "Deze Week",
    "app.PortabilityReport.periodYearText": "Dit Jaar",
    "app.PortabilityReport.selectCustomPeriod": "Of selecteer een periode naar eigen voorkeur",
    "app.PortabilityReport.title": "Portabiliteitsrapport",
    "app.PrivacyPolicy.title": "Privacybeleid",
    "app.ProcedureFocalDevices": "Implantaten",
    "app.ProcedurePerformers": "Uitvoerders",
    "app.ReportBug": "Meld een bug",
    "app.Settings.header": "Accountinstellingen",
    "app.ShareMeasurement.header": "Deel uw zelfmetingen",
    "app.ShareMeasurements.MonthText": "Deze maand",
    "app.ShareMeasurements.PreviewHeader": "Hier is een voorbeeld van de gegevens die u zult delen",
    "app.ShareMeasurements.WeekText": "Deze week",
    "app.ShareMeasurements.YearText": "Dit jaar",
    "app.ShareMeasurements.buttonSave": "Of sla op naar uw apparaat",
    "app.ShareMeasurements.buttonSaveHelpText": "Dit zal een .JSON-bestand van uw metingen opslaan",
    "app.ShareMeasurements.buttonSelect": "Selecteer uw zorgverlener",
    "app.ShareMeasurements.buttonSelectHelpText": "U wordt gevraagd om uw ziekenhuis te selecteren op het volgende scherm",
    "app.ShareMeasurements.chooseFromDate": "Kies een begindatum",
    "app.ShareMeasurements.chooseToDate": "Kies een einddatum",
    "app.ShareMeasurements.description": "Alleen de metingen die u zelf hebt ingevoerd, worden gedeeld.",
    "app.ShareMeasurements.header": "Deel uw zelf gemeten gegevens",
    "app.ShareMeasurements.select": "Kies de tijdsperiode die u wilt delen",
    "app.ShareMeasurements.selectCustomPeriod": "Of selecteer een aangepaste periode",
    "app.ShareProgressModal.failed.text": "Verzenden mislukt naar",
    "app.ShareProgressModal.failed.tryAgain": "Probeer het later opnieuw",
    "app.ShareProgressModal.sending.text": "Verzenden naar",
    "app.ShareProgressModal.sent.text": "Succesvol verzonden naar",
    "app.SourceType.appleHealth": "Apple Gezondheid",
    "app.SourceType.fitbit": "Fitbit",
    "app.SourceType.garmin": "Garmin",
    "app.SourceType.googleFit": "Google Fit",
    "app.SourceType.manualEntry": "Handmatige Invoer",
    "app.SourceType.withings": "Withings",
    "app.TermsAndConditions.title": "Algemene Voorwaarden",
    "app.TimingRepeats.header": "Herhaal schema",
    "app.Timings.header": "Tijdsschema Inname \/ Toediening",
    "app.TobaccoUse.title": "Tabaksgebruik",
    "app.TryAgain": "Probeer opnieuw",
    "app.Vaccinations.title": "Vaccinaties",
    "app.account.dangerZone.description": "Het verwijderen van uw account zal:\\n\\n* uw zorgverleners ontkoppelen\\n* uw zelfmetingen verwijderen\\n* uw naam, telefoonnummer en e-mailadres uit ons systeem verwijderen\\n\\nU kunt op elk moment een nieuw account aanmaken.",
    "app.account.dangerZone.subtitle": "Als u niet langer digi.me wilt gebruiken om uw medische gegevens te bekijken, kunt u uw account permanent verwijderen.",
    "app.account.dangerZoneTitle": "Gevaarlijke Zone",
    "app.account.displayName": "Weergavenaam",
    "app.account.portabilityDetails": "Maak gebruik van uw recht op gegevensportabiliteit door uw Portabiliteitsrapport te genereren. Dit produceert een XML-bestand dat u kunt gebruiken om over te stappen naar een ander PGO.",
    "app.account.portabilityReport": "Rapport genereren",
    "app.account.portabilityReportTitle": "Gegevensportabiliteit",
    "app.account.portabilitySubTitle": "Als een van de selecte gecertificeerde bedrijven van MedMij, zorgen we voor een privé en veilige toegang tot uw medische gegevens.",
    "app.addModal.addData": "Gegevens toevoegen",
    "app.addModal.cancel": "Annuleren",
    "app.addModal.fitnessTitle": "Welke fitnessdienst wil je koppelen?",
    "app.addModal.linkData": "Gegevens koppelen",
    "app.addModal.measurement": "Meting",
    "app.addModal.measurementTitle": "Lichaamsmeting toevoegen",
    "app.addModal.reading": "Vitale meting",
    "app.addModal.readingTitle": "Vitale meting toevoegen",
    "app.addModal.records": "Medische gegevens",
    "app.addModal.recordsOptionGP": "Huisartsdossier",
    "app.addModal.recordsOptionHospital": "Ziekenhuisdossier",
    "app.addModal.recordsOptionNotSure": "Niet zeker",
    "app.addModal.recordsOptionVaccine": "Vaccinatieregister",
    "app.addModal.recordsTitle": "Welk type medisch dossier wil je koppelen?",
    "app.addModal.service": "Fitnessdienst",
    "app.addresses.empty": "Geen adresgegevens aanwezig",
    "app.addresses.header": "Adressen",
    "app.administration-agreement.title": "Toedieningsafspraak",
    "app.administrationAgreement.title": "Toedieningsafspraak",
    "app.ago-date.days-ago": "dagen geleden",
    "app.ago-date.today": "vandaag",
    "app.ago-date.yesterday": "gisteren",
    "app.alcohol-use.title": "Alcoholgebruik",
    "app.allergyIntolerance.title": "Allergie \/ Intolerantie",
    "app.allergyIntolerances.title": "Allergie \/ Intoleranties",
    "app.altText.sourceList.attention": "Opnieuw inloggen vereist",
    "app.altText.sourceList.paused": "Aanbieder momenteel niet beschikbaar",
    "app.altText.sourceList.refreshed": "Vernieuwd",
    "app.altText.sourceList.refreshing": "Vernieuwen",
    "app.altText.sourceList.warning": "Vernieuwd met waarschuwingen",
    "app.animation.unlockingAndLoadingYourRecords": "Ontgrendelen en laden van uw gegevens",
    "app.animation.upToDate": "Alle gegevens geladen",
    "app.annotations.empty": "Geen notities aanwezig",
    "app.annotations.header": "Notities",
    "app.appointment.title": "Afspraak",
    "app.appointments.title": "Afspraken",
    "app.ariaLabel.menu.add": "Toevoegen",
    "app.ariaLabel.menu.help": "Hulp",
    "app.ariaLabel.menu.sources": "Opties",
    "app.bloodPressure.title": "Bloeddruk",
    "app.bodyHeight.title": "Lichaamslengte",
    "app.bodyTemperature.title": "Lichaamstemperatuur",
    "app.bodyWeight.title": "Lichaamsgewicht",
    "app.btn.delete.measurement": "Verwijder deze meting",
    "app.btn.delete.measurement.cancel": "Annuleren",
    "app.btn.delete.measurement.confirm": "Verwijderen",
    "app.btn.delete.measurement.deleting": "Bezig met verwijderen…",
    "app.button.addMeasurement": "Meting toevoegen",
    "app.button.cancel": "Annuleren",
    "app.button.changeEmail": "Bewerken",
    "app.button.changeNotifications": "Uitschakelen",
    "app.button.changePassword": "Bewerken",
    "app.button.changePhone": "Bewerken",
    "app.button.close": "Sluiten",
    "app.button.deleteAccount": "Account verwijderen",
    "app.button.dismiss": "Sluiten",
    "app.button.help": "Helpcentrum",
    "app.button.ok": "Oké",
    "app.button.privacyPolicy": "Privacybeleid",
    "app.button.reportIssue": "Meld een probleem",
    "app.button.termsOfUse": "Gebruiksvoorwaarden",
    "app.clearingAllData": "Alle geïmporteerde versleutelde gegevens worden verwijderd...",
    "app.column.advanceDirective": "Wilsverklaring",
    "app.column.date": "Datum",
    "app.column.heart": "Hart",
    "app.column.pulse": "Pols",
    "app.column.rate": "Frequentie",
    "app.column.role": "Rol",
    "app.column.treatmentDirective": "Behandel Aanwijzing",
    "app.column.type": "Type",
    "app.composition-relates-to-target-identifier-header": "Relatie tot Identificatie",
    "app.composition.title": "SOEP verslag",
    "app.condition.evidences.empty": "Geen ondersteunend bewijs aanwezig",
    "app.condition.evidences.header": "Ondersteunend bewijs",
    "app.condition.stages.empty": "Geen stadia aanwezig",
    "app.condition.stages.header": "Stadia",
    "app.consent-actor.empty": "Geen vertegenwoordiger aanwezig",
    "app.consent-actor.header": "Vertegenwoordiger",
    "app.consent-data.empty": "Geen gegevens aanwezig",
    "app.consent-data.header": "Gegevens",
    "app.consent-except.empty": "Geen uitzonderingen aanwezig",
    "app.consent-except.header": "Uitzonderingen",
    "app.consent-policy.empty": "Geen regelgeving aanwezig",
    "app.consent-policy.header": "Regelgeving",
    "app.consent.title": "Toestemming",
    "app.consentActors.header": "Vertegenwoordiger",
    "app.consentData.header": "Toestemming Gegevens",
    "app.consentExcepts.header": "Uitzonderingen",
    "app.consentPolicies.header": "Regelgeving",
    "app.consents.title": "Toestemmingen",
    "app.contactPoints.empty": "Geen contactgegevens aanwezig",
    "app.contactPoints.header": "Contactgegevens",
    "app.coverage.organization": "Organisatie",
    "app.delete-account.cancel": "Annuleren",
    "app.delete-account.close": "Sluiten",
    "app.delete-account.delete": "Verwijder mijn account",
    "app.delete-account.deleted": "Uw account is verwijderd.",
    "app.delete-account.deleting": "Bezig met verwijderen...",
    "app.delete-account.header": "Wilt u uw digi.me-account verwijderen?",
    "app.delete-account.warning": "Dit zal alle geïmporteerde gegevens verwijderen, links naar uw bronnen verwijderen en uw digi.me-account verwijderen.",
    "app.details": "Details",
    "app.details.close": "Sluit",
    "app.details.empty": "Geen details aanwezig",
    "app.details.showDetails": "Laat details zien",
    "app.device.title": "Implantaat",
    "app.devices.title": "Medische hulpmiddelen",
    "app.diagnose.title": "Probleem en Diagnose",
    "app.diagnoses.title": "Problemen en Diagnosen",
    "app.diagnosis.title": "Diagnose",
    "app.diagnostic-report.performers.header": "Uitvoerders",
    "app.diagnosticReport.title": "Diagnostische rapportage",
    "app.dialog.biometrics.btn.no": "Blijf handmatig inloggen",
    "app.dialog.biometrics.btn.yes": "Biometrie inschakelen",
    "app.dialog.biometrics.description": "Log in met je gezicht of vingerafdruk. Biometrie is sneller, veiliger en makkelijker dan telkens je e-mail, wachtwoord en verificatiecode in te voeren.",
    "app.dialog.biometrics.title": "Snelle en Veilige Inlog Inschakelen",
    "app.dialog.demoError.btn": "OK",
    "app.dialog.demoError.title": "Niet beschikbaar in demomodus",
    "app.dialog.portabilityReportFail.altText": "Downloadpictogram",
    "app.dialog.portabilityReportFail.btn": "OK",
    "app.dialog.portabilityReportFail.description": "Dit komt waarschijnlijk omdat je deze app nog niet aan een zorgverlener hebt gekoppeld.",
    "app.dialog.portabilityReportFail.title": "Geen rapport beschikbaar",
    "app.digiMeError.code": "Code",
    "app.digiMeError.error": "Fout",
    "app.disclaimer.medical": "Digi.me is geen vervanging voor professioneel medisch advies. Raadpleeg altijd eerst je arts.",
    "app.dosage.empty": "Geen doseringen aanwezig",
    "app.dosage.header": "Dosering",
    "app.drug-use.title": "Drugsgebruik",
    "app.encounter.title": "Contact",
    "app.encounters.title": "Contacten",
    "app.episode-of-care.title": "Episoden",
    "app.episodeOfCare.diagnoses.empty": "Geen diagnoses aanwezig",
    "app.episodeOfCare.diagnoses.header": "Diagnosen",
    "app.episodeOfCare.status-histories.empty": "Geen status geschiedenis aanwezig",
    "app.episodeOfCare.statuses-history.header": "Status Geschiedenis",
    "app.episodesOfCare.title": "Episoden",
    "app.error.error": "Fout",
    "app.error.errorCode": "Fout code:",
    "app.error.reference": "Referentie:",
    "app.error.serverCode": "Server code:",
    "app.faq": "Veelgestelde vragen",
    "app.feedback.widget.banner": "Neem contact met ons op",
    "app.feedback.widget.confirmation": "Bedankt. We nemen binnenkort contact met u op.",
    "app.feedback.widget.languagecode": "nl",
    "app.feedback.widget.launcher": "Hulp",
    "app.feedback.widget.reference": "Ref",
    "app.feedback.widget.reportbug": "Bug melden",
    "app.feedback.widget.reporterror": "Fout melden",
    "app.feedback.widget.requestProviderDescription": "Voeg hier de naam van uw zorgverlener toe:",
    "app.feedback.widget.requestProviderSubject": "Nieuwe Zorgverlener Aanvraag",
    "app.feedback.widget.submit": "Versturen",
    "app.footer.copyright": "digi.me is een product van",
    "app.footer.privacyPolicy": "Privacybeleid",
    "app.footer.termsOfUse": "Gebruiksvoorwaarden",
    "app.functionalOrMentalStatus.title": "Functionele of Mentale Status",
    "app.glucose.title": "Glucose",
    "app.grid.empty": "Geen invoer aanwezig",
    "app.grid.error": "Foutmelding",
    "app.grid.loading": "Laden...",
    "app.hamburger.wdx": "World Data Exchange B.V.",
    "app.hamburgerMenu.clearImportedRecords": "Verwijder geïmporteerde gegevens",
    "app.hamburgerMenu.dangerZone": "Gevarenzone",
    "app.hamburgerMenu.deleteAccount": "Verwijder uw account",
    "app.hamburgerMenu.logOut": "Uitloggen",
    "app.healthCareProvider.title": "Zorgaanbieder",
    "app.healthCareProviders.title": "Zorgaanbieders",
    "app.healthInsurance.title": "Zorgverzekering",
    "app.healthInsurances.title": "Zorgverzekeringen",
    "app.healthProfessional.title": "Zorgverlener",
    "app.healthcare-provider.title": "Zorgaanbieder",
    "app.healthcare-services.empty": "Geen zorgorganisaties aanwezig",
    "app.healthcare-services.header": "Zorgorganisatie",
    "app.heartAndPulseRate.title": "Hart- en polsfrequentie",
    "app.help": "Help",
    "app.helper.button.addFirstRecords": "Medische dossiers toevoegen",
    "app.helper.button.addMeasurement": "Voeg een zelfmeting toe",
    "app.helper.button.addRecords": "Voeg meer dossiers toe",
    "app.helper.button.back": "Terug",
    "app.helper.description.ComingVerySoon": "Binnenkort beschikbaar!",
    "app.helper.description.addMeasurement": "Heeft jouw huisarts je gevraagd om je gewicht, hartslag of bloeddruk bij te houden? Je kunt jouw zelfmetingen ook direct en privé met jouw huisarts delen.",
    "app.helper.description.addRecords": "Via onze samenwerking met Medmij kun je jouw medische dossiers importeren van meer dan 5.000 zorgverleners in Nederland. Je kunt zoveel zorgverleners toevoegen als je nodig hebt om jouw universele medisch dossier te creëren.",
    "app.helper.nothingToDisplay": "Niets weer te geven",
    "app.home.button.refresh": "Controleren op updates",
    "app.home.hello": "Hoi",
    "app.home.lastChecked": "Je hebt je gegevens bij je zorgverleners voor het laatst",
    "app.home.lastCheckedDaysAgo": "dagen geleden gecontroleerd.",
    "app.home.lastCheckedToday": "vandaag gecontroleerd.",
    "app.home.lastCheckedYesterday": "gisteren gecontroleerd.",
    "app.humanNames.empty": "Geen namen aanwezig",
    "app.humanNames.header": "Namen",
    "app.identifiers.empty": "Geen identifiers aanwezig",
    "app.imagingStudy.title": "Beeldvormend onderzoek",
    "app.imagings.title": "Beeldvorming",
    "app.immunization-reaction.empty": "Geen reacties aanwezig",
    "app.immunization-reaction.header": "Reactie",
    "app.immunization-recommendation-protocol.header": "Protocol",
    "app.immunization-recommendation-recommendation-date-criteria.empty": "Geen criteria aanwezig",
    "app.immunization-recommendation-recommendation-date-criteria.header": "Datum criteria",
    "app.immunization-recommendation-recommendation-date-criterion.header": "Datum criterium",
    "app.immunization-recommendation-recommendation.empty": "Geen aanbevelingen aanwezig",
    "app.immunization-recommendation-recommendation.header": "Aanbeveling",
    "app.immunization-recommendation-recommendations.header": "Aanbevelingen",
    "app.immunization-recommendation.title": "Vaccinatie aanbevelingen",
    "app.immunization.explanation.empty": "Geen uitleg aanwezig",
    "app.immunization.practioners.header": "Toediener",
    "app.immunization.practitioners.empty": "Geen toedieners aanwezig",
    "app.immunization.title": "Vaccinatie",
    "app.immunization.vaccinationProtocol.empty": "Geen protocollen aanwezig",
    "app.immunization.vaccinationProtocol.header": "Protocol",
    "app.immunization.vaccinationProtocols.header": "Gevolgde protocollen",
    "app.info.missing.header": "Sommige gegevens zijn mogelijk niet geïmporteerd",
    "app.info.missing.moreLink": "Meer informatie",
    "app.info.missing.partialDetails": "We waren niet in staat om al uw gegevens volledig te importeren vanuit ",
    "app.info.missing.partialHeader": "Gedeeltelijke synchronisatie",
    "app.info.missing.statement": "We blijven bij elke synchronisatie alle gegevens opvragen, dus deze kunnen op een later tijdstip verschijnen.",
    "app.info.missing.unsupportedDetails": " geeft momenteel geen toegang tot ",
    "app.info.missing.unsupportedHeader": "Niet-ondersteund",
    "app.input.additionalComment": "Aanvullende opmerkingen",
    "app.input.blood-glucose": "Bloedglucose (mmol\/L)",
    "app.input.blood-glucose.code": "Code",
    "app.input.blood-glucose.timing": "Tijdstip",
    "app.input.blood-oxygen": "Bloedzuurstof (%)",
    "app.input.blood-oxygen.administered-oxygen": "Toegediende Zuurstof",
    "app.input.blood-pressure.cuff-size": "Manchetmaat",
    "app.input.blood-pressure.diastolic": "Diastolisch (mmHg)",
    "app.input.blood-pressure.location": "Locatie",
    "app.input.blood-pressure.method": "Methode",
    "app.input.blood-pressure.position": "Positie",
    "app.input.blood-pressure.systolic": "Systolisch (mmHg)",
    "app.input.clothing": "Kleding",
    "app.input.date": "Datum",
    "app.input.heartRate": "Hartslag (BPM)",
    "app.input.height": "Lengte",
    "app.input.height.lying": "Liggend",
    "app.input.height.position": "Positie",
    "app.input.height.standing": "Staand",
    "app.input.method": "Methode",
    "app.input.optional": "(Optioneel)",
    "app.input.optional-additional-information": "Optionele aanvullende informatie",
    "app.input.respiration-rate.administered-oxygen": "Toegediende zuurstof",
    "app.input.respiration-rate.breaths-per-minute": "Ademhalingen per minuut",
    "app.input.respiration-rate.depth": "Diepte",
    "app.input.respiration-rate.flow-rate": "Stroomsnelheid (l\/min)",
    "app.input.respiration-rate.inspired-oxygen": "Geïnspireerde zuurstof",
    "app.input.respiration-rate.pattern": "Patroon",
    "app.input.respiration-rate.rhythm": "Ritme",
    "app.input.select": "- selecteer -",
    "app.input.temperature": "Temperatuur (°C)",
    "app.input.time": "Tijd",
    "app.input.unit": "Eenheid",
    "app.input.weight": "Gewicht (kg)",
    "app.ips.button": "Bekijk jouw Patiëntsamenvatting",
    "app.ips.componentHeader.allergies": "Allergieën en Intoleranties",
    "app.ips.componentHeader.contacts": "Contacten",
    "app.ips.componentHeader.diagnoses": "Diagnoses",
    "app.ips.componentHeader.medications": "Medicijnen",
    "app.ips.componentHeader.patientDetails": "Patiëntgegevens",
    "app.ips.componentHeader.procedures": "Procedures",
    "app.ips.componentHeader.results": "Resultaten",
    "app.ips.componentHeader.summaryDetails": "Samenvattende Gegevens",
    "app.ips.componentHeader.vaccines": "Vaccinaties",
    "app.ips.description": "Jouw Patiëntsamenvatting geeft zorgprofessionals een snel en betrouwbaar overzicht van jouw medische geschiedenis, met belangrijke details zoals noodcontacten, allergieën, medicijnen, vaccinaties en meer. Alles op één plek, zodat je de juiste zorg kunt krijgen wanneer je die nodig hebt.",
    "app.ips.generating": "Je patiëntsamenvatting maken met deze bronnen",
    "app.ips.pdf.count": "van",
    "app.ips.pdf.disclaimer": "De informatie in dit document bevat privacy gevoelige persoons- en medische gegevens. Zorg ervoor, dat je deze privacy gevoelige informatie veilig opslaat.",
    "app.ips.pdf.title": "Patiëntsamenvatting",
    "app.ips.pdf.wdx": "World Data Exchange B.V.",
    "app.ips.results.disclaimer": "Numerieke resultaten met een referentiewaarde die binnen die waarde vallen, worden niet weergegeven in het onderstaande overzicht.",
    "app.ips.summaryDetails.asOf": "Vanaf",
    "app.ips.summaryDetails.generatedDate": "Aangemaakt op",
    "app.ips.summaryDetails.sourceIntro": "Gebaseerd op gegevens van deze bronnen",
    "app.journal.title": "Journaal",
    "app.laboratory.title": "Laboratorium",
    "app.language.dutch": "Nederlands",
    "app.language.english": "Engels",
    "app.language.settings": "App-taal",
    "app.linked-source.refreshing": "Vernieuwen...",
    "app.linked-sources.menu.button.manageAccess": "Toegang beheren",
    "app.linked-sources.menu.button.remove": "Verwijderen",
    "app.linked-sources.menu.removeDescription": "Dit zal de bron en de bijbehorende gegevens uit uw account verwijderen, niet uit de bron zelf.",
    "app.living-situation.title": "Woonsituatie",
    "app.loading": "Bezig met laden",
    "app.logout": "Uitloggen",
    "app.logout.message": "U bent uitgelogd voor uw privacy en veiligheid.",
    "app.measurement.title": "Meting",
    "app.medical-aid.title": "Medische Hulpmiddelen",
    "app.medical-aids.title": "Medische Hulpmiddelen",
    "app.medicalServices.empty": "Geen invoer aanwezig",
    "app.medicalServices.error": "Foutmelding",
    "app.medicalServices.loading": "Bezig met laden",
    "app.medication-agreement.title": "Medicatieafspraak",
    "app.medication-agreements.title": "Medicatieafspraak",
    "app.medication-dispense.title": "Geneesmiddel Uitgifte",
    "app.medication-overview.administration-agreement": "Toedienings Afspraak",
    "app.medication-overview.agreements": "Medicatie Afspraak",
    "app.medication-overview.title": "Medicatie Overzicht",
    "app.medication-overview.use": "Medicatie Gebruik",
    "app.medication-request-dosage-header": "Doseerinstructies",
    "app.medication-request-dosage-subheader": "Instructies",
    "app.medication-request.title": "Recept",
    "app.medication-use.title": "Medicatiegebruik",
    "app.medication.title": "Medicatie",
    "app.medicationStatement.title": "Medicatiegebruik",
    "app.medicationUse.title": "Medicatiegebruik",
    "app.menu.Appointments": "Afspraken",
    "app.menu.Consent": "Toestemming",
    "app.menu.FunctionalOrMentalStatus": "Functionele of mentale status",
    "app.menu.HealthProfessional": "Zorgverleners",
    "app.menu.HealthcareProviders": "Zorgaanbieders",
    "app.menu.Insurance": "Zorgverzekering",
    "app.menu.LivingSituation": "Woonsituatie",
    "app.menu.Procedures": "Verrichtingen",
    "app.menu.allergyIntolerances": "Allergie \/ Intolerantie",
    "app.menu.bloodPressure": "Bloeddruk",
    "app.menu.bodyHeight": "Lichaamslengte",
    "app.menu.bodyTemperature": "Lichaamstemperatuur",
    "app.menu.bodyWeight": "Lichaamsgewicht",
    "app.menu.contactPersons": "Contactpersonen",
    "app.menu.details": "Gegevens",
    "app.menu.diagnoses": "Problemen en Diagnosen",
    "app.menu.documents": "Documenten",
    "app.menu.encounters": "Contacten",
    "app.menu.episodeOfCare": "Episoden",
    "app.menu.flags": "Alerts",
    "app.menu.glucose": "Glucose",
    "app.menu.gp-records": "Huisarts Dossier",
    "app.menu.healthCareProvider": "Zorgaanbieders",
    "app.menu.healthRecord": "Zorgdossiers",
    "app.menu.heartAndPulseRates": "Hart- en polsfrequentie",
    "app.menu.intoxications": "Middelengebruik",
    "app.menu.intoxications.alcoholUse": "Alcohol",
    "app.menu.intoxications.drugUse": "Drugs",
    "app.menu.intoxications.tobaccoUse": "Tabak",
    "app.menu.ips": "Patiënt­samenvatting",
    "app.menu.journal": "Journaal",
    "app.menu.laboratory": "Laboratorium",
    "app.menu.lifestyle": "Leefstijl",
    "app.menu.linkedSources": "Bronnen",
    "app.menu.measurement": "Metingen",
    "app.menu.medicalAids": "Medische Hulpmiddelen",
    "app.menu.medicalDevices": "Medische hulpmiddelen",
    "app.menu.medicalServices": "Gezondheidsgegevens uitwisseling",
    "app.menu.medication": "Medicatie",
    "app.menu.myDetails": "Gegevens",
    "app.menu.myHealthRecord": "Mijn zorgdossier",
    "app.menu.myMedicalRecord.results.imaging": "Beeldvorming",
    "app.menu.nutritionAdvice": "Voedingsadvies",
    "app.menu.o2Saturation": "Zuurstofsaturatie",
    "app.menu.optInResearch": "Opt In Onderzoek",
    "app.menu.pathways": "Zorgpaden",
    "app.menu.patientDetails": "Patiëntgegevens",
    "app.menu.records": "Dossiers",
    "app.menu.respiration": "Ademhaling",
    "app.menu.results": "Uitslagen",
    "app.menu.sources": "Bronnen",
    "app.menu.vaccinations": "Vaccinaties",
    "app.menu.wellbeing": "Leefstijl",
    "app.modal.button.cancel": "Annuleren",
    "app.nutrition-order.title": "Voedingsadvies",
    "app.nutritionAdvice.title": "Voedingsadvies",
    "app.o2Saturation.title": "Zuurstofsaturatie",
    "app.observation.components.empty": "Geen componenten aanwezig",
    "app.observation.components.header": "Componenten",
    "app.observation.referenceRanges.empty": "Geen referentiebereik aanwezig",
    "app.observation.referenceRanges.header": "Referentiebereik",
    "app.onboard.button": "Vind uw zorgverlener",
    "app.onboard.description": "Bekijk uw medische gegevens door verbinding te maken met uw zorgverleners. Kies uit duizenden huisartsen en ziekenhuizen.",
    "app.onboard.description.strong": "U kunt onbeperkt medische gegevens koppelen",
    "app.onboard.title": "Hallo",
    "app.page.page-not-found.title": "PAGINA NIET GEVONDEN",
    "app.patient-contact.title": "Contactpersoon",
    "app.patient.title": "Patiënt",
    "app.patientContact.title": "Contactpersonen",
    "app.patientDetail.title": "Patiëntgegevens",
    "app.paywall.agreement": "Automatisch verlengd, op elk moment opzegbaar.",
    "app.paywall.btn.continue": "Doorgaan",
    "app.paywall.btn.help": "Hulp",
    "app.paywall.btn.legals": "Juridisch",
    "app.paywall.btn.redeemCode": "Code inwisselen",
    "app.paywall.btn.restore": "Herstellen",
    "app.paywall.feature1": "Exporteer je Patiëntsamenvatting zo vaak je wilt als PDF.",
    "app.paywall.feature2": "Deel je Patiëntsamenvatting via e-mail of sms.",
    "app.paywall.restoreError": "Actief abonnement niet gevonden",
    "app.paywall.saving": "Bespaar 30%",
    "app.paywall.success": "Geabonneerd",
    "app.paywall.title": "Ontgrendel PDF",
    "app.pdfExport.name": "Patiëntsamenvatting voor {$patientName} - {$DDMMMYYYY} op {$HHMM}.pdf",
    "app.pdfExport.noName": "Patiëntsamenvatting - {$DDMMMYYYY} op {$HHMM}.pdf",
    "app.practitioner-qualifications.empty": "Geen kwalificaties aanwezig",
    "app.practitioner.qualifications.header": "Kwalificaties",
    "app.procedure.title": "Verrichting",
    "app.procedures.title": "Verrichtingen",
    "app.promo.ips.description": "Toegang tot zorg wereldwijd, met vertrouwen. Jouw Internationale Patiëntsamenvatting is een vertrouwd document dat zorgverleners overal kunnen gebruiken om jouw medische behoeften te begrijpen, zodat je de beste zorg krijgt, waar je ook bent.",
    "app.promo.ips.more": "Binnenkort beschikbaar.",
    "app.promo.ips.title": "Internationale Patiëntsamenvatting",
    "app.promo.mobile.hover.android": "Tik om de Google Play Store te openen of scan vanaf je telefoon.",
    "app.promo.mobile.hover.ios": "Tik om de Apple App Store te openen of scan vanaf je telefoon.",
    "app.promo.mobile.text": "Download digi.me op je telefoon en krijg overal toegang tot al je medische gegevens. Dankzij de veilige biometrische login is je info met één tik beschikbaar, of je nu bij de dokter bent of onderweg.",
    "app.promo.mobile.title": "Altijd je gegevens bij de hand!",
    "app.promo.optInResearch.description": "Je krijgt binnenkort de mogelijkheid om deel te nemen aan onderzoeksprogramma’s, terwijl jouw privacy gewaarborgd blijft met onze Opt In en Opt Out diensten. Kies zelf wanneer en hoe jouw gegevens bijdragen aan medische vooruitgang, terwijl je volledige controle behoudt.",
    "app.promo.optInResearch.more": "Blijf op de hoogte voor meer details!",
    "app.promo.optInResearch.title": "Opt In Onderzoek",
    "app.promo.pathways.description": "Met de module zorgpaden heb je goed inzicht over een bepaald aandoening en kan je ook zelf bepaalde taken uitvoeren voor je zorgverlener zoals het invullen van een vragenlijst of het bijhouden van je bloeddruk.",
    "app.promo.pathways.more": "Binnenkort beschikbaar.",
    "app.promo.pathways.title": "Zorgpaden Ondersteuning",
    "app.promo.wellbeing.description": "Houd belangrijke gezondheidsindicatoren bij en geeft je goed inzicht om gezond te blijven. Deze module helpt je mogelijke gezondheidsproblemen voor te zijn en geeft je controle over je leefstijl.",
    "app.promo.wellbeing.more": "Binnenkort beschikbaar.",
    "app.promo.wellbeing.title": "Leefstijl monitoring",
    "app.ptReview.btn": "Review achterlaten",
    "app.ptReview.description": "Door jouw ervaring met digi.me te delen, help jij anderen een weloverwogen keuze te maken over welke PGO zij moeten gebruiken. Dit kost jou waarschijnlijk niet meer dan 5 minuten.",
    "app.ptReview.title": "Beoordeel de Digi.me PGO",
    "app.ptReviewThanks.description": "Jouw beoordeling kan binnen een paar dagen worden gelezen door andere gebruikers die in de Digi.me PGO geïnteresseerd zijn.",
    "app.ptReviewThanks.title": "Bedankt",
    "app.reauthorize.button": "Ververs mijn gegevens",
    "app.reset": "Verwijder geïmporteerde gegevens",
    "app.reset.cancel": "Nee, annuleren",
    "app.reset.clear": "Ja, verwijderen",
    "app.reset.header": "Alle geïmporteerde gegevens van digi.me verwijderen?",
    "app.reset.warning": "Dit zal alle medische gegevens uit uw digi.me-account verwijderen. De gegevens worden niet verwijderd bij uw zorgverleners, maar zijn mogelijk niet meer beschikbaar om opnieuw te importeren op een later tijdstip.",
    "app.respiratoryRate.title": "Ademhaling",
    "app.return.errorDescription": "Er is een onverwachte fout opgetreden.",
    "app.securityPrompt.btn.delete": "Account Verwijderen",
    "app.securityPrompt.btn.logout": "Uitloggen",
    "app.securityPrompt.btn.unlock": "Ontgrendel Gezondheidskluis",
    "app.securityPrompt.noaccess.description": "Om uw privacy te beschermen, verwijderen we automatisch inactieve gezondheidskluizen. Omdat uw digi.me-kluis lange tijd niet is gebruikt, is deze verwijderd.\\n\\nOm uw medische gegevens opnieuw te importeren, verwijder dit account en maak een nieuw account aan om een nieuwe gezondheidskluis ann te maken.",
    "app.securityPrompt.noaccess.title": "Kan uw gezondheidskluis niet openen",
    "app.securityPrompt.unlock.description": "Na een periode van inactiviteit moet je je gezondheidskluis handmatig ontgrendelen. Hiervoor moet je mogelijk opnieuw inloggen bij een van je eerder gekoppelde bronnen. Dit is om je geïmporteerde medische gegevens te beschermen.",
    "app.securityPrompt.unlock.title": "Ontgrendel je Gezondheidskluis",
    "app.self-measurements.modal.button.addMeasurement": "Meting toevoegen",
    "app.self-measurements.modal.title": "Welke meting wil je registreren?",
    "app.self-measurements.modal.title.addBloodGlucose": "Bloedglucose toevoegen",
    "app.self-measurements.modal.title.addBloodOxygen": "Bloedzuurstof Toevoegen",
    "app.self-measurements.modal.title.addBloodPressure": "Bloeddruk toevoegen",
    "app.self-measurements.modal.title.addBodyHeight": "Lichaamslengte toevoegen",
    "app.self-measurements.modal.title.addBodyTemperature": "Lichaamstemperatuur toevoegen",
    "app.self-measurements.modal.title.addBodyWeight": "Lichaamsgewicht toevoegen",
    "app.self-measurements.modal.title.addHeartRate": "Hartslag toevoegen",
    "app.self-measurements.modal.title.addRespirationRate": "Ademhalingsfrequentie toevoegen",
    "app.selfMeasurement.title": "Toevoegen",
    "app.settings": "Instellingen",
    "app.settings.email": "E-mailadres",
    "app.settings.emailNotifications": "E-mailmeldingen",
    "app.settings.emailNotificationsDescription": "Inclusief herinneringen om te controleren op nieuwe gegevens of om medische enquêtes in te vullen en productaankondigingen.",
    "app.settings.emailNotificationsToggleAllow": "Toestaan",
    "app.settings.emailNotificationsToggleDontAllow": "Niet toestaan",
    "app.settings.password": "Wachtwoord",
    "app.settings.phone": "Telefoonnummer",
    "app.settings.subscription.detail": "Je hebt je geabonneerd via de {{store}} op {{subscribed.date}}",
    "app.settings.subscription.manage": "Beheren",
    "app.settings.subscription.title": "Abonnement",
    "app.share": "Delen",
    "app.signinSuccess.button": "Maak uw account",
    "app.signinSuccess.cancel": "Of, annuleer en verwijder geïmporteerde gegevens",
    "app.signinSuccess.explainer": "Voltooi de instelling van uw account met multi-factor authenticatie om toegang te krijgen tot uw medische gegevens. Maak u geen zorgen - als u nu niet kunt voltooien, worden alle geïmporteerde gegevens verwijderd voor uw privacy en heeft geen invloed op uw ziekenhuisdossiers.",
    "app.signinSuccess.heading": "U heeft succesvol uw medische gegevens geïmporteerd. Laatste Stap: Maak Uw Account",
    "app.signup.consent.buttonCancel": "Annuleren",
    "app.signup.consent.buttonSkip": "Maak eerst een account aan",
    "app.signup.consent.buttonStart": "Selecteer eerst jouw zorgverlener",
    "app.signup.consentDetails": "1. Met een PGO kan iedere Nederlands ingezetene (vanaf 16 jaar) die dat wil gezondheidsgegevens op één plek verzamelen, beheren en delen.\\n2. MedMij-regels zijn van toepassing op het aanmeldingsproces en alle MedMij-deelnemers moeten zich aan deze regels houden.\\n3. Je wordt eerst gevraagd om jouw zorgverlener (Huisarts of Ziekenhuis) te selecteren om jouw medisch dossier op te halen.\\n4. Vervolgens word je gevraagd om met behulp van DigiD jezelf bij jouw zorgverlener te identificeren.\\n5. Nadat jouw identiteit is vastgesteld, zullen jouw medische gegevens van jouw zorgverlener worden opgehaald en opgeslagen in jouw eigen digitale versleutelde data kluis.\\n6. Vervolgens zal digi.me jou vragen het PGO-registratieproces te voltooien door jouw e-mailadres te vragen en dit te verifiëren met behulp van twee-factor-authenticatie.\\n7. Zodra dit is gedaan, zijn jouw geselecteerde medische gegevens beschikbaar in jouw eigen digi.me digitale medische kluis.\\n\\nIndien je meer informatie wenst, verwijzen we je naar [Helpcentrum](https:\/\/digi.me\/nl\/help) op digi.me website.\\n\\nDoor verder te gaan, bevestig je dat je onze [Gebruiksvoorwaarden](https:\/\/digi.me\/nl\/legal\/terms) en [Privacybeleid](https:\/\/digi.me\/nl\/legal\/privacy) hebt gelezen en begrepen.",
    "app.signup.consentHelp": "",
    "app.signup.consentPolicies": "Door verder te gaan, bevestig je dat je onze <a href=\"https:\/\/digi.me\/legal\/terms\">Gebruiksvoorwaarden<\/a> <a href=\"https:\/\/digi.me\/nl\/legal\/terms\">Gebruiksvoorwaarden<\/a> en <a href=\"https:\/\/digi.me\/nl\/legal\/privacy\">Privacybeleid<\/a> hebt gelezen en begrepen.",
    "app.signup.consentSkipDescription": "Je kunt ook nu een account aanmaken en later zorgverleners koppelen of zelfmetingen toevoegen.",
    "app.signup.consentTitle": "Bevestig dat jij het volgende begrijpt en ermee akkoord gaat voordat jij begint.",
    "app.singninSuccess.cancel": "Of, annuleren en geïmporteerde gegevens verwijderen",
    "app.source-and-timestamp": "Gegevensbron:",
    "app.source-and-timestamp.selfMeasurement": "Zelfmeting",
    "app.sources.sync.warning": "met waarschuwingen.",
    "app.specimen-accession-identifier-header": "Monsterinname Identificatie",
    "app.specimen.containers.empty": "Geen container details aanwezig",
    "app.specimen.containers.header": "Containers",
    "app.specimen.processing.empty": "Details omtrent verwerking zijn niet aanwezig",
    "app.specimen.processing.header": "Details omtrent verwerking",
    "app.specimen.title": "Monster",
    "app.summary": "Samenvatting",
    "app.summary.noRecords": "Geen dossiers.",
    "app.table.header.source": "Bron",
    "app.termsAndConditions": "Algemene Voorwaarden",
    "app.title": "digi.me",
    "app.toast.uploadFailed": "Het lukte niet om sommige bestanden te uploaden.",
    "app.toast.uploadSuccess": "Je lengte- en gewichtmetingen uit Apple Gezondheid zijn toegevoegd.",
    "app.tobacco-use.title": "Tabaksgebruik",
    "app.toggle.hideAll": "Sluit alles",
    "app.toggle.showAll": "Laat alles zien",
    "app.trustBanner.text": "Als een van de weinige gecertificeerde MedMij-bedrijven, zorgen wij voor een privé en veilige toegang tot uw medische gegevens.",
    "app.trustBanner.title": "Vertrouwd & Gecertificeerd",
    "app.update.forced.button": "Nu bijwerken",
    "app.update.forced.description": "Deze versie wordt niet langer ondersteund.",
    "app.update.forced.title": "Werk nu bij.",
    "app.update.warning.link": "Werk nu bij ",
    "app.update.warning.text": "om digi.me te blijven gebruiken",
    "app.vaccination-recommendations.title": "Vaccinatie aanbevelingen",
    "app.vaccinations.title": "Vaccinaties",
    "azureadb2c.button.cancel": "Annuleren",
    "azureadb2c.button.change.email": "E-mail wijzigen",
    "azureadb2c.button.login": "Inloggen",
    "azureadb2c.button.mfa.call": "Bel Mij",
    "azureadb2c.button.mfa.send": "Code Verzenden",
    "azureadb2c.button.password.create": "Creëren",
    "azureadb2c.button.verify.code": "Code Verifiëren",
    "azureadb2c.button.verify.email": "Verificatiecode verzenden",
    "azureadb2c.button.verify.mfa": "Code Verifiëren",
    "azureadb2c.button.verify.new": "Stuur nieuwe code",
    "azureadb2c.error.email": "E-mailadres is verplicht.",
    "azureadb2c.error.mfa": "De ingevoerde verificatiecode komt niet overeen met onze gegevens. Probeer het opnieuw, of vraag een nieuwe code aan.",
    "azureadb2c.error.user.exists": "Dit e-mailadres is geregistreerd bij een bestaand digi.me-account. Gelieve een ander te gebruiken.",
    "azureadb2c.footer.copyright": "© 2025",
    "azureadb2c.footer.privacy": "Privacybeleid",
    "azureadb2c.footer.terms": "Gebruiksvoorwaarden",
    "azureadb2c.help.wait": "Wacht alstublieft terwijl wij uw informatie verwerken.",
    "azureadb2c.instruction.verified": "E-mailadres geverifieerd. Je kunt nu doorgaan.",
    "azureadb2c.instruction.verify": "Verificatiecode is verzonden naar uw inbox. Kopieer deze alstublieft naar het invoervak hieronder.",
    "azureadb2c.label.email": "E-mailadres",
    "azureadb2c.label.mfa.code": "Landcode",
    "azureadb2c.label.mfa.phone": "Telefoonnummer",
    "azureadb2c.label.mfa.verify": "Voer hieronder uw verificatiecode in, of ",
    "azureadb2c.label.password.confirm": "Bevestig jouw nieuwe wachtwoord",
    "azureadb2c.label.password.new": "Maak jouw wachtwoord. Het moet 3 van de volgende elementen bevatten: hoofdletters, kleine letters, cijfers, symbolen.",
    "azureadb2c.label.verify": "Verificatiecode",
    "azureadb2c.link.mfa.new": "stuur een nieuwe code",
    "azureadb2c.login.email": "E-mailadres",
    "azureadb2c.login.error.email": "Voer alstublieft uw e-mailadres in",
    "azureadb2c.login.error.password": "Voer alstublieft jouw wachtwoord in",
    "azureadb2c.login.forgot": "Wachtwoord vergeten?",
    "azureadb2c.login.headline": "Inloggen",
    "azureadb2c.login.headline.mfa": "Multi-factor authenticatie",
    "azureadb2c.login.link.keep": "Houd me ingelogd",
    "azureadb2c.login.link.signup": "Nu aanmelden",
    "azureadb2c.login.mfa.subtitle": "We hebben het volgende nummer geregistreerd voor u. We kunnen een code via SMS of telefoon sturen om u te verifiëren.",
    "azureadb2c.login.no-account.label": "Heeft u geen account?",
    "azureadb2c.login.password": "Wachtwoord",
    "azureadb2c.login.subtitle": "Log in met uw e-mailadres",
    "azureadb2c.marketing.headline": "Registreer nu om jouw medische dossiers in je persoonlijke gegevenskluis te bewaren, waardoor je maximale privacy en veiligheid hebt.",
    "azureadb2c.marketing.list1": "🔒 Beveiligde Toegang: Alleen jij kan jouw gegevens ontgrendelen.",
    "azureadb2c.marketing.list2": "🛡️ Bankniveau Veiligheid: Meervoudige authenticatie houdt het veilig.",
    "azureadb2c.marketing.list3": "🌎 Overal Toegang: Jouw gegevens, wanneer je ze nodig hebt.",
    "azureadb2c.marketing.list4": "🌟 Ontwikkeld door World Data Exchange: Vertrouwen bij elke klik.",
    "azureadb2c.needHelp": "Hulp nodig?",
    "azureadb2c.placeholder.email": "E-mailadres",
    "azureadb2c.placeholder.password.confirm": "Bevestig jouw nieuwe wachtwoord",
    "azureadb2c.placeholder.password.new": "Nieuw Wachtwoord",
    "azureadb2c.placeholder.phone": "Telefoonnummer",
    "azureadb2c.placeholder.verify": "Verificatiecode",
    "azureadb2c.signup.acceptToCAndPrivacyPolicy": "Ik bevestig dat ik de Gebruiksvoorwaarden en het Privacybeleid heb gelezen en begrepen.",
    "azureadb2c.signup.emailNotifications": "Opt-in om herinneringen te ontvangen om nieuwe medische dossiers te controleren.",
    "azureadb2c.signup.headline": "Maak je account aan",
    "azureadb2c.signup.headline.mfa": "Multi-factor authenticatie",
    "azureadb2c.signup.subtitle": "Maak een account aan om je medische gegevens te bekijken. Vul je e-mail, mobiel nummer, naam en wachtwoord in. Door je aan te melden, geef je toestemming om deze persoonlijke gegevens te verwerken.",
    "azureadb2c.signup.subtitle.mfa": "Voer hieronder een nummer in waarnaar we een code kunnen sturen via SMS of telefoon om u te verifiëren.",
    "diagnostic-report-performers.empty": "Geen uitvoerders aanwezig",
    "docs.binary.error.modalBody": "Het document is mogelijk niet geïmporteerd of is niet langer gekoppeld aan uw medisch dossier. U kunt proberen al uw bronnen te vernieuwen om te zien of dit het probleem oplost, of rechtstreeks contact opnemen met uw zorgverlener.",
    "docs.binary.error.modalBtnPrimary": "Ok",
    "docs.binary.error.modalBtnSecondary": "Ga naar Bronnen",
    "docs.binary.error.modalTitle": "Kan dit document niet weergeven",
    "fapp.AllergyIntolerances.title": "Allergieën \/ Intoleranties",
    "fhir.Address": "Adres",
    "fhir.Address.city": "Plaats",
    "fhir.Address.country": "Land",
    "fhir.Address.district": "Gemeente",
    "fhir.Address.empty": "Geen adresgegevens aanwezig",
    "fhir.Address.houseNumber": "Huisnummer",
    "fhir.Address.line": "Adres",
    "fhir.Address.line.iso21090-ADXP-houseNumber": "Huisnummer",
    "fhir.Address.line.iso21090-ADXP-streetName": "Straatnaam",
    "fhir.Address.postalCode": "Postcode",
    "fhir.Address.state": "Provincie",
    "fhir.Address.street": "Straatnaam",
    "fhir.Address.type": "Adres Soort",
    "fhir.Address.use": "Adres Soort",
    "fhir.Alert": "Alert",
    "fhir.Alert.author": "Auteur",
    "fhir.Alert.category": "Alert Type",
    "fhir.Alert.code": "Code",
    "fhir.Alert.detail": "Toelichting",
    "fhir.Alert.encounter": "Contact",
    "fhir.Alert.period": "Periode",
    "fhir.Alert.priority": "Prioriteit",
    "fhir.Alert.status": "Status",
    "fhir.Alert.subject": "Naam Persoon",
    "fhir.Alerts": "Alerts",
    "fhir.AllergyIntolerance.asserted-date": "Vastgelegd op",
    "fhir.AllergyIntolerance.assertedDate": "Vastgelegd op",
    "fhir.AllergyIntolerance.asserter": "Informatiebron",
    "fhir.AllergyIntolerance.category": "Allergie Categorie",
    "fhir.AllergyIntolerance.clinical-status": "Allergie Status",
    "fhir.AllergyIntolerance.clinicalStatus": "Allergie Status",
    "fhir.AllergyIntolerance.code": "Veroorzakende Stof",
    "fhir.AllergyIntolerance.criticality": "Mate Van Kritiek Zijn",
    "fhir.AllergyIntolerance.encounter": "Contact",
    "fhir.AllergyIntolerance.last-occurrence": "Voor het laatst voorgedaan op",
    "fhir.AllergyIntolerance.lastOccurrence": "Voor het laatst voorgedaan op",
    "fhir.AllergyIntolerance.onset": "Begindatum",
    "fhir.AllergyIntolerance.patient": "Patiënt",
    "fhir.AllergyIntolerance.reaction": "Reactie",
    "fhir.AllergyIntolerance.reaction.description": "Reactie Beschrijving",
    "fhir.AllergyIntolerance.reaction.exposure-route": "Wijze van blootstelling",
    "fhir.AllergyIntolerance.reaction.exposureRoute": "Wijze van blootstelling",
    "fhir.AllergyIntolerance.reaction.manifestation": "Symptoom",
    "fhir.AllergyIntolerance.reaction.onset": "Begindatum",
    "fhir.AllergyIntolerance.reaction.severity": "Ernst",
    "fhir.AllergyIntolerance.reaction.substance": "Stof of product",
    "fhir.AllergyIntolerance.recorded-date": "Datum vastgelegd",
    "fhir.AllergyIntolerance.recordedDate": "Datum vastgelegd",
    "fhir.AllergyIntolerance.recorder": "Vastgelegd door",
    "fhir.AllergyIntolerance.substance": "Veroorzakende Stof",
    "fhir.AllergyIntolerance.type": "Type",
    "fhir.AllergyIntolerance.verification-status": "Verificatie Status",
    "fhir.AllergyIntolerance.verificationStatus": "Verificatie Status",
    "fhir.Annotation": "Notitie",
    "fhir.Annotation.AuthorReference": "Auteur referentie",
    "fhir.Annotation.author": "Auteur",
    "fhir.Annotation.authorReference": "Auteur referentie",
    "fhir.Annotation.text": "Tekst",
    "fhir.Annotation.time": "Tijdstip",
    "fhir.Appointment.OnlineEditable": "Online Aanpasbaar",
    "fhir.Appointment.OnlineEditable.indicator": "Indicator",
    "fhir.Appointment.OnlineEditable.onlineEditableUntil": "Online aanpasbaar tot",
    "fhir.Appointment.appointmentType": "Afspraak Type",
    "fhir.Appointment.comment": "Toelichting",
    "fhir.Appointment.created": "Aangemaakt op",
    "fhir.Appointment.description": "Beschrijving",
    "fhir.Appointment.end": "Einddatum",
    "fhir.Appointment.identifier": "Identificatie",
    "fhir.Appointment.incomingReferral": "Doorverwijzing",
    "fhir.Appointment.indication": "Indicatie",
    "fhir.Appointment.minutesDuration": "Ingeplande tijdsduur",
    "fhir.Appointment.orderStatus": "Opdracht status",
    "fhir.Appointment.patientInstructions": "Patiënt instructies",
    "fhir.Appointment.priority": "Prioriteit",
    "fhir.Appointment.reason": "Reden",
    "fhir.Appointment.requestedPeriod": "Aangevraagde periode",
    "fhir.Appointment.serviceCategory": "Dienst Categorie",
    "fhir.Appointment.serviceType": "Dienst Type",
    "fhir.Appointment.slot": "Tijdslot",
    "fhir.Appointment.specialty": "Specialisme",
    "fhir.Appointment.start": "Begindatum",
    "fhir.Appointment.status": "Status",
    "fhir.Appointment.supportingInformation": "Ondersteunende informatie",
    "fhir.AppointmentOnlineEditable": "Online Aanpasbaar",
    "fhir.AppointmentParticipant": "Deelnemer aan Afspraak",
    "fhir.AppointmentParticipant.actor": "Deelnemer",
    "fhir.AppointmentParticipant.actor.practitionerRole": "Rol zorgverlener",
    "fhir.AppointmentParticipant.required": "Aanwezigheid vereist",
    "fhir.AppointmentParticipant.status": "Status",
    "fhir.AppointmentParticipant.type": "Type",
    "fhir.AppointmentParticipants": "Deelnemers aan Afspraak",
    "fhir.Composition": "SOEP Verslag",
    "fhir.Composition.Attester.mode": "Rol",
    "fhir.Composition.Attester.party": "Verantwoordelijke instantie",
    "fhir.Composition.Attester.time": "Datum \/ Tijdstip",
    "fhir.Composition.Event.code": "Code",
    "fhir.Composition.Event.detail": "Toelichting",
    "fhir.Composition.Event.period": "Periode",
    "fhir.Composition.ICPCcode": "ICPC Code",
    "fhir.Composition.ICPCdescription": "ICPC Beschrijving",
    "fhir.Composition.RelatesTo.code": "Code",
    "fhir.Composition.RelatesTo.targetReference": "Referentie doel",
    "fhir.Composition.Section.code": "SOEP Regel Code",
    "fhir.Composition.Section.emptyReason": "Reden Ontbreken Gegevens",
    "fhir.Composition.Section.entry": "Tekst",
    "fhir.Composition.Section.extension.icpcCode": "ICPC Code",
    "fhir.Composition.Section.extension.icpcDescription": "ICPC Beschrijving",
    "fhir.Composition.Section.mode": "Modus",
    "fhir.Composition.Section.orderedBy": "Verzoek gedaan door",
    "fhir.Composition.Section.title": "Titel",
    "fhir.Composition.attester": "Document ondertekenaar",
    "fhir.Composition.author": "Auteur",
    "fhir.Composition.class": "Klasse",
    "fhir.Composition.confidentiality": "Vertrouwelijkheid",
    "fhir.Composition.custodian": "Verantwoordelijke voor onderhoud en toegang tot verslag",
    "fhir.Composition.date": "Datum",
    "fhir.Composition.encounter": "Contact",
    "fhir.Composition.event": "Moment type in relatie tot document",
    "fhir.Composition.icpcCode": "ICPC",
    "fhir.Composition.icpcDescription": "ICPC Beschrijving",
    "fhir.Composition.identifier": "Identificatie",
    "fhir.Composition.relatesTo": "Document heeft betrekking op",
    "fhir.Composition.section": "SOEP Regels",
    "fhir.Composition.status": "Status",
    "fhir.Composition.subject": "Patiënt",
    "fhir.Composition.title": "Titel",
    "fhir.Composition.type": "Type",
    "fhir.Condition.Evidence.code": "Code",
    "fhir.Condition.Evidence.detail": "Toelichting",
    "fhir.Condition.Stage.assessment": "Beoordeling",
    "fhir.Condition.Stage.summary": "Samenvatting",
    "fhir.Condition.Stage.type": "Type",
    "fhir.Condition.abatement": "Vermindering klachten \/ Remissie",
    "fhir.Condition.asserter": "Informatiebron",
    "fhir.Condition.bodySite": "Lichaamslocatie",
    "fhir.Condition.category": "Categorie",
    "fhir.Condition.clinicalStatus": "Status",
    "fhir.Condition.code": "Beschrijving",
    "fhir.Condition.context": "Context",
    "fhir.Condition.encounter": "Contact",
    "fhir.Condition.evidence": "Ondersteunend bewijs",
    "fhir.Condition.onset": "Begindatum",
    "fhir.Condition.recordedDate": "Datum vastgelegd",
    "fhir.Condition.recorder": "Vastgelegd door",
    "fhir.Condition.severity": "Ernst",
    "fhir.Condition.stage": "Stadium",
    "fhir.Condition.subject": "Patiënt",
    "fhir.Condition.verificationStatus": "Verificatie status",
    "fhir.ConditionEvidence": "Ondersteunend bewijs",
    "fhir.ConditionEvidence.code": "Code",
    "fhir.ConditionEvidence.detail": "Toelichting",
    "fhir.ConditionStage": "Stadium Aandoening",
    "fhir.Consent": "Toestemming",
    "fhir.Consent.action": "Actie",
    "fhir.Consent.actor": "Vertegenwoordiger",
    "fhir.Consent.actor.reference": "Informatiebron",
    "fhir.Consent.actor.role": "Rol",
    "fhir.Consent.category": "Categorie",
    "fhir.Consent.consentingParty": "(Juridisch) Vertegenwoordiger",
    "fhir.Consent.data": "Gegevens",
    "fhir.Consent.data.meaning": "Betekenis",
    "fhir.Consent.data.reference": "Referentie",
    "fhir.Consent.dataPeriod": "Van kracht tijdens",
    "fhir.Consent.dateTime": "Datum",
    "fhir.Consent.except": "Uitzondering",
    "fhir.Consent.except.action": "Uitzonderingen op Consent",
    "fhir.Consent.except.class": "Klasse",
    "fhir.Consent.except.code": "Code",
    "fhir.Consent.except.dataPeriod": "Van kracht tijdens",
    "fhir.Consent.except.period": "Periode",
    "fhir.Consent.except.purpose": "Doel",
    "fhir.Consent.except.securityLabel": "Beveiligingslabel",
    "fhir.Consent.except.type": "Type",
    "fhir.Consent.meaning": "Betekenis",
    "fhir.Consent.organization": "Zorgaanbieder",
    "fhir.Consent.patient": "Patiënt",
    "fhir.Consent.period": "Periode",
    "fhir.Consent.policy": "Beleid",
    "fhir.Consent.policy.authority": "Instantie",
    "fhir.Consent.policy.uri": "URL",
    "fhir.Consent.policyRule": "Regelgeving",
    "fhir.Consent.purpose": "Doel",
    "fhir.Consent.reference": "Referentie",
    "fhir.Consent.role": "Rol",
    "fhir.Consent.securityLabel": "Beveiligingslabel",
    "fhir.Consent.source": "Bron",
    "fhir.Consent.status": "Status",
    "fhir.ConsentActor": "Vertegenwoordiger",
    "fhir.ConsentData": "Toestemming Gegevens",
    "fhir.ConsentExcept": "Uitzondering Toestemming",
    "fhir.ConsentExcept.period": "Periode",
    "fhir.ConsentExcept.type": "Type",
    "fhir.ConsentPolicy": "Regelgeving Toestemming",
    "fhir.ContactPoint": "Aanspreekpunt",
    "fhir.ContactPoint.type": "Telecom Type",
    "fhir.ContactPoint.use": "Nummer- \/ E-mail Soort",
    "fhir.Coverage.Payor.BankInformation": "Bank Informatie",
    "fhir.Coverage.Payor.BankInformation.accountNumber": "Rekeningnummer",
    "fhir.Coverage.Payor.BankInformation.bankCode": "Bankcode",
    "fhir.Coverage.Payor.BankInformation.bankName": "Bank Naam",
    "fhir.Coverage.Payor.name": "Betaler Naam",
    "fhir.Coverage.beneficiary": "Verzekering Begunstigde",
    "fhir.Coverage.contract": "Contract",
    "fhir.Coverage.dependent": "Betaler Persoon",
    "fhir.Coverage.network": "Vezekerings pakket",
    "fhir.Coverage.order": "Volgorde",
    "fhir.Coverage.payor": "Betaler",
    "fhir.Coverage.payors": "Betalers",
    "fhir.Coverage.period": "Verzekeringsperiode",
    "fhir.Coverage.policyHolder": "Polishouder",
    "fhir.Coverage.relationship": "Relatie",
    "fhir.Coverage.sequence": "Reeks",
    "fhir.Coverage.status": "Status",
    "fhir.Coverage.subscriber": "Hoofdverzekerde",
    "fhir.Coverage.subscriberId": "Verzekerde Nummer",
    "fhir.Coverage.type": "Verzekeringssoort",
    "fhir.Device.expirationDate": "Expiratie datum",
    "fhir.Device.location": "Anatomische Locatie",
    "fhir.Device.lotNumber": "Lotnummer",
    "fhir.Device.manufactureDate": "Productiedatum",
    "fhir.Device.manufacturer": "Fabrikant",
    "fhir.Device.model": "Model",
    "fhir.Device.owner": "Zorgaanbieder",
    "fhir.Device.patient": "Patiënt",
    "fhir.Device.safety": "Veiligheid",
    "fhir.Device.status": "Status",
    "fhir.Device.type": "Type",
    "fhir.Device.udi": "UDI Naam",
    "fhir.Device.udi.name": "UDI Naam",
    "fhir.DeviceUdi.carrierAIDC": "Product ID; AIDC",
    "fhir.DeviceUdi.carrierHRF": "Product ID;  HRF",
    "fhir.DeviceUdi.deviceIdentifier": "Product ID",
    "fhir.DeviceUdi.deviceUdi": "Product ID; UDI",
    "fhir.DeviceUdi.entryType": "Invoertype",
    "fhir.DeviceUdi.issuer": "Uitgever",
    "fhir.DeviceUdi.jurisdiction": "Jurisdictie",
    "fhir.DeviceUdi.name": "Naamgegevens",
    "fhir.DeviceUseStatement.bodySite": "Lichaamsdeel of orgaan",
    "fhir.DeviceUseStatement.device": "Apparaat",
    "fhir.DeviceUseStatement.healthProfessional": "Zorgverlener",
    "fhir.DeviceUseStatement.healthcareProvider": "Zorgaanbieder",
    "fhir.DeviceUseStatement.indication": "Indicatie",
    "fhir.DeviceUseStatement.reasonReference": "Reden referentie",
    "fhir.DeviceUseStatement.recordedOn": "Vastgelegd op",
    "fhir.DeviceUseStatement.source": "Bron",
    "fhir.DeviceUseStatement.status": "Status",
    "fhir.DeviceUseStatement.subject": "Patiënt",
    "fhir.DeviceUseStatement.timing": "Tijdstip",
    "fhir.DeviceUseStatement.whenUsed": "Wanneer gebruikt",
    "fhir.Diagnose.onset": "Begindatum",
    "fhir.Diagnosis.clinicalStatus": "Probleem Status",
    "fhir.Diagnosis.code": "Code",
    "fhir.Diagnosis.onset": "Begindatum",
    "fhir.Diagnosis.recordedDate": "Datum vastgelegd",
    "fhir.DiagnosticReport.Code": "Code",
    "fhir.DiagnosticReport.Performer.Actor": "Uitvoerende",
    "fhir.DiagnosticReport.Performer.Role": "Rol",
    "fhir.DiagnosticReport.basedOn": "Gebaseerd op",
    "fhir.DiagnosticReport.category": "Categorie",
    "fhir.DiagnosticReport.codedDiagnosis": "Diagnose Code",
    "fhir.DiagnosticReport.conclusion": "Conclusie",
    "fhir.DiagnosticReport.context": "Context",
    "fhir.DiagnosticReport.effective": "Tijdstip\/Tijdsperiode",
    "fhir.DiagnosticReport.imagingStudy": "Beeldvormend onderzoek",
    "fhir.DiagnosticReport.issued": "Testuitslag",
    "fhir.DiagnosticReport.performer": "Opgesteld door",
    "fhir.DiagnosticReport.result": "Observaties",
    "fhir.DiagnosticReport.specimen": "Monster",
    "fhir.DiagnosticReport.status": "Status",
    "fhir.DiagnosticReport.subject": "Betreffende",
    "fhir.Dosage.additionalInstruction": "Aanvullende instructies",
    "fhir.Dosage.asNeeded": "Doseer duur",
    "fhir.Dosage.dose": "Dosis",
    "fhir.Dosage.maxDosePerAdministration": "Maximale dosis per keer",
    "fhir.Dosage.maxDosePerLifetime": "Maximale cumulatieve dosis (levenslang)",
    "fhir.Dosage.method": "Methode voor toediening",
    "fhir.Dosage.patientInstruction": "Instructies voor patiënt",
    "fhir.Dosage.rate": "Frequentie",
    "fhir.Dosage.route": "Toedieningsweg",
    "fhir.Dosage.sequence": "Volgnummer",
    "fhir.Dosage.site": "Locatie",
    "fhir.Dosage.text": "Omschrijving",
    "fhir.Encounter.account": "Account",
    "fhir.Encounter.appointment": "Afspraak",
    "fhir.Encounter.class": "Type",
    "fhir.Encounter.episodeOfCare": "Episode(n)",
    "fhir.Encounter.identifier": "Identificatie",
    "fhir.Encounter.incomingReferral": "Doorverwijzing",
    "fhir.Encounter.length": "Duur",
    "fhir.Encounter.partOf": "Maakt deel uit van",
    "fhir.Encounter.period": "Periode",
    "fhir.Encounter.priority": "Prioriteit",
    "fhir.Encounter.reason": "Reden",
    "fhir.Encounter.serviceProvider": "Zorgaanbieder",
    "fhir.Encounter.status": "Status",
    "fhir.Encounter.subject": "Patiënt",
    "fhir.Encounter.type": "Type",
    "fhir.EncounterClassHistory.class": "Klasse",
    "fhir.EncounterClassHistory.period": "Periode",
    "fhir.EncounterDiagnoses": "Diagnoses tijdens contact",
    "fhir.EncounterDiagnosis": "Diagnose",
    "fhir.EncounterDiagnosis.condition": "Aandoening",
    "fhir.EncounterDiagnosis.rank": "Rang",
    "fhir.EncounterDiagnosis.role": "Rol",
    "fhir.EncounterHospitalization": "Ziekenhuisopname",
    "fhir.EncounterHospitalization.admitSource": "Code Herkomst patiënt",
    "fhir.EncounterHospitalization.destination": "Bestemming na ontslag",
    "fhir.EncounterHospitalization.dietPreference": "Dieetvoorkeur",
    "fhir.EncounterHospitalization.dischargeDisposition": "Code bestemming na ontslag",
    "fhir.EncounterHospitalization.origin": "Herkomst patiënt",
    "fhir.EncounterHospitalization.preAdmissionIdentifier": "Voorbereiding Ziekenhuisopname Identificatie",
    "fhir.EncounterHospitalization.reAdmission": "Heropname",
    "fhir.EncounterHospitalization.specialArrangement": "Speciaal verzoek",
    "fhir.EncounterHospitalization.specialCourtesy": "Speciale behandeling",
    "fhir.EncounterLocation": "Afspraak Locatie",
    "fhir.EncounterLocation.location": "Locatie",
    "fhir.EncounterLocation.period": "Periode",
    "fhir.EncounterLocation.status": "Status",
    "fhir.EncounterLocations": "Contact Locaties",
    "fhir.EncounterParticipant": "Aanwezige",
    "fhir.EncounterParticipant.individual": "Persoon",
    "fhir.EncounterParticipant.period": "Periode",
    "fhir.EncounterParticipant.type": "Type",
    "fhir.EncounterParticipants": "Aanwezig bij contact",
    "fhir.EncounterStatusHistories": "Contact Status Overzicht",
    "fhir.EncounterStatusHistory": "Contact Status overzicht",
    "fhir.EncounterStatusHistory.period": "Periode",
    "fhir.EncounterStatusHistory.status": "Status",
    "fhir.EpisodeOfCare.Diagnosis.condition": "Aandoening",
    "fhir.EpisodeOfCare.Diagnosis.role": "Rol",
    "fhir.EpisodeOfCare.StatusHistory.period": "Periode",
    "fhir.EpisodeOfCare.StatusHistory.status": "Status",
    "fhir.EpisodeOfCare.careManager": "Zorgverlener",
    "fhir.EpisodeOfCare.diagnoses": "Titel",
    "fhir.EpisodeOfCare.diagnosis": "Diagnose",
    "fhir.EpisodeOfCare.firstEncounter": "Datum eerste contact",
    "fhir.EpisodeOfCare.lastEncounter": "Datum laatste contact",
    "fhir.EpisodeOfCare.managingOrganization": "Zorgverlener",
    "fhir.EpisodeOfCare.patient": "Patiënt",
    "fhir.EpisodeOfCare.period": "Periode",
    "fhir.EpisodeOfCare.status": "Status",
    "fhir.EpisodeOfCare.statusHistory": "Status",
    "fhir.EpisodeOfCare.title": "Omschrijving",
    "fhir.EpisodeOfCare.type": "Type",
    "fhir.Flag.code": "Code",
    "fhir.Flag.identifier": "Identificatie",
    "fhir.Flag.period": "Periode",
    "fhir.Flag.status": "Status",
    "fhir.Flag.subject": "Onderwerp",
    "fhir.HumanName": "Naam",
    "fhir.HumanName.family": "Achternaam",
    "fhir.HumanName.family.fathers-family": "Achternaam Vader",
    "fhir.HumanName.family.mothers-family": "Achternaam Moeder",
    "fhir.HumanName.family.own-name": "Achternaam",
    "fhir.HumanName.family.own-prefix": "Voorvoegsels",
    "fhir.HumanName.family.partner-name": "Achternaam Partner",
    "fhir.HumanName.family.partner-prefix": "Voorvoegsels Partner",
    "fhir.HumanName.given": "Naam",
    "fhir.HumanName.name": "Naamgegevens",
    "fhir.HumanName.use": "Naamgebruik",
    "fhir.Identifier.assigner": "Uitgevende instantie",
    "fhir.Identifier.period": "Periode",
    "fhir.Identifier.system": "Bron",
    "fhir.Identifier.type": "Type",
    "fhir.Identifier.use": "Gebruik",
    "fhir.Identifier.value": "Waarde",
    "fhir.ImagingStudy.availability": "Beschikbaarheid",
    "fhir.ImagingStudy.basedOn": "Gebaseerd op",
    "fhir.ImagingStudy.context": "Context",
    "fhir.ImagingStudy.description": "Beschrijving",
    "fhir.ImagingStudy.endpoint": "Eindpunt",
    "fhir.ImagingStudy.interpreter": "Beoordeeld door",
    "fhir.ImagingStudy.modalityList": "Modaliteiten",
    "fhir.ImagingStudy.numberOfInstances": "Aantal onderdelen",
    "fhir.ImagingStudy.numberOfSeries": "Aantal onderzoeken",
    "fhir.ImagingStudy.patient": "Patiënt",
    "fhir.ImagingStudy.procedureCode": "DICOM Procedure Code Sequentie",
    "fhir.ImagingStudy.procedureReference": "Verrichting Referentie",
    "fhir.ImagingStudy.reason": "Reden",
    "fhir.ImagingStudy.referrer": "Verwijzende arts",
    "fhir.ImagingStudy.series": "Onderzoek",
    "fhir.ImagingStudy.started": "Aanvang",
    "fhir.ImagingStudy.uid": "UID (Unique Identifier)",
    "fhir.ImagingStudySeries.availability": "Beschikbaarheid onderzoek",
    "fhir.ImagingStudySeries.bodySite": "Lichaamsdeel of orgaan",
    "fhir.ImagingStudySeries.description": "Beschrijving",
    "fhir.ImagingStudySeries.endpoint": "Eindpunt",
    "fhir.ImagingStudySeries.header": "Beeldvormend onderzoek serie",
    "fhir.ImagingStudySeries.laterality": "Lateraliteit",
    "fhir.ImagingStudySeries.modality": "Modaliteit",
    "fhir.ImagingStudySeries.number": "Aantal",
    "fhir.ImagingStudySeries.numberOfInstances": "Aantal onderdelen",
    "fhir.ImagingStudySeries.performer": "Uitvoerder",
    "fhir.ImagingStudySeries.started": "Aanvang",
    "fhir.ImagingStudySeries.uid": "UID (Unique Identifier)",
    "fhir.ImagingStudySeriesInstance.header": "Beeldvormend onderzoek serie onderdeel",
    "fhir.ImagingStudySeriesInstance.number": "Aantal",
    "fhir.ImagingStudySeriesInstance.sopClass": "DICOM SOP klasse",
    "fhir.ImagingStudySeriesInstance.title": "Titel",
    "fhir.ImagingStudySeriesInstance.uid": "UID (Unique Identifier)",
    "fhir.ImagingStudySeriesInstances.header": "Beeldvormend onderzoek serie onderdeel",
    "fhir.Immunization.doseQuantity": "Hoeveelheid toegediend vaccin",
    "fhir.Immunization.encounter": "Contact",
    "fhir.Immunization.expirationDate": "Expiratie datum",
    "fhir.Immunization.location": "Vaccinatie locatie",
    "fhir.Immunization.lotNumber": "Lotnummer",
    "fhir.Immunization.manufacturer": "Fabrikant",
    "fhir.Immunization.notGiven": "Niet gegeven",
    "fhir.Immunization.occurrence": "Datum",
    "fhir.Immunization.patient": "Patiënt",
    "fhir.Immunization.practitioner": "Toediener",
    "fhir.Immunization.primarySource": "Primaire bron",
    "fhir.Immunization.reportOrigin": "Herkomst rapport",
    "fhir.Immunization.route": "Toedieningsweg",
    "fhir.Immunization.site": "Locatie",
    "fhir.Immunization.status": "Status",
    "fhir.Immunization.statusReason": "Reden geen vaccinatie ",
    "fhir.Immunization.vaccineCode": "Vaccin code",
    "fhir.ImmunizationRecommendation.Recommendation.dateCriterion": "Datum",
    "fhir.ImmunizationRecommendation.Recommendation.supportingImmunization": "Ondersteunende gegevens vaccinatie historie \/ evaluatie",
    "fhir.ImmunizationRecommendation.authority": "Verantwoordelijk voor protocol",
    "fhir.ImmunizationRecommendation.date": "Datum",
    "fhir.ImmunizationRecommendation.patient": "Patiënt",
    "fhir.ImmunizationRecommendation.recommendation.contraindicatedVaccineCode": "Contra-indicatie voor vaccin code",
    "fhir.ImmunizationRecommendation.recommendation.date": "Datum",
    "fhir.ImmunizationRecommendation.recommendation.dateCriterion.code": "Code",
    "fhir.ImmunizationRecommendation.recommendation.dateCriterion.value": "Aanbevolen datum",
    "fhir.ImmunizationRecommendation.recommendation.description": "Beschrijving",
    "fhir.ImmunizationRecommendation.recommendation.doseNumber": "Dosis nummer",
    "fhir.ImmunizationRecommendation.recommendation.forecastReason": "Reden vaccinatie status",
    "fhir.ImmunizationRecommendation.recommendation.forecastStatus": "Vaccinatie status",
    "fhir.ImmunizationRecommendation.recommendation.protocol.authority": "Protocol gepubliceerd door",
    "fhir.ImmunizationRecommendation.recommendation.protocol.description": "Beschrijving",
    "fhir.ImmunizationRecommendation.recommendation.protocol.doseSequence": "Dosis reeks",
    "fhir.ImmunizationRecommendation.recommendation.protocol.series": "Vaccin reeks",
    "fhir.ImmunizationRecommendation.recommendation.series": "Vaccin reeks",
    "fhir.ImmunizationRecommendation.recommendation.seriesDoses": "Aanbevolen aantal doses",
    "fhir.ImmunizationRecommendation.recommendation.supportingImmunization": "Ondersteunende gegevens vaccinatie historie \/ evaluatie",
    "fhir.ImmunizationRecommendation.recommendation.supportingPatientInformation": "Patiënt informatie ",
    "fhir.ImmunizationRecommendation.recommendation.targetDisease": "Doelziekten",
    "fhir.ImmunizationRecommendation.recommendation.vaccineCode": "Vaccin code",
    "fhir.ImmunizationRecommendationRecommendation.dateCriterion": "Datumcriteria",
    "fhir.ImmunizationRecommendationRecommendation.supportingImmunization": "Ondersteuning van vaccinatie",
    "fhir.Immunzation.explanation": "Toelichting",
    "fhir.Immunzation.explanation.reason": "Reden",
    "fhir.Immunzation.explanation.reasonNotGiven": "Reden waarom de vaccinatie niet is uitgevoerd",
    "fhir.Immunzation.practitioner.actor": "Toediener",
    "fhir.Immunzation.practitioner.role": "Rol",
    "fhir.Immunzation.reaction": "Reacties",
    "fhir.Immunzation.reaction.date": "Datum",
    "fhir.Immunzation.reaction.detail": "Toelichting",
    "fhir.Immunzation.reaction.reported": "Zelfrapportage",
    "fhir.Immunzation.vaccinationProtocol.authority": "Autoriteit",
    "fhir.Immunzation.vaccinationProtocol.description": "Beschrijving",
    "fhir.Immunzation.vaccinationProtocol.doseSequence": "Dosis reeks",
    "fhir.Immunzation.vaccinationProtocol.doseStatus": "Dosis status",
    "fhir.Immunzation.vaccinationProtocol.doseStatusReason": "Reden dosis status",
    "fhir.Immunzation.vaccinationProtocol.series": "Vaccin reeks",
    "fhir.Immunzation.vaccinationProtocol.seriesDoses": "Aanbevolen aantal doses",
    "fhir.Immunzation.vaccinationProtocol.targetDisease": "Doelziekten",
    "fhir.MedicalAid.device": "Apparaat",
    "fhir.MedicalAid.whenUsed": "Wanneer gebruikt",
    "fhir.Medication.Ingredient.amount": "Hoeveelheid",
    "fhir.Medication.Ingredient.isActive": "Is werkzaam bestanddeel",
    "fhir.Medication.Ingredient.itemCodeableConcept": "Medicatie Ingrediënt",
    "fhir.Medication.Ingredient.itemReference": "Type Codelijst",
    "fhir.Medication.authoredOn": "Afspraakdatum",
    "fhir.Medication.code": "Code",
    "fhir.Medication.form": "Farmaceutische vorm",
    "fhir.Medication.ingredient": "Geneesmiddel ingrediënt",
    "fhir.Medication.isBrand": "Merk geneesmiddel",
    "fhir.Medication.isOverTheCounter": "OTC",
    "fhir.Medication.manufacturer": "Fabrikant",
    "fhir.Medication.medication": "Geneesmiddel",
    "fhir.Medication.package": "Verpakking",
    "fhir.Medication.package.batch": "Verpakking Batch",
    "fhir.Medication.package.batch.expirationDate": "Vervaldatum",
    "fhir.Medication.package.batch.lotNumber": "Batchnummer",
    "fhir.Medication.package.container": "Type container",
    "fhir.Medication.package.content": "Verpakkingsinhoud",
    "fhir.Medication.package.content.amount": "Hoeveelheid",
    "fhir.Medication.package.content.itemCodeableConcept": "Samenstelling verpakking",
    "fhir.Medication.package.content.itemReference": "Referentie verpakking",
    "fhir.Medication.status": "Status",
    "fhir.MedicationDispense.authorizingPrescription": "Bijbehorende recept",
    "fhir.MedicationDispense.category": "Categorie",
    "fhir.MedicationDispense.context": "Context",
    "fhir.MedicationDispense.daysSupply": "Aantal dagen levering",
    "fhir.MedicationDispense.destination": "Bestemming",
    "fhir.MedicationDispense.medication": "Medicatie",
    "fhir.MedicationDispense.notDone": "Niet uitgevoerd",
    "fhir.MedicationDispense.notDoneReasonCodeableConcept": "Reden Niet Uitgevoerd",
    "fhir.MedicationDispense.partOf": "Maakt deel uit van",
    "fhir.MedicationDispense.performer.actor": "Uitvoerende",
    "fhir.MedicationDispense.performer.function": "Functie",
    "fhir.MedicationDispense.performer.medicationDispensePerformer": "Verstrekker",
    "fhir.MedicationDispense.performer.onBehalfOf": "Namens",
    "fhir.MedicationDispense.quantity": "Hoeveelheid",
    "fhir.MedicationDispense.receiver": "Ontvanger",
    "fhir.MedicationDispense.status": "Status",
    "fhir.MedicationDispense.subject": "Patiënt",
    "fhir.MedicationDispense.substitution.medicationDispenseSubstitution": "Substitutie medicatie",
    "fhir.MedicationDispense.substitution.reason": "Reden substitutie",
    "fhir.MedicationDispense.substitution.responsibleParty": "Verantwoordelijke",
    "fhir.MedicationDispense.substitution.type": "Type",
    "fhir.MedicationDispense.substitution.wasSubstituted": "Werd gesubstitueerd",
    "fhir.MedicationDispense.type": "Type",
    "fhir.MedicationDispense.whenHandedOver": "Wanneer uitgereikt",
    "fhir.MedicationDispense.whenPrepared": "Wanneer klaargemaakt",
    "fhir.MedicationRequest.DispenseRequest.duration": "Gebruiksduur",
    "fhir.MedicationRequest.DispenseRequest.numberOfRepeatsAllowed": "Aantal toegestane herhalingen",
    "fhir.MedicationRequest.DispenseRequest.quantity": "Hoeveelheid",
    "fhir.MedicationRequest.DispenseRequest.validityPeriod": "Toegestane periode voor gebruik",
    "fhir.MedicationRequest.Requester.agent": "Zorgverlener",
    "fhir.MedicationRequest.Requester.onBehalfOf": "Namens",
    "fhir.MedicationRequest.Requester.performer": "Uitvoerder",
    "fhir.MedicationRequest.authoredOn": "Datum \/ Tijd",
    "fhir.MedicationRequest.basedOn": "Gebaseerd op",
    "fhir.MedicationRequest.category": "Categorie",
    "fhir.MedicationRequest.context": "Context",
    "fhir.MedicationRequest.definition": "Definitie",
    "fhir.MedicationRequest.dispenseRequest": "Uitgifte verzoek",
    "fhir.MedicationRequest.intent": "Intentie van verzoek",
    "fhir.MedicationRequest.medication": "Medicatie",
    "fhir.MedicationRequest.priorPrescription": "Vorige recept",
    "fhir.MedicationRequest.priority": "Prioriteit",
    "fhir.MedicationRequest.reasonCode": "Medicatie Afspraak Reden",
    "fhir.MedicationRequest.reasonReference": "Referentie voor reden van voorschrijven",
    "fhir.MedicationRequest.recorder": "Vastgelegd door",
    "fhir.MedicationRequest.requester": "Voorschrijver",
    "fhir.MedicationRequest.status": "Status",
    "fhir.MedicationRequest.subject": "Patiënt",
    "fhir.MedicationRequest.supportingInformation": "Ondersteunende informatie",
    "fhir.MedicationStatement.basedOn": "Gebaseerd op",
    "fhir.MedicationStatement.category": "Categorie",
    "fhir.MedicationStatement.context": "Context",
    "fhir.MedicationStatement.dateAsserted": "Vastgelegd op",
    "fhir.MedicationStatement.derivedFrom": "Organisatie",
    "fhir.MedicationStatement.effectiveDateTime": "Gebruiksperiode",
    "fhir.MedicationStatement.informationSource": "Informatiebron",
    "fhir.MedicationStatement.medication": "Medicatie",
    "fhir.MedicationStatement.partOf": "Maakt deel uit van",
    "fhir.MedicationStatement.reasonCode": "Reden van voorschrijven",
    "fhir.MedicationStatement.reasonNotTaken": "Reden niet ingenomen",
    "fhir.MedicationStatement.reasonReference": "Referentie reden voor gebruik",
    "fhir.MedicationStatement.status": "Status",
    "fhir.MedicationStatement.subject": "Patiënt",
    "fhir.MedicationStatement.taken": "Ingenomen",
    "fhir.Narrative": "Toelichting",
    "fhir.NutritionAdvice.dateTime": "Datum",
    "fhir.NutritionAdvice.orderer": "Opdrachtgever",
    "fhir.NutritionAdvice.status": "Status",
    "fhir.NutritionOrder": "Voedingsadvies",
    "fhir.NutritionOrder.allergyIntolerance": "Allergie \/ Intolerantie",
    "fhir.NutritionOrder.comment": "Toelichting",
    "fhir.NutritionOrder.dateTime": "Datum \/ Tijd",
    "fhir.NutritionOrder.encounter": "Contact",
    "fhir.NutritionOrder.excludeFoodModifier": "Uitsluitingen",
    "fhir.NutritionOrder.foodPreferenceModifier": "Gewenste aanpassing",
    "fhir.NutritionOrder.oralDiet.consistency": "Consistentie",
    "fhir.NutritionOrder.oralDiet.type": "Type",
    "fhir.NutritionOrder.orderer": "Opdrachtgever",
    "fhir.NutritionOrder.patient": "Patiënt",
    "fhir.NutritionOrder.status": "Status",
    "fhir.NutritionOrderEnteralFormula": "Voedingsadvies sondevoeding",
    "fhir.NutritionOrderEnteralFormula.additiveProductName": "Productnaam toevoeging",
    "fhir.NutritionOrderEnteralFormula.additiveType": "Type toevoeging",
    "fhir.NutritionOrderEnteralFormula.administrationInstruction": "Instructies voor toediening",
    "fhir.NutritionOrderEnteralFormula.baseFormulaProductName": "Productnaam sondevoeding",
    "fhir.NutritionOrderEnteralFormula.baseFormulaType": "Type sondevoeding",
    "fhir.NutritionOrderEnteralFormula.caloricDensity": "Caloriedichtheid",
    "fhir.NutritionOrderEnteralFormula.maxVolumeToDeliver": "Maximaal toe te dienen volume",
    "fhir.NutritionOrderEnteralFormula.routeofAdministration": "Toedieningsweg",
    "fhir.NutritionOrderEnteralFormulaAdministration": "Instructies voor toediening",
    "fhir.NutritionOrderEnteralFormulaAdministration.quantity": "Hoeveelheid",
    "fhir.NutritionOrderEnteralFormulaAdministration.rateQuantity": "toedienings snelheid",
    "fhir.NutritionOrderEnteralFormulaAdministration.rateRatio": "toedienings snelheid ratio",
    "fhir.NutritionOrderOralDiet": "Voedingsadvies Orale voeding",
    "fhir.NutritionOrderOralDiet.fluidConsistencyType": "Consistentie vloeistof \/ drank",
    "fhir.NutritionOrderOralDiet.instruction": "Aanwijzingen",
    "fhir.NutritionOrderOralDiet.type": "Type",
    "fhir.NutritionOrderOralDietNutrient": "Voedingsadvies orale voeding - Voedingsstoffen",
    "fhir.NutritionOrderOralDietNutrient.amount": "Hoeveelheid",
    "fhir.NutritionOrderOralDietNutrient.modifier": "Benodigde aanpassing in voedingsstof",
    "fhir.NutritionOrderOralDietTexture": "Textuur voeding",
    "fhir.NutritionOrderOralDietTexture.foodType": "Type voeding",
    "fhir.NutritionOrderOralDietTexture.modifier": "Benodigde aanpassing in textuur",
    "fhir.NutritionOrderSupplement": "Voedingsadvies voedingssupplement",
    "fhir.NutritionOrderSupplement.instruction": "Aanwijzingen",
    "fhir.NutritionOrderSupplement.productName": "Productnaam voedingssupplement",
    "fhir.NutritionOrderSupplement.quantity": "Hoeveelheid",
    "fhir.NutritionOrderSupplement.supplementType": "Type voedingssupplement",
    "fhir.Observation.BloodPressure.diastolic": "Diastolische",
    "fhir.Observation.BloodPressure.systolic": "Systolische",
    "fhir.Observation.BodyHeight": "Lichaamslengte",
    "fhir.Observation.BodyHeight.height": "Lichaamslengte",
    "fhir.Observation.BodyHeight.source": "Bron",
    "fhir.Observation.BodyTemperature": "Lichaamstemperatuur",
    "fhir.Observation.BodyTemperature.temperature": "Temperatuur",
    "fhir.Observation.BodyWeight": "Lichaamsgewicht",
    "fhir.Observation.BodyWeight.source": "Bron",
    "fhir.Observation.BodyWeight.weight": "Lichaamsgewicht",
    "fhir.Observation.Component.code": "Code",
    "fhir.Observation.Component.dataAbsentReason": "Reden Ontbreken Gegevens",
    "fhir.Observation.Component.interpretation": "Beoordeling",
    "fhir.Observation.Component.value": "Waarde",
    "fhir.Observation.Glucose": "Glucose",
    "fhir.Observation.Glucose.glucose": "Glucose",
    "fhir.Observation.HeartAndPulseRates.rate": "Frequentie",
    "fhir.Observation.HeartAndPulseRates.type": "Type",
    "fhir.Observation.O2Saturation": "Zuurstofsaturatie",
    "fhir.Observation.O2Saturation.o2-saturation": "Zuurstofsaturatie",
    "fhir.Observation.ReferenceRange.age": "Leeftijd",
    "fhir.Observation.ReferenceRange.appliesTo": "Is van toepassing op",
    "fhir.Observation.ReferenceRange.high": "Hoog",
    "fhir.Observation.ReferenceRange.low": "Laag",
    "fhir.Observation.ReferenceRange.text": "Tekst",
    "fhir.Observation.ReferenceRange.type": "Type",
    "fhir.Observation.RespiratoryRate.rate": "Frequentie",
    "fhir.Observation.basedOn": "Gebaseerd op",
    "fhir.Observation.bodySite": "Lichaamsdeel of orgaan",
    "fhir.Observation.category": "Categorie",
    "fhir.Observation.clinicalStatus": "Allergie Status",
    "fhir.Observation.code": "Code",
    "fhir.Observation.comment": "Toelichting",
    "fhir.Observation.component": "Component",
    "fhir.Observation.context": "Context",
    "fhir.Observation.criticality": "Mate Van Kritiek Zijn",
    "fhir.Observation.dataAbsentReason": "Reden Ontbreken Gegevens",
    "fhir.Observation.date": "Datum",
    "fhir.Observation.derivedFrom": "Organisatie",
    "fhir.Observation.device": "Meetinstrument",
    "fhir.Observation.effective": "Datum",
    "fhir.Observation.effectivePeriod": "Periode",
    "fhir.Observation.encounter": "Contact",
    "fhir.Observation.focus": "Focus",
    "fhir.Observation.hasMember": "Gerelateerde bronnen",
    "fhir.Observation.interpretation": "Interpretatie",
    "fhir.Observation.issued": "Testuitslag",
    "fhir.Observation.method": "Meet Methode",
    "fhir.Observation.name": "Naam",
    "fhir.Observation.partOf": "Maakt deel uit van",
    "fhir.Observation.performer": "Gemeten door",
    "fhir.Observation.period": "Periode",
    "fhir.Observation.referenceRange": "Referentiebereik",
    "fhir.Observation.respirationRate.administeredOxygen": "Toegediende Zuurstof",
    "fhir.Observation.respirationRate.flowRate": "Debiet",
    "fhir.Observation.respirationRate.inspiredOxygen": "Geïnspireerde Zuurstof",
    "fhir.Observation.situation": "Situatie",
    "fhir.Observation.specimen": "Monster",
    "fhir.Observation.status": "Status",
    "fhir.Observation.subject": "Patiënt",
    "fhir.Observation.timingEvent": "Timing evenement",
    "fhir.Observation.use": "Gebruik",
    "fhir.Observation.value": "Waarde",
    "fhir.Observation.valueCodeableConcept": "Waarde",
    "fhir.Observation.verificationStatus": "Verificatie Status",
    "fhir.Organization.city": "Stad",
    "fhir.Organization.contact.name": "Naam",
    "fhir.Organization.contact.purpose": "Doel",
    "fhir.Organization.name": "Naam",
    "fhir.Organization.type": "Type",
    "fhir.OrganizationContact.name": "Naam",
    "fhir.OrganizationContact.purpose": "Doel",
    "fhir.Patient": "Patiënt",
    "fhir.Patient.birthDate": "Geboortedatum",
    "fhir.Patient.deceased": "Overleden",
    "fhir.Patient.deceased.no": "Nee",
    "fhir.Patient.deceased.yes": "Ja",
    "fhir.Patient.gender": "Geslacht",
    "fhir.Patient.legalStatus": "Juridische Status",
    "fhir.Patient.lifeStance": "Levensovertuiging",
    "fhir.Patient.maritalStatus": "Burgerlijke Staat",
    "fhir.Patient.multipleBirth": "Meerling Indicator",
    "fhir.Patient.multipleBirth.No": "Nee",
    "fhir.Patient.multipleBirth.no": "Nee",
    "fhir.Patient.multipleBirth.yes": "Ja",
    "fhir.Patient.name": "Naamgegevens",
    "fhir.Patient.nationality": "Nationaliteit",
    "fhir.Patient.preferredPharmacy": "Apotheek van voorkeur",
    "fhir.Patient.proficiency": "Taalvaardigheid",
    "fhir.Patient.telecom": "Telefoon",
    "fhir.PatientContact.gender": "Geslacht",
    "fhir.PatientContact.name": "Naam",
    "fhir.PatientContact.organization": "Zorgaanbieder",
    "fhir.PatientContact.period": "Periode",
    "fhir.PatientContact.relation": "Relatie",
    "fhir.PatientContact.relationship": "Relatie",
    "fhir.PatientContact.role": "Rol",
    "fhir.Period.end": "Tot",
    "fhir.Period.lowerTextOfEnd": "tot",
    "fhir.Period.start": "Van",
    "fhir.Period.upperTextOfEnd": "Tot",
    "fhir.PortabilityReport.downloadReport": "Download rapport",
    "fhir.PortabilityReport.downloadingReport": "Rapport downloaden...",
    "fhir.PortabilityReport.footer": "Uw rapport wordt als een XML file gedownload volgens de MedMij Richtlijnen. U kunt het bekijken in een teksteditor, Excel of vergelijkbare applicaties.",
    "fhir.PortabilityReport.header": "Exporteer uw portabiliteitsrapport",
    "fhir.PortabilityReport.select": "Als je deze app ooit aan een van je zorgverleners hebt gekoppeld, kun je een kopie van je overdraagbaarheidsrapport aanvragen. Kies eerst de periode die je nodig hebt.",
    "fhir.Practitioner.Qualification.code": "Code",
    "fhir.Practitioner.Qualification.period": "Periode",
    "fhir.Practitioner.birthDate": "Geboortedatum",
    "fhir.Practitioner.birthdate": "Geboortedatum",
    "fhir.Practitioner.communication": "Taal",
    "fhir.Practitioner.gender": "Geslacht",
    "fhir.Practitioner.name": "Naam",
    "fhir.Practitioner.qualification": "Kwalificatie",
    "fhir.PractitionerRole.active": "Actief",
    "fhir.PractitionerRole.availabilityExceptions": "Uitzonderingen beschikbaarheid",
    "fhir.PractitionerRole.code": "Code",
    "fhir.PractitionerRole.healthcareService": "Zorgorganisatie",
    "fhir.PractitionerRole.location": "Locatie",
    "fhir.PractitionerRole.organization": "Zorgaanbieder",
    "fhir.PractitionerRole.period": "Periode",
    "fhir.PractitionerRole.practitioner": "Zorgverlener",
    "fhir.PractitionerRole.specialty": "Specialisme",
    "fhir.Procedure": "Verrichting",
    "fhir.Procedure.basedOn": "Gebaseerd op",
    "fhir.Procedure.bodySite": "Lichaamsdeel of orgaan",
    "fhir.Procedure.category": "Categorie",
    "fhir.Procedure.code": "Verrichting",
    "fhir.Procedure.complication": "Complicatie",
    "fhir.Procedure.complicationDetail": "Toelichting Complicatie",
    "fhir.Procedure.followUp": "Instructies voor opvolging",
    "fhir.Procedure.laterality": "Zijde",
    "fhir.Procedure.location": "Zorginstelling waar procedure plaatsvindt",
    "fhir.Procedure.method": "Methode",
    "fhir.Procedure.outcome": "Uitkomst",
    "fhir.Procedure.partOf": "Maakt deel uit van",
    "fhir.Procedure.performed": "Uitgevoerd",
    "fhir.Procedure.reasonCode": "Reden voor verrichting",
    "fhir.Procedure.reasonReference": "Referentie voor reden voor verrichting",
    "fhir.Procedure.report": "Rapportage",
    "fhir.Procedure.status": "Status",
    "fhir.Procedure.subject": "Betreffende",
    "fhir.Procedure.usedCode": "Code verrichting",
    "fhir.Procedure.usedReference": "Referentie",
    "fhir.ProcedureFocalDevice": "Implantaat",
    "fhir.ProcedureFocalDevice.action": "Actie",
    "fhir.ProcedureFocalDevice.header": "Implantaat",
    "fhir.ProcedureFocalDevice.manipulated": "Manipulatie implantaat",
    "fhir.ProcedureFocalDevices.header": "Implantaten",
    "fhir.ProcedurePerformer": "Uitgevoerd door",
    "fhir.ProcedurePerformer.actor": "Uitvoerende",
    "fhir.ProcedurePerformer.header": "Uitgevoerd door",
    "fhir.ProcedurePerformer.onBehalfOf": "Namens",
    "fhir.ProcedurePerformers.header": "Procedure uitvoerenden",
    "fhir.Range.high": "Tot",
    "fhir.Range.low": "Van",
    "fhir.Specimen.Collection.bodySite": "Locatie",
    "fhir.Specimen.Collection.collected": "Monsterafname tijdstip",
    "fhir.Specimen.Collection.collector": "Monsterafname gedaan door",
    "fhir.Specimen.Collection.method": "Methode Monsterafname",
    "fhir.Specimen.Collection.quantity": "Hoeveelheid",
    "fhir.Specimen.Container.additive": "Toevoeging",
    "fhir.Specimen.Container.capacity": "Inhoud",
    "fhir.Specimen.Container.description": "Beschrijving",
    "fhir.Specimen.Container.specimenQuantity": "Monster hoeveelheid",
    "fhir.Specimen.Container.type": "Containertype",
    "fhir.Specimen.Processing.additive": "Toevoeging",
    "fhir.Specimen.Processing.description": "Beschrijving",
    "fhir.Specimen.Processing.procedure": "Procedure verwerking",
    "fhir.Specimen.Processing.time": "Tijdstip verwerking",
    "fhir.Specimen.collection": "Details monsterafname",
    "fhir.Specimen.container": "Container",
    "fhir.Specimen.parent": "Primaire monster",
    "fhir.Specimen.processing": "Wordt verwerkt",
    "fhir.Specimen.receivedTime": "Tijdstip ontvangst",
    "fhir.Specimen.request": "Verzoek",
    "fhir.Specimen.status": "Status",
    "fhir.Specimen.subject": "Afkomst Monster",
    "fhir.Specimen.type": "Type",
    "fhir.Timing.code": "Code",
    "fhir.Timing.event": "Tijdstip",
    "fhir.Timing.header": "Tijdsschema Inname \/ Toediening",
    "fhir.TimingRepeat.boundsDuration": "Specificatie duur van tijdsschema",
    "fhir.TimingRepeat.boundsPeriod": "Specificatie periode van tijdsschema",
    "fhir.TimingRepeat.boundsRange": "Specificatie range duur van tijdsschema",
    "fhir.TimingRepeat.count": "Telling van het gewenst aantal herhalingen",
    "fhir.TimingRepeat.countMax": "Maximaal aantal herhalingen",
    "fhir.TimingRepeat.dayOfWeek": "Dag van de week",
    "fhir.TimingRepeat.duration": "Tijdsduur",
    "fhir.TimingRepeat.durationMax": "Tijdsduur max",
    "fhir.TimingRepeat.durationUnit": "Tijdsduur eenheid",
    "fhir.TimingRepeat.frequency": "Aantal herhalingen",
    "fhir.TimingRepeat.frequencyMax": "Maximaal aantal herhalingen",
    "fhir.TimingRepeat.offset": "Aantal minuten verwijderd van gebeurtenis",
    "fhir.TimingRepeat.period": "Aantal tijdseenheden",
    "fhir.TimingRepeat.periodMax": "Max aantal tijdseenheden",
    "fhir.TimingRepeat.periodUnit": "Tijdseenheid",
    "fhir.TimingRepeat.timeOfDay": "Tijdstip dag",
    "fhir.TimingRepeat.when": "Aanduiding wanneer",
    "fhir.address.city": "Plaats",
    "fhir.administration-agreement.medication": "Geneesmiddel",
    "fhir.administration-agreement.status": "Status",
    "fhir.administration-agreement.whenHandedOver": "Verstrekkingsdatum",
    "fhir.administrative-gender.female": "Vrouw",
    "fhir.administrative-gender.male": "Man",
    "fhir.administrative-gender.other": "Anders",
    "fhir.administrative-gender.unknown": "Onbekend",
    "fhir.allergy-intolerance-category.biologic": "Biologisch",
    "fhir.allergy-intolerance-category.environment": "Omgeving",
    "fhir.allergy-intolerance-category.food": "Voeding",
    "fhir.allergy-intolerance-category.medication": "Medicatie",
    "fhir.allergy-intolerance-clinical-status.active": "Actief",
    "fhir.allergy-intolerance-clinical-status.inactive": "Inactief",
    "fhir.allergy-intolerance-clinical-status.resolved": "Niet langer aanwezig",
    "fhir.allergy-intolerance-criticality.high": "Hoog risico",
    "fhir.allergy-intolerance-criticality.low": "Laag risico",
    "fhir.allergy-intolerance-criticality.unable-to-assess": "Niet mogelijk om risico te beoordelen",
    "fhir.allergy-intolerance-reaction-severity.mild": "Licht",
    "fhir.allergy-intolerance-reaction-severity.moderate": "Matig",
    "fhir.allergy-intolerance-reaction-severity.severe": "Ernstig",
    "fhir.allergy-intolerance-type.allergy": "Allergie",
    "fhir.allergy-intolerance-type.intolerance": "Intolerantie",
    "fhir.allergy-intolerance-verification-status.confirmed": "Bevestigd",
    "fhir.allergy-intolerance-verification-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.allergy-intolerance-verification-status.refuted": "Uitgesloten",
    "fhir.allergy-intolerance-verification-status.unconfirmed": "Onbevestigd",
    "fhir.allergyintolerance-verification.presumed": "Vermoedelijk",
    "fhir.appointment-participant-required.information-only": "Ter informatie",
    "fhir.appointment-participant-required.optional": "Optioneel",
    "fhir.appointment-participant-required.required": "Vereist",
    "fhir.appointment-participant-status.accepted": "Geaccepteerd",
    "fhir.appointment-participant-status.declined": "Geweigerd",
    "fhir.appointment-participant-status.needs-action": "Onbevestigd",
    "fhir.appointment-participant-status.tentative": "Voorlopig geaccepteerd",
    "fhir.appointment-status.arrived": "Patiënt(en) is\/zijn gearriveerd",
    "fhir.appointment-status.booked": "Bevestigd",
    "fhir.appointment-status.cancelled": "Geannuleerd",
    "fhir.appointment-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.appointment-status.fulfilled": "Voldaan aan planningscriteria",
    "fhir.appointment-status.noshow": "Niet verschenen",
    "fhir.appointment-status.pending": "In afwachting van bevestiging",
    "fhir.appointment-status.proposed": "Voorgesteld",
    "fhir.appointment.appointmentType": "Type",
    "fhir.appointment.end": "Einddatum",
    "fhir.appointment.minutesDuration": "Ingeplande tijdsduur",
    "fhir.appointment.reason": "Reden",
    "fhir.appointment.serviceType": "Dienst",
    "fhir.appointment.specialty": "Specialisme",
    "fhir.appointment.start": "Begindatum",
    "fhir.appointment.status": "Status",
    "fhir.availability.nearline": "Nearline (externe HDD, SSD, cloud opslag etc.)",
    "fhir.availability.offline": "Offline",
    "fhir.availability.online": "Online",
    "fhir.availability.unavailable": "Niet beschikbaar",
    "fhir.boolean.no": "Nee",
    "fhir.boolean.yes": "Ja",
    "fhir.composition-section-mode.changes": "Veranderingen",
    "fhir.composition-section-mode.snapshot": "Snapshot",
    "fhir.composition-section-mode.working": "Werkend",
    "fhir.composition-status.amended": "Naderhand gewijzigd",
    "fhir.composition-status.appends": "Naderhand aangevuld",
    "fhir.composition-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.composition-status.final": "Definitief",
    "fhir.composition-status.legal": "Juridisch",
    "fhir.composition-status.official": "Officieel",
    "fhir.composition-status.personal": "Op persoonlijke titel",
    "fhir.composition-status.preliminary": "Voorlopig",
    "fhir.composition-status.professional": "Professioneel",
    "fhir.composition-status.replaces": "Vervangend document",
    "fhir.composition-status.signs": "Ondertekend document",
    "fhir.composition-status.transforms": "Getransformeerd",
    "fhir.condition-clinical-status.active": "Actueel",
    "fhir.condition-clinical-status.inactive": "Niet actueel",
    "fhir.condition-clinical-status.recurrence": "Recidiverend",
    "fhir.condition-clinical-status.relapse": "Terugval",
    "fhir.condition-clinical-status.remission": "In remissie",
    "fhir.condition-clinical-status.resolved": "Hersteld",
    "fhir.condition-clinical-status.unknown": "Onbekend",
    "fhir.condition-verification-status.confirmed": "Bevestigd",
    "fhir.condition-verification-status.differential": "Differentieel",
    "fhir.condition-verification-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.condition-verification-status.provisional": "Tijdelijk",
    "fhir.condition-verification-status.refuted": "Uitgesloten",
    "fhir.condition-verification-status.unconfirmed": "Onbevestigd",
    "fhir.condition-verification-status.unknown": "Onbekend",
    "fhir.consent.category": "Categorie",
    "fhir.consent.comment": "Toelichting",
    "fhir.consent.dateTime": "Datum",
    "fhir.consent.disorder": "Aandoening",
    "fhir.consent.exceptType": "Type uitzondering",
    "fhir.consent.treatment": "Behandeling",
    "fhir.consent.treatmentPermitted": "Behandeling Toegestaan",
    "fhir.consent.type": "Type",
    "fhir.consent.verifiedWith": "Geverifieerd bij",
    "fhir.contact-entity-type.admin": "Administratieve inlichtingen",
    "fhir.contact-entity-type.bill": "Betaal adres",
    "fhir.contact-entity-type.hr": "Personeelszaken",
    "fhir.contact-entity-type.patinf": "Patiënt",
    "fhir.contact-entity-type.payor": "Betaler",
    "fhir.contact-entity-type.press": "Pers \/ Media",
    "fhir.contact-point-system.email": "E-mail",
    "fhir.contact-point-system.fax": "Fax",
    "fhir.contact-point-system.mail": "E-mail",
    "fhir.contact-point-system.other": "Anders",
    "fhir.contact-point-system.pager": "Pieper",
    "fhir.contact-point-system.phone": "Telefoonnummer",
    "fhir.contact-point-system.sms": "SMS",
    "fhir.contact-point-system.url": "URL",
    "fhir.contact-point-use.home": "Telefoonnummer thuis of privé e-mailadres",
    "fhir.contact-point-use.mobile": "Mobiel telefoonnummer",
    "fhir.contact-point-use.old": "Oud",
    "fhir.contact-point-use.temp": "Tijdelijk telefoonnummer",
    "fhir.contact-point-use.work": "Zakelijk telefoonnummer of zakelijk e-mailadres",
    "fhir.coverage-status.active": "Actief",
    "fhir.coverage-status.cancelled": "Geannuleerd",
    "fhir.coverage-status.draft": "Conceptversie",
    "fhir.coverage-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.device-udi-entry-type.barcode": "Barcode",
    "fhir.device-udi-entry-type.card": "Kaart",
    "fhir.device-udi-entry-type.manual": "Handleiding",
    "fhir.device-udi-entry-type.rfid": "RFID",
    "fhir.device-udi-entry-type.self-reported": "Zelf gerapporteerd",
    "fhir.device-udi-entry-type.unknown": "Onbekend",
    "fhir.device-use-statement.active": "Actief",
    "fhir.device-use-statement.completed": "Voltooid",
    "fhir.device-use-statement.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.device-use-statement.intended": "Bedoeld",
    "fhir.device-use-statement.on-hold": "In de wacht",
    "fhir.device-use-statement.stopped": "Gestopt",
    "fhir.diagnostic-report-status.amended": "Naderhand gewijzigd",
    "fhir.diagnostic-report-status.appended": "Aangevuld na definitieve vaststelling",
    "fhir.diagnostic-report-status.cancelled": "Geannuleerd",
    "fhir.diagnostic-report-status.corrected": "Gecorrigeerd na definitieve vaststelling",
    "fhir.diagnostic-report-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.diagnostic-report-status.final": "Definitief vastgesteld",
    "fhir.diagnostic-report-status.partial": "Deelrapportage",
    "fhir.diagnostic-report-status.preliminary": "Voorlopig",
    "fhir.diagnostic-report-status.registered": "Geregistreerd",
    "fhir.diagnostic-report-status.unknown": "Onbekend",
    "fhir.document-manifest-status.current": "Huidig",
    "fhir.document-manifest-status.entered-in-error": "Ingevoerd in fout",
    "fhir.document-manifest-status.superseded": "Vervangen",
    "fhir.document-reference-doc-status.amended": "Gewijzigd",
    "fhir.document-reference-doc-status.appended": "Toegevoegd",
    "fhir.document-reference-doc-status.entered-in-error": "Ingevoerd in Fout",
    "fhir.document-reference-doc-status.final": "Definitief",
    "fhir.document-reference-doc-status.preliminary": "Voorlopig",
    "fhir.document-reference-relates-to-code.appends": "Voegt toe",
    "fhir.document-reference-relates-to-code.replaces": "Vervangt",
    "fhir.document-reference-relates-to-code.signs": "Ondertekent",
    "fhir.document-reference-relates-to-code.transforms": "Transformeert",
    "fhir.document-reference-status.current": "Huidig",
    "fhir.document-reference-status.entered-in-error": "Ingevoerd in fout",
    "fhir.document-reference-status.superseded": "Vervangen",
    "fhir.email": "Email",
    "fhir.encounter-status.active": "In gebruik",
    "fhir.encounter-status.arrived": "Patiënt(en) is\/zijn gearriveerd",
    "fhir.encounter-status.cancelled": "Geannuleerd",
    "fhir.encounter-status.completed": "Afgerond",
    "fhir.encounter-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.encounter-status.finished": "Afgerond",
    "fhir.encounter-status.in-progress": "In uitvoering",
    "fhir.encounter-status.onleave": "Met verlof",
    "fhir.encounter-status.planned": "Ingepland",
    "fhir.encounter-status.reserved": "Gereserveerd",
    "fhir.encounter-status.triaged": "Triage uitgevoerd",
    "fhir.encounter-status.unknown": "Onbekend",
    "fhir.encounter.class": "Type",
    "fhir.encounter.episodeOfCare": "Episoden",
    "fhir.encounter.participants": "Deelnemers",
    "fhir.encounter.period": "Periode",
    "fhir.encounterClassHistories": "Contact Geschiedenis",
    "fhir.encounterClassHistory": "Contact Geschiedenis",
    "fhir.episode-of-care-status.active": "Actueel",
    "fhir.episode-of-care-status.cancelled": "Geannuleerd",
    "fhir.episode-of-care-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.episode-of-care-status.finished": "Afgerond",
    "fhir.episode-of-care-status.onhold": "Gepauzeerd",
    "fhir.episode-of-care-status.planned": "Ingepland",
    "fhir.episode-of-care-status.waitlist": "Wachtlijst",
    "fhir.fhir.AppointmentParticipant.actor.practitionerRole": "Rol zorgverlener",
    "fhir.flag-status.active": "Actief",
    "fhir.flag-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.flag-status.inactive": "Inactief",
    "fhir.functionalOrMentalStatus.period": "Periode",
    "fhir.functionalOrMentalStatus.status": "Status",
    "fhir.functionalOrMentalStatus.value": "Waarde",
    "fhir.healthInsurance.name": "Naamgegevens",
    "fhir.healthInsurance.status": "Status",
    "fhir.healthInsurance.subscriberId": "Verzekerde Nummer",
    "fhir.identifier-use.official": "Officieel",
    "fhir.identifier-use.old": "Verouderd",
    "fhir.identifier-use.secondary": "Secundair",
    "fhir.identifier-use.temp": "Tijdelijk",
    "fhir.identifier-use.usual": "Gangbaar",
    "fhir.immunization-status.completed": "Uitgevoerd",
    "fhir.immunization-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.immunization-status.not-done": "Niet uitgevoerd",
    "fhir.medication-request-intent.instance-order": "Instance Order",
    "fhir.medication-request-intent.order": "Opdracht",
    "fhir.medication-request-intent.plan": "Plan van aanpak",
    "fhir.medication-request-intent.proposal": "Voorstel",
    "fhir.medication-request-on-hold.on-hold": "Gepauzeerd",
    "fhir.medication-request-routine.asap": "z.s.m.",
    "fhir.medication-request-routine.routine": "Routine",
    "fhir.medication-request-routine.stat": "Direct \/ met hoogste prioriteit",
    "fhir.medication-request-routine.urgent": "Urgent",
    "fhir.medication-request-status.active": "Actief",
    "fhir.medication-request-status.cancelled": "Geannuleerd",
    "fhir.medication-request-status.completed": "Uitgevoerd",
    "fhir.medication-request-status.draft": "Conceptversie",
    "fhir.medication-request-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.medication-request-status.stopped": "Afgebroken",
    "fhir.medication-request-status.unknown": "Onbekend",
    "fhir.medication-statement-status.active": "Actief",
    "fhir.medication-statement-status.completed": "Afgerond",
    "fhir.medication-statement-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.medication-statement-status.intended": "Toekomstig",
    "fhir.medication-statement-status.on-hold": "Gepauzeerd",
    "fhir.medication-statement-status.stopped": "Gestopt",
    "fhir.medication-statement-taken.n": "Nee",
    "fhir.medication-statement-taken.na": "Niet van toepassing",
    "fhir.medication-statement-taken.unk": "Onbekend",
    "fhir.medication-statement-taken.y": "Ja",
    "fhir.medication-status.active": "Actief",
    "fhir.medication-status.completed": "Afgerond",
    "fhir.medication-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.medication-status.in-progress": "In uitvoering",
    "fhir.medication-status.inactive": "Niet actueel",
    "fhir.medication-status.on-hold": "Gepauzeerd",
    "fhir.medication-status.preparation": "Bereiding",
    "fhir.medication-status.stopped": "Gestopt",
    "fhir.medicationUse.effective": "Gebruiksdatum",
    "fhir.medicationUse.medication": "Geneesmiddel",
    "fhir.medicationUse.status": "Status",
    "fhir.name-part-qualifier.BR": "Voornamen",
    "fhir.name-part-qualifier.CL": "Roepnaam",
    "fhir.name-part-qualifier.IN": "Initialen",
    "fhir.name-use.anonymous": "Anoniem",
    "fhir.name-use.maiden": "Gebruikte naam vóór huwelijk",
    "fhir.name-use.nickname": "Bijnaam",
    "fhir.name-use.official": "Officieel",
    "fhir.name-use.old": "Verouderd",
    "fhir.name-use.temp": "Tijdelijk",
    "fhir.name-use.usual": "Gewoonlijk",
    "fhir.narrative-status.additional": "Extra",
    "fhir.narrative-status.empty": "Leeg",
    "fhir.narrative-status.extensions": "Extensies",
    "fhir.narrative-status.generated": "Gegenereerd",
    "fhir.nl-core-address.adresSoort": "Adres soort",
    "fhir.nutrition-order-status.active": "Actief",
    "fhir.nutrition-order-status.cancelled": "Geannuleerd",
    "fhir.nutrition-order-status.completed": "Afgerond",
    "fhir.nutrition-order-status.draft": "Conceptversie",
    "fhir.nutrition-order-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.nutrition-order-status.on-hold": "Gepauzeerd",
    "fhir.nutrition-order-status.planned": "Ingepland",
    "fhir.nutrition-order-status.proposed": "Voorgesteld",
    "fhir.nutrition-order-status.requested": "Aangevraagd",
    "fhir.nutritionAdvice.dateTime": "Datum",
    "fhir.nutritionAdvice.oralDietFluidConsistencyType": "Consistentie",
    "fhir.nutritionAdvice.oralDietType": "Dieet",
    "fhir.nutritionAdvice.orderer": "Opdrachtgever",
    "fhir.nutritionAdvice.status": "Status",
    "fhir.observation-status.amended": "Gewijzigd",
    "fhir.observation-status.cancelled": "Geannuleerd",
    "fhir.observation-status.corrected": "Gecorrigeerd",
    "fhir.observation-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.observation-status.final": "Definitief",
    "fhir.observation-status.preliminary": "Voorlopig",
    "fhir.observation-status.registered": "Geregistreerd",
    "fhir.observation-status.unknown": "Onbekend",
    "fhir.observation.components.header": "Componenten",
    "fhir.patientContact.name": "Naamgegevens",
    "fhir.patientContact.relation": "Relatie",
    "fhir.patientContact.role": "Rol",
    "fhir.procedure-status.aborted": "Afgebroken",
    "fhir.procedure-status.completed": "Afgerond",
    "fhir.procedure-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.procedure-status.in-progress": "In uitvoering",
    "fhir.procedure-status.preparation": "Voorbereiding",
    "fhir.procedure-status.suspended": "Uitgesteld",
    "fhir.procedure-status.unknown": "Onbekend",
    "fhir.specimen-status.available": "Aanwezig",
    "fhir.specimen-status.entered-in-error": "Ten onrechte ingevoerd",
    "fhir.specimen-status.unavailable": "Niet aanwezig",
    "fhir.specimen-status.unsatisfactory": "Monster ongeschikt voor bepaling",
    "fhir.timing-repeat-day-of-week.friday": "Vrijdag",
    "fhir.timing-repeat-day-of-week.monday": "Maandag",
    "fhir.timing-repeat-day-of-week.saturday": "Zaterdag",
    "fhir.timing-repeat-day-of-week.sunday": "Zondag",
    "fhir.timing-repeat-day-of-week.thursday": "Donderdag",
    "fhir.timing-repeat-day-of-week.tuesday": "Dinsdag",
    "fhir.timing-repeat-day-of-week.wednesday": "Woensdag",
    "fhir.timing-repeat-unit.days": "Dagen",
    "fhir.timing-repeat-unit.hours": "Uren",
    "fhir.timing-repeat-unit.minutes": "Minuten",
    "fhir.timing-repeat-unit.months": "Maanden",
    "fhir.timing-repeat-unit.seconds": "Seconden",
    "fhir.timing-repeat-unit.weeks": "Weken",
    "fhir.timing-repeat-unit.years": "Jaren",
    "fhir.v3-MaritalStatus.A": "Niet officieel gehuwd",
    "fhir.v3-MaritalStatus.C": "Gehuwd conform het gewoonterecht",
    "fhir.v3-MaritalStatus.D": "Gescheiden",
    "fhir.v3-MaritalStatus.I": "Interlocutoir",
    "fhir.v3-MaritalStatus.L": "Wettelijk gescheiden",
    "fhir.v3-MaritalStatus.M": "Gehuwd",
    "fhir.v3-MaritalStatus.P": "Polygaam",
    "fhir.v3-MaritalStatus.S": "Nooit gehuwd",
    "fhir.v3-MaritalStatus.T": "Geregistreerd partnerschap",
    "fhir.v3-MaritalStatus.U": "Niet gehuwd",
    "fhir.v3-MaritalStatus.W": "Weduwe of weduwnaar",
    "fhir.v3-NullFlavor.UNK": "Onbekend",
    "grid.UnableToAdd": "Kan niet toevoegen. Probeer het opnieuw.",
    "key": "nl",
    "nav.btn.add": "Nieuwe ",
    "nav.btn.add.applehealth": "Apple Gezondheid",
    "nav.btn.add.device": "Verbind Apparaat",
    "nav.btn.add.measurement": "Voeg Meting Toe",
    "nav.btn.add.records": "Importeer Dossiers",
    "ngb.alert.close": "Sluiten",
    "ngb.carousel.next": "Volgende",
    "ngb.carousel.previous": "Vorige",
    "ngb.carousel.slide-number": "Slide {$INTERPOLATION} van {$INTERPOLATION_1}",
    "ngb.datepicker.next-month": "Volgende maand",
    "ngb.datepicker.previous-month": "Vorige maand",
    "ngb.datepicker.select-month": "Selecteer maand",
    "ngb.datepicker.select-year": "Selecteer jaar",
    "ngb.pagination.first": "««",
    "ngb.pagination.first-aria": "Eerste",
    "ngb.pagination.last": "»»",
    "ngb.pagination.last-aria": "Laatste",
    "ngb.pagination.next": "»",
    "ngb.pagination.next-aria": "Volgende",
    "ngb.pagination.previous": "«",
    "ngb.pagination.previous-aria": "Vorige",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.AM": "{$INTERPOLATION}",
    "ngb.timepicker.HH": "UU",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.SS": "SS",
    "ngb.timepicker.decrement-hours": "Verlaag uren",
    "ngb.timepicker.decrement-minutes": "Verlaag minuten",
    "ngb.timepicker.decrement-seconds": "Verlaag seconden",
    "ngb.timepicker.hours": "Uren",
    "ngb.timepicker.increment-hours": "Verhoog uren",
    "ngb.timepicker.increment-minutes": "Verhoog minuten",
    "ngb.timepicker.increment-seconds": "Verhoog seconden",
    "ngb.timepicker.minutes": "Minuten",
    "ngb.timepicker.seconds": "Seconden",
    "ngb.toast.close-aria": "Sluiten",
    "table.alcohol-use.column.period": "Periode",
    "table.alcohol-use.column.use": "Gebruik",
    "table.column.consistency": "Consistentie",
    "table.column.date": "Datum",
    "table.column.details": "Gegevens",
    "table.column.diet": "Dieet",
    "table.column.disease": "Ziekte",
    "table.column.email": "E-mail Adressen",
    "table.column.healthcareProvider": "Zorgverlener",
    "table.column.name": "Naam",
    "table.column.participants": "Zorgverlener",
    "table.column.period": "Periode",
    "table.column.phone": "Telefoonnummers",
    "table.column.role": "Rol",
    "table.column.situation": "Woning Type",
    "table.column.source": "Bron",
    "table.column.status": "Status",
    "table.column.substance": "Stof of product",
    "table.column.type": "Type",
    "table.column.use": "Gebruik",
    "table.column.vaccine": "Vaccin",
    "table.column.value": "Waarde",
    "table.column.weight": "Gewicht",
    "table.drug-use.column.period": "Periode",
    "table.drug-use.column.use": "Gebruik",
    "table.entry.manual": "Handmatig",
    "table.tobacco-use.column.period": "Periode",
    "table.tobacco-use.column.use": "Gebruik"
}
  }