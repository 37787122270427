import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeviceService, ShareMeasurementsService } from '@core/services';
import { configId } from '@environments/environment';
import { ErrorCodes, getLibraryFrom, getUrlHandler } from '@globals';
import { Store } from '@ngrx/store';
import { ERROR_UI_ACTIONS } from '@store/app';
import { DIGI_ME_SAAS_RETURN_ACTIONS, USER_API_ACTIONS } from '@store/digi.me';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, filter, map, take, tap } from 'rxjs';

@Component({
  selector: 'app-return',
  template: ``,
})
export class ReturnComponent implements OnInit {
  // TODO: Split the return's to different urls within the app, e.g. /return/onboard, /return/revoke, /return/authorize, /return/reauthorize
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly shareMeasurementsService: ShareMeasurementsService,
    private readonly deviceService: DeviceService,
    private readonly oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    this.deviceService.restoreToSessionStorage();

    const isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
      map((result) => result.allConfigsAuthenticated.some((x) => x.configId === configId && x.isAuthenticated)),
    );

    const authenticatedOrLibraryAndParams$ = combineLatest([this.activatedRoute.queryParams, isAuthenticated$]).pipe(
      filter(([params, isAuthenticated]) => isAuthenticated || getLibraryFrom(params) !== undefined),
      map(([params]) => params),
    );

    combineLatest([this.activatedRoute.queryParams, isAuthenticated$])
      .pipe(
        filter(([params, isAuthenticated]) => !isAuthenticated && getLibraryFrom(params) === undefined),
        take(1),
      )
      .subscribe(([params]) => {
        // Check if the user comes from a digi.me flow with an error code.
        // TODO: Can we assume that we handle all errors? Otherwise it will redirect to the login page.
        if (params['success'] === 'false' && Object.values(ErrorCodes).includes(params['errorCode'])) {
          this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeFailed({ params }));
        } else {
          // When tabs have been switch during the Saas flow
          // Force a login, but not for the onboard first account creation flow
          sessionStorage.setItem('returnToProcess', window.location.search);

          this.oidcSecurityService.authorize(configId, {
            urlHandler: getUrlHandler(),
          });
        }
      });

    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter(
          (params: Params) => params['success'] === 'false' && Object.values(ErrorCodes).includes(params['errorCode']),
        ),
      )
      .subscribe((params: Params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeFailed({ params }));
      });

    // Use this flow for the authorization of a first onboarding service response.
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => !!params['code'] && params['success'] === 'true'),
      )
      .subscribe((params: Params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeSucceeded({ params }));
      });

    // Use this flow for the subsequent responses from the onboarding services.
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => !params['code'] && params['success'] === 'true'),
        filter(() => localStorage.getItem('sourceType') === 'pull'),
        tap(async () => await this.router.navigate([`${$localize.locale}`, 'linked-sources'])),
      )
      .subscribe((params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.onboardSucceeded({ params }));
      });

    // Use this flow for the revoke/reauth.
    // TODO: Be able to distinguish reauth and revoke, for now we use the reauth flow for both, because a confirm is needed for reauth
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => !params['code'] && params['result'] === 'success'),
        tap(async () => await this.router.navigate([`${$localize.locale}`, 'linked-sources'])),
      )
      .subscribe((params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.reauthorizeCompleted({ params }));
      });

    // Result fail can get returned by a manage action e.g.
    // Dispatch a general error action on a non success result
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => params['result'] === 'fail'),
      )
      .subscribe((params: Params) => {
        this.store.dispatch(ERROR_UI_ACTIONS.generalFailure({ params }));
      });

    // Use this flow to obtain the account reference necessary for sharing data.
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => params['success'] === 'true' && params['accountReference']),
        filter(() => localStorage.getItem('sourceType') === 'push'),
      )
      .subscribe((params: Params) => {
        // An account reference is necessary to identify the provider to which data will be pushed.
        this.shareMeasurementsService.setAccountReference(params['accountReference']);

        // Begin by retrieving all data associated with the user. After the accounts have been successfully loaded,
        // find the specific account using its reference and then proceed to push data to it.
        this.store.dispatch(USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' }));
      });

    // Use this flow when the user cancels the manage permissions process.
    authenticatedOrLibraryAndParams$
      .pipe(
        take(1),
        filter((params: Params) => params['result'] === 'cancel'),
      )
      .subscribe(() => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.managePermissionsCancelled());
      });
  }
}
