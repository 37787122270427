import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { DigiMeModule } from '@digi.me';
import { getOpenIdConfigs } from '@environments/environment';
import { MobileFileSaver, WebFileSaver } from '@globals';
import { NavigationModule } from '@navigation';
import { SharedModule } from '@shared';
import { AppStoreModule, PageStoreModule } from '@store/app';
import { DigiMeStoreModule } from '@store/digi.me';
import {
  AllergyIntoleranceStoreModule,
  AppointmentStoreModule,
  BinaryStoreModule,
  CompositionStoreModule,
  ConditionStoreModule,
  ConsentStoreModule,
  CoverageStoreModule,
  DeviceStoreModule,
  DeviceUseStatementStoreModule,
  DocumentManifestStoreModule,
  DocumentReferenceStoreModule,
  EncounterStoreModule,
  EpisodeOfCareStoreModule,
  FlagStoreModule,
  ImagingStudyStoreModule,
  ImmunizationRecommendationStoreModule,
  ImmunizationStoreModule,
  MedicationDispenseStoreModule,
  MedicationRequestStoreModule,
  MedicationStatementStoreModule,
  MedicationStoreModule,
  NutritionOrderStoreModule,
  ObservationStoreModule,
  OrganizationStoreModule,
  PatientContactStoreModule,
  PatientStoreModule,
  PractitionerRoleStoreModule,
  PractitionerStoreModule,
  ProcedureStoreModule,
  SortStoreModule,
  SpecimenStoreModule,
} from '@store/hl7fhir';
import {
  AbstractSecurityStorage,
  AuthInterceptor,
  AuthModule,
  StsConfigLoader,
  StsConfigStaticLoader,
} from 'angular-auth-oidc-client';
import { DigiMeResetComponent } from '../widgets/digi.me/digi-me-reset/digi-me-reset.component';
import { MiddleMenuContainerComponent } from '../widgets/navigation/middle-menu/middle-menu.container';
import { AccountCreateComponent } from './components/account-create/account-create.component';
import { AccountCreateContainerComponent } from './components/account-create/account-create.container';
import { AccountDeletionComponent } from './components/account-deletion/account-deletion.component';
import { CallbackComponent } from './components/callback.component';
import { DefaultComponent } from './components/default/default.component';
import { DemoModeModalComponent } from './components/demo-mode-modal/demo-mode-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { ForceUpdateModalComponent } from './components/force-update-modal/force-update-modal.component';
import { MainComponent } from './components/main/main.component';
import { NavigatorOnLineComponent } from './components/network-status/network-status.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReturnComponent } from './components/return/return.component';
import { UseSecurityPromptComponent } from './components/security-propmpt/security-prompt.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UseBiometricsComponent } from './components/use-biometrics/use-biometrics.component';
import { WarningUpdateComponent } from './components/warning-update/warning-update.component';
import {
  ApiInterceptor,
  DemoModeInterceptor,
  FILE_SAVER_TOKEN,
  MobileOidcStorageService,
  SessionStorageService,
} from './services';

const store = [
  AppStoreModule,
  DigiMeStoreModule,
  PatientStoreModule,
  CoverageStoreModule,
  PatientContactStoreModule,
  OrganizationStoreModule,
  PractitionerStoreModule,
  PractitionerRoleStoreModule,
  ObservationStoreModule,
  AllergyIntoleranceStoreModule,
  ConditionStoreModule,
  ImmunizationStoreModule,
  ImmunizationRecommendationStoreModule,
  SpecimenStoreModule,
  ConsentStoreModule,
  NutritionOrderStoreModule,
  MedicationStoreModule,
  MedicationStatementStoreModule,
  MedicationDispenseStoreModule,
  CompositionStoreModule,
  MedicationRequestStoreModule,
  DeviceStoreModule,
  ProcedureStoreModule,
  EpisodeOfCareStoreModule,
  DeviceUseStatementStoreModule,
  ImagingStudyStoreModule,
  AppointmentStoreModule,
  BinaryStoreModule,
  EncounterStoreModule,
  FlagStoreModule,
  DocumentManifestStoreModule,
  DocumentReferenceStoreModule,
  SortStoreModule,
  PageStoreModule,
];

const authFactory = () => {
  const configs = getOpenIdConfigs().openIdConfigs;
  return new StsConfigStaticLoader(configs);
};

@NgModule({
  declarations: [
    DefaultComponent,
    PageNotFoundComponent,
    ReturnComponent,
    CallbackComponent,
    MainComponent,
    TopMenuComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    NavigationModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
      },
    }),
    ...store,
    DigiMeModule,
    AccountCreateComponent,
    AccountCreateContainerComponent,
    AccountDeletionComponent,
    ErrorModalComponent,
    ForceUpdateModalComponent,
    WarningUpdateComponent,
    NavigatorOnLineComponent,
    DigiMeResetComponent,
    MiddleMenuContainerComponent,
    UseBiometricsComponent,
    UseSecurityPromptComponent,
    DemoModeModalComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DemoModeInterceptor,
      multi: true,
    },
    {
      provide: FILE_SAVER_TOKEN,
      useFactory: () => (Capacitor.isNativePlatform() ? new MobileFileSaver() : new WebFileSaver()),
    },
    {
      provide: AbstractSecurityStorage,
      useFactory: () => (Capacitor.isNativePlatform() ? new MobileOidcStorageService() : new SessionStorageService()),
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
    }
  }
}
