import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Logger } from '@logging';
import { AbstractLoggerService } from 'angular-auth-oidc-client';

@Injectable()
export class OidcLoggingService implements AbstractLoggerService {
  private readonly logger = inject(Logger);
  async logError(message: string | object, ...args: any[]): Promise<void> {
    for (const arg of args) {
      if (arg instanceof HttpErrorResponse) {
        await this.logger.logApiException(arg);
      }
    }

    console.error(message, ...args);
  }

  logWarning(message: string | object, ...args: any[]): void {
    console.warn(message, ...args);
  }

  logDebug(message: string | object, ...args: any[]): void {
    console.debug(message, ...args);
  }
}
