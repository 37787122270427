{
    "locale": "en-US",
    "translations": {
    "App.Docs.Date": "Created",
    "App.Docs.Details": "Details",
    "App.Docs.Manifest.Author": "Author",
    "App.Docs.Manifest.Created": "Created",
    "App.Docs.Manifest.Source": "Source",
    "App.Docs.Manifest.Status": "Status",
    "App.Docs.Manifest.Subject": "Subject",
    "App.Docs.Manifest.Type": "Type",
    "App.Docs.Reference.Author": "Author",
    "App.Docs.Reference.Class": "Class",
    "App.Docs.Reference.Created": "Created",
    "App.Docs.Reference.Download": "Download",
    "App.Docs.Reference.FileLocation": "Location",
    "App.Docs.Reference.SecurityLabel": "Security",
    "App.Docs.Reference.Source": "Provider",
    "App.Docs.Reference.Status": "Status",
    "App.Docs.Reference.Subject": "Subject",
    "App.Docs.Reference.Type": "Type",
    "English": "English (United States)",
    "Key": "en-US",
    "account.createdOn": "Account created on",
    "app.AlcoholUse.title": "Alcohol Use",
    "app.Alerts": "Alerts",
    "app.AllergyIntolerance.reaction-empty": "No reaction to show.",
    "app.AllergyIntolerance.reactions.empty": "No reactions to show.",
    "app.AllergyIntolerance.reactions.header": "Reactions",
    "app.AllergyIntolerances.title": "Allergy and Intolerances",
    "app.Appointment.title": "Appointment",
    "app.Appointments.title": "Appointments",
    "app.Condition": "Condition",
    "app.ConditionEvidences.header": "Evidences",
    "app.ConditionStages.header": "Stages",
    "app.ConsentExcept.action": "Action",
    "app.ConsentExcept.class": "Class",
    "app.ConsentExcept.code": "Code",
    "app.ConsentExcept.dataPeriod": "Data Period",
    "app.ConsentExcept.exceptType": "Except Type",
    "app.ConsentExcept.purpose": "Purpose",
    "app.ConsentExcept.securityLabel": "Security Label",
    "app.Consents.title": "Consents given",
    "app.ContactSupport": "Contact Support",
    "app.Devices.title": "Medical Devices",
    "app.Documents.title": "Documents",
    "app.Dosages.header": "Dosages",
    "app.DrugUse.title": "Drug Use",
    "app.Encounter.title": "Encounter",
    "app.Encounters.title": "Encounters",
    "app.Error.message": "If you're stuck reach out to support and we'll do our best to get you unstuck or try again later.",
    "app.Error.onboard.message": "Something went wrong. Please try again. If the issue persists, wait an hour and try again, then contact our support team if needed.",
    "app.Error.onboard.title": "Unable to link your records",
    "app.Error.onboard.userCancel.button.cancel": "Cancel",
    "app.Error.onboard.userCancel.button.request": "Request Your Provider",
    "app.Error.onboard.userCancel.message": "We are adding more and more healthcare providers every week. If you couldn't find yours let us know and we'll try to fast track it.",
    "app.Error.onboard.userCancel.title": "Couldn't find your healthcare provider?",
    "app.Error.title": "Something went wrong",
    "app.Flag.title": "Flag",
    "app.Flags.title": "Flags",
    "app.FunctionalOrMentalStatus.title": "Functional or Mental status",
    "app.HealthInsurances.title": "Insurances",
    "app.HealthProfessional.title": "Health Professional",
    "app.HealthcareProviders.title": "Healthcare Providers",
    "app.HomePage.title": "Home",
    "app.Identifier.header": "Identification Number",
    "app.Identifiers.header": "Identification Number",
    "app.ImagingStudy.accession": "Accession",
    "app.ImagingStudy.header": "Imaging Study",
    "app.Input.customPeriodDateSelector": "Select the time period you want to share",
    "app.InputElement.chooseFromDate": "Choose a from date",
    "app.InputElement.chooseToDate": "Choose a to date",
    "app.InputElement.customPeriodDateSelector": "Or select a custom period",
    "app.InputElement.predefinedPeriodDateMonth": "This Month",
    "app.InputElement.predefinedPeriodDateWeek": "This Week",
    "app.InputElement.predefinedPeriodDateYear": "This Year",
    "app.Intoxications.title": "Intoxications",
    "app.Lifestyle.title": "Lifestyle",
    "app.LinkedSources.ConnectButton": "Connect",
    "app.LinkedSources.FindProvider": "Find your providers",
    "app.LinkedSources.FitnessDescription": "Add fitness apps you already use to automatically update your health data.",
    "app.LinkedSources.FitnessTitle": "Fitness & Wellbeing",
    "app.LinkedSources.MedicalRecordsTitle": "Medical Records",
    "app.LinkedSources.intro": "Bring all your medical records and fitness data together to create your secure universal health record.",
    "app.LinkedSources.linkToAnother": "Link To Another",
    "app.LinkedSources.logs": "Logs",
    "app.LinkedSources.noSourcesText": "Import medical records from over 5000 providers with our partner, Medmij. Find yours and authorise secure access using DigiD.",
    "app.LinkedSources.permissions": "Manage access",
    "app.LinkedSources.reauthMessage": "Unable to refresh. Tap to renew access.",
    "app.LinkedSources.reauthorise": "Reconnect",
    "app.LinkedSources.reauthorizeText": "If you are regularly asked to reconnect, go to Manage Access and enable Ongoing Access for the affected source.",
    "app.LinkedSources.refresh": "Refresh",
    "app.LinkedSources.refreshAll": "Check for new records",
    "app.LinkedSources.refreshText.attention": "Re-authentication required",
    "app.LinkedSources.refreshText.beforeDay": "Refreshed",
    "app.LinkedSources.refreshText.beforeTime": "at",
    "app.LinkedSources.refreshText.missingData": "with potentially missing data",
    "app.LinkedSources.refreshText.paused": "Provider not currently available",
    "app.LinkedSources.refreshText.refreshed": "Refreshed",
    "app.LinkedSources.refreshText.refreshing": "Refreshing",
    "app.LinkedSources.refreshText.warning": "Refreshed with warnings",
    "app.LinkedSources.refreshing": "Refreshing...",
    "app.LinkedSources.remove": "Remove",
    "app.LinkedSources.remove.info": "This will delete the source and its records from your account, not from the source itself.",
    "app.LinkedSources.removeAll": "Remove all",
    "app.LinkedSources.removeDescription": "Remove the link to each connected account and remove any associated records from your digi.me account.",
    "app.LinkedSources.sourceNotAvailable": "This provider is not currently sharing records.",
    "app.LinkedSources.startOver": "Want to start over?",
    "app.LinkedSources.title": "Sources",
    "app.LinkedSources.viewErrors": "View Errors",
    "app.LivingSituation.title": "Living Situation",
    "app.NetworkStatus.appNotWork": "Some parts of the app may not work as expected",
    "app.NetworkStatus.noInternet": "No Internet Connection Detected",
    "app.Notifications.header": "Activity",
    "app.NutritionAdvice.title": "Nutrition Advice",
    "app.NutritionOrderOralDietComponent.Schedule": "Schedule",
    "app.NutritionOrderSupplementComponent.Schedule": "Schedule",
    "app.OnboardOrLogIn": "Onboard or Log in",
    "app.OnboardOrLogIn.description": "The simplest, most private way to take control of your health data.",
    "app.OnboardOrLogIn.logInButton": "Log in",
    "app.OnboardOrLogIn.logInText": "Log in now to see your records.",
    "app.OnboardOrLogIn.logInTitle": "Have a digi.me account?",
    "app.OnboardOrLogIn.moreButton": "or, read our Getting Started guide",
    "app.OnboardOrLogIn.onboardButton": "Create your account",
    "app.OnboardOrLogIn.onboardText": "Find Your Healthcare Provider → DigiD Login → Give Consent → Secure digi.me Account with Email & SMS → Collect Healthcare Data",
    "app.OnboardOrLogIn.onboardTitle": "New to digi.me?",
    "app.OnboardOrLogIn.yourRecords": "Welcome to digi.me",
    "app.Organization.contact.empty": "No contact to show.",
    "app.Organization.contact.header": "Contact",
    "app.Organization.contacts": "Contacts",
    "app.Organization.contacts.empty": "No contacts to show.",
    "app.PatientContacts.title": "Contact Persons",
    "app.PatientDetails.title": "Patient Details",
    "app.PortabilityMenuItem": "Portability Report",
    "app.PortabilityReport.chooseFromDate": "Choose a from date",
    "app.PortabilityReport.chooseToDate": "Choose a to date",
    "app.PortabilityReport.periodMonthText": "This Month",
    "app.PortabilityReport.periodWeekText": "This Week",
    "app.PortabilityReport.periodYearText": "This Year",
    "app.PortabilityReport.selectCustomPeriod": "Or Select A Custom Period",
    "app.PortabilityReport.title": "Portability Report",
    "app.PrivacyPolicy.title": "Privacy Policy",
    "app.ProcedureFocalDevices": "Focal Devices",
    "app.ProcedurePerformers": "Performers",
    "app.ReportBug": "Report A Bug",
    "app.Settings.header": "Account Settings",
    "app.ShareMeasurement.header": "Share your self measurements",
    "app.ShareMeasurements.MonthText": "This Month",
    "app.ShareMeasurements.PreviewHeader": "Here's a preview of the data you will be sharing",
    "app.ShareMeasurements.WeekText": "This Week",
    "app.ShareMeasurements.YearText": "This Year",
    "app.ShareMeasurements.buttonSave": "Or save to your device",
    "app.ShareMeasurements.buttonSaveHelpText": "This will save a .JSON file of your measurements",
    "app.ShareMeasurements.buttonSelect": "Select your healthcare provider",
    "app.ShareMeasurements.buttonSelectHelpText": "You will be asked to select your hospital on the next screen",
    "app.ShareMeasurements.chooseFromDate": "Choose a from data",
    "app.ShareMeasurements.chooseToDate": "Choose a to date",
    "app.ShareMeasurements.description": "Only the measurements you entered yourself will be shared.",
    "app.ShareMeasurements.header": "Share your self measurements",
    "app.ShareMeasurements.select": "Select the time period you want to share",
    "app.ShareMeasurements.selectCustomPeriod": "Or select a custom period",
    "app.ShareProgressModal.failed.text": "Failed to send to",
    "app.ShareProgressModal.failed.tryAgain": "Please try again later",
    "app.ShareProgressModal.sending.text": "Sending to",
    "app.ShareProgressModal.sent.text": "Successfully sent to",
    "app.SourceType.appleHealth": "Apple Health",
    "app.SourceType.fitbit": "Fitbit",
    "app.SourceType.garmin": "Garmin",
    "app.SourceType.googleFit": "Google Fit",
    "app.SourceType.manualEntry": "Manual Entry",
    "app.SourceType.withings": "Withings",
    "app.TermsAndConditions.title": "Terms And Conditions",
    "app.TimingRepeats.header": "Timings Repeat",
    "app.Timings.header": "Timings",
    "app.TobaccoUse.title": "Tobacco Use",
    "app.TryAgain": "Try Again",
    "app.Vaccinations.title": "Vaccinations",
    "app.account.dangerZone.description": "Deleting your account will:\\n\\n* unlink your healthcare providers\\n* delete your self measurements\\n* remove your name, phone number and email from our system.\\n\\nYou can create a new account at any time.",
    "app.account.dangerZone.subtitle": "If you no longer wish to use digi.me to view your medical records you can permanently delete your account",
    "app.account.dangerZoneTitle": "Danger Zone",
    "app.account.displayName": "Display Name",
    "app.account.portabilityDetails": "Exercise your right to data portability by generating your Portability Report. This produces an XML file that you can use to migrate to another PGO.",
    "app.account.portabilityReport": "Generate Report",
    "app.account.portabilityReportTitle": "Data Portability",
    "app.account.portabilitySubTitle": "As one of the select few MedMij certified companies, we ensure private and secure access to your health records.",
    "app.addModal.addData": "Add data",
    "app.addModal.cancel": "Cancel",
    "app.addModal.fitnessTitle": "What fitness service do you want to link?",
    "app.addModal.linkData": "Link data",
    "app.addModal.measurement": "Measurement",
    "app.addModal.measurementTitle": "Add body measurement",
    "app.addModal.reading": "Vital reading",
    "app.addModal.readingTitle": "Add vital reading",
    "app.addModal.records": "Medical records",
    "app.addModal.recordsOptionGP": "GP Record",
    "app.addModal.recordsOptionHospital": "Hospital record",
    "app.addModal.recordsOptionNotSure": "Not sure",
    "app.addModal.recordsOptionVaccine": "Vaccine record",
    "app.addModal.recordsTitle": "What type of medical record do you want to link?",
    "app.addModal.service": "Fitness service",
    "app.addresses.empty": "No addresses to show. ",
    "app.addresses.header": "Addresses",
    "app.administration-agreement.title": "Administration Agreement",
    "app.administrationAgreement.title": "Administration Agreement",
    "app.ago-date.days-ago": "days ago",
    "app.ago-date.today": "today",
    "app.ago-date.yesterday": "yesterday",
    "app.alcohol-use.title": "Alcohol Use",
    "app.allergyIntolerance.title": "Allergy \/ Intolerance",
    "app.allergyIntolerances.title": "Allergy and Intolerances",
    "app.altText.sourceList.attention": "Re-authentication required",
    "app.altText.sourceList.paused": "Provider not currently available",
    "app.altText.sourceList.refreshed": "Refreshed",
    "app.altText.sourceList.refreshing": "Refreshing",
    "app.altText.sourceList.warning": "Refreshed with warnings",
    "app.animation.unlockingAndLoadingYourRecords": "Unlocking and loading your records",
    "app.animation.upToDate": "All records loaded",
    "app.annotations.empty": "No annotations to show.",
    "app.annotations.header": "Annotations",
    "app.appointment.title": "Appointment",
    "app.appointments.title": "Appointments",
    "app.ariaLabel.menu.add": "Add",
    "app.ariaLabel.menu.help": "Help",
    "app.ariaLabel.menu.sources": "Options",
    "app.bloodPressure.title": "Blood Pressure",
    "app.bodyHeight.title": "Body Height",
    "app.bodyTemperature.title": "Body Temperature",
    "app.bodyWeight.title": "Body Weight",
    "app.btn.delete.measurement": "Delete this measurement",
    "app.btn.delete.measurement.cancel": "Cancel",
    "app.btn.delete.measurement.confirm": "Delete",
    "app.btn.delete.measurement.deleting": "Deleting...",
    "app.button.addMeasurement": "Add measurement",
    "app.button.cancel": "Cancel",
    "app.button.changeEmail": "Edit",
    "app.button.changeNotifications": "Opt Out",
    "app.button.changePassword": "Edit",
    "app.button.changePhone": "Edit",
    "app.button.close": "Close",
    "app.button.deleteAccount": "Delete Account",
    "app.button.dismiss": "Dismiss",
    "app.button.help": "Help Centre",
    "app.button.ok": "Ok",
    "app.button.privacyPolicy": "Privacy Policy",
    "app.button.reportIssue": "Report an issue",
    "app.button.termsOfUse": "Terms of Use",
    "app.clearingAllData": "Clearing all imported encrypted data...",
    "app.column.advanceDirective": "Advance Directive",
    "app.column.date": "Date",
    "app.column.heart": "Heart",
    "app.column.pulse": "Pulse",
    "app.column.rate": "Rate",
    "app.column.role": "Role",
    "app.column.treatmentDirective": "Treatment Directive",
    "app.column.type": "Type",
    "app.composition-relates-to-target-identifier-header": "Relates To Identifier",
    "app.composition.title": "Composition",
    "app.condition.evidences.empty": "No evidences to show.",
    "app.condition.evidences.header": "Evidences",
    "app.condition.stages.empty": "No stages to show.",
    "app.condition.stages.header": "Stages",
    "app.consent-actor.empty": "No actors to show. ",
    "app.consent-actor.header": "Actors",
    "app.consent-data.empty": "No data to show. ",
    "app.consent-data.header": "Data",
    "app.consent-except.empty": "No except to show",
    "app.consent-except.header": "Excepts",
    "app.consent-policy.empty": "No policies to show. ",
    "app.consent-policy.header": "Policies",
    "app.consent.title": "Consent",
    "app.consentActors.header": "Actors",
    "app.consentData.header": "Consent Data",
    "app.consentExcepts.header": "Excepts",
    "app.consentPolicies.header": "Policies",
    "app.consents.title": "Consents given",
    "app.contactPoints.empty": "No contact details to show.",
    "app.contactPoints.header": "Contact Information",
    "app.coverage.organization": "Organization",
    "app.delete-account.cancel": "Cancel",
    "app.delete-account.close": "Close",
    "app.delete-account.delete": "Delete my account",
    "app.delete-account.deleted": "Your account has been deleted.",
    "app.delete-account.deleting": "Deleting ...",
    "app.delete-account.header": "Delete you digi.me account?",
    "app.delete-account.warning": "This will remove any imported records, remove links to your sources, and delete your digi.me account.",
    "app.details": "Details",
    "app.details.close": "Close",
    "app.details.empty": " No details to show. ",
    "app.details.showDetails": "Show details",
    "app.device.title": "Device",
    "app.devices.title": "Medical Devices",
    "app.diagnose.title": "Diagnose",
    "app.diagnoses.title": "Diagnoses",
    "app.diagnosis.title": "Diagnosis",
    "app.diagnostic-report.performers.header": "Performers",
    "app.diagnosticReport.title": "Diagnostic Report",
    "app.dialog.biometrics.btn.no": "Keep logging in manually",
    "app.dialog.biometrics.btn.yes": "Enable biometrics",
    "app.dialog.biometrics.description": "Instantly log in using your face or fingerprint. Biometrics are faster, safer, and more convenient than entering your email, password, and waiting for a verification code every time.",
    "app.dialog.biometrics.title": "Enable Quick & Secure Login",
    "app.dialog.demoError.btn": "OK",
    "app.dialog.demoError.title": "Not available in demo mode",
    "app.dialog.portabilityReportFail.altText": "Download icon",
    "app.dialog.portabilityReportFail.btn": "OK",
    "app.dialog.portabilityReportFail.description": "This is likely because you haven’t linked this app to a healthcare provider yet.",
    "app.dialog.portabilityReportFail.title": "No report available",
    "app.digiMeError.code": "Code",
    "app.digiMeError.error": "Error",
    "app.disclaimer.medical": "Digi.me is not a substitute for professional medical advice. Always consult your physician first.",
    "app.dosage.empty": "No dosages to show.",
    "app.dosage.header": "Dosage",
    "app.drug-use.title": "Drug Use",
    "app.encounter.title": "Encounter",
    "app.encounters.title": "Encounters",
    "app.episode-of-care.title": "Episode Of Care",
    "app.episodeOfCare.diagnoses.empty": "No diagnoses to show.",
    "app.episodeOfCare.diagnoses.header": "Diagnoses",
    "app.episodeOfCare.status-histories.empty": "No history statuses to show.",
    "app.episodeOfCare.statuses-history.header": "Statuses History",
    "app.episodesOfCare.title": "Episodes",
    "app.error.error": "Error",
    "app.error.errorCode": "Error code: ",
    "app.error.reference": "Reference: ",
    "app.error.serverCode": "Server code: ",
    "app.faq": "FAQ",
    "app.feedback.widget.banner": "Contact Us",
    "app.feedback.widget.confirmation": "Thank you. We will be in touch soon.",
    "app.feedback.widget.languagecode": "en",
    "app.feedback.widget.launcher": "Help",
    "app.feedback.widget.reference": "Ref",
    "app.feedback.widget.reportbug": "Bug Report",
    "app.feedback.widget.reporterror": "Error Report",
    "app.feedback.widget.requestProviderDescription": "Please add the name of your Healthcare Provider here:",
    "app.feedback.widget.requestProviderSubject": "New Healthcare Provider Request",
    "app.feedback.widget.submit": "Send",
    "app.footer.copyright": "digi.me is a product of",
    "app.footer.privacyPolicy": "Privacy Policy",
    "app.footer.termsOfUse": "Terms of Use",
    "app.functionalOrMentalStatus.title": "Functional or Mental status",
    "app.glucose.title": "Glucose",
    "app.grid.empty": "No Rows To Show",
    "app.grid.error": "ERR",
    "app.grid.loading": "Loading...",
    "app.hamburger.wdx": "World Data Exchange B.V.",
    "app.hamburgerMenu.clearImportedRecords": "Clear imported records",
    "app.hamburgerMenu.dangerZone": "Danger Zone",
    "app.hamburgerMenu.deleteAccount": "Delete your account",
    "app.hamburgerMenu.logOut": "Log out",
    "app.healthCareProvider.title": "Health Care Provider",
    "app.healthCareProviders.title": "Health Care Providers",
    "app.healthInsurance.title": "Health Insurance",
    "app.healthInsurances.title": "Health Insurances",
    "app.healthProfessional.title": "Health Professional",
    "app.healthcare-provider.title": "Healthcare Provider",
    "app.healthcare-services.empty": "No healthcare services to show.",
    "app.healthcare-services.header": "Healthcare Services",
    "app.heartAndPulseRate.title": "Heart and Pulse Rates",
    "app.help": "Help",
    "app.helper.button.addFirstRecords": "Add medical records",
    "app.helper.button.addMeasurement": "Add a self measurement",
    "app.helper.button.addRecords": "Add more records",
    "app.helper.button.back": "Back",
    "app.helper.description.ComingVerySoon": "Coming very soon!",
    "app.helper.description.addMeasurement": "Has your GP asked you to keep a record of your weight, heart rate, or blood pressure? You can share your self measurements directly and privately with your GP too.",
    "app.helper.description.addRecords": "Through our partnership with Medmij, you can import your medical records from over 5,000 healthcare providers in the Netherlands. You can add as many providers as you need to create your universal health record.",
    "app.helper.nothingToDisplay": "Nothing to display",
    "app.home.button.refresh": "Check for updates",
    "app.home.hello": "Hello",
    "app.home.lastChecked": "You last checked for new records from your healthcare providers",
    "app.home.lastCheckedDaysAgo": "days ago.",
    "app.home.lastCheckedToday": "today.",
    "app.home.lastCheckedYesterday": "yesterday.",
    "app.humanNames.empty": "No names to show.",
    "app.humanNames.header": "Names",
    "app.identifiers.empty": "No identifiers to show.",
    "app.imagingStudy.title": "Imaging Study",
    "app.imagings.title": "Imaging",
    "app.immunization-reaction.empty": "No reactions to show.",
    "app.immunization-reaction.header": "Reaction",
    "app.immunization-recommendation-protocol.header": "Protocol",
    "app.immunization-recommendation-recommendation-date-criteria.empty": "No criteria to show.",
    "app.immunization-recommendation-recommendation-date-criteria.header": "Date criteria",
    "app.immunization-recommendation-recommendation-date-criterion.header": "Date Criterion",
    "app.immunization-recommendation-recommendation.empty": "No recommendations to show.",
    "app.immunization-recommendation-recommendation.header": "Recommendation",
    "app.immunization-recommendation-recommendations.header": "Recommendations",
    "app.immunization-recommendation.title": "Immunization Recommendation",
    "app.immunization.explanation.empty": "No explanation to show. ",
    "app.immunization.practioners.header": "Practitioners",
    "app.immunization.practitioners.empty": "No practitioners to show.",
    "app.immunization.title": "Immunization",
    "app.immunization.vaccinationProtocol.empty": "No protocols to show.",
    "app.immunization.vaccinationProtocol.header": "Protocol",
    "app.immunization.vaccinationProtocols.header": "Protocols followed",
    "app.info.missing.header": "Some data may be not have been imported",
    "app.info.missing.moreLink": "More info",
    "app.info.missing.partialDetails": "We were unable to completely import your records from ",
    "app.info.missing.partialHeader": "Partial Sync",
    "app.info.missing.statement": "We continue to request all records with every sync so these may appear at a later time.",
    "app.info.missing.unsupportedDetails": " does not currently provide access to ",
    "app.info.missing.unsupportedHeader": "Unsupported Record Type",
    "app.input.additionalComment": "Additional Comments",
    "app.input.blood-glucose": "Blood Glucose (mmol\/L)",
    "app.input.blood-glucose.code": "Code",
    "app.input.blood-glucose.timing": "Timing",
    "app.input.blood-oxygen": "Blood Oxygen (%)",
    "app.input.blood-oxygen.administered-oxygen": "Administered Oxygen",
    "app.input.blood-pressure.cuff-size": "Cuff size",
    "app.input.blood-pressure.diastolic": "Diastolic (mmHg)",
    "app.input.blood-pressure.location": "Location",
    "app.input.blood-pressure.method": "Method",
    "app.input.blood-pressure.position": "Position",
    "app.input.blood-pressure.systolic": "Systolic (mmHg)",
    "app.input.clothing": "Clothing",
    "app.input.date": "Date",
    "app.input.heartRate": "Heart Rate (BPM)",
    "app.input.height": "Height",
    "app.input.height.lying": "Lying",
    "app.input.height.position": "Position",
    "app.input.height.standing": "Standing",
    "app.input.method": "Method",
    "app.input.optional": "(Optional)",
    "app.input.optional-additional-information": "Optional additional information",
    "app.input.respiration-rate.administered-oxygen": "Administered Oxygen",
    "app.input.respiration-rate.breaths-per-minute": "Breaths per minute",
    "app.input.respiration-rate.depth": "Depth",
    "app.input.respiration-rate.flow-rate": "Flow Rate (l\/min)",
    "app.input.respiration-rate.inspired-oxygen": "Inspired Oxygen",
    "app.input.respiration-rate.pattern": "Pattern",
    "app.input.respiration-rate.rhythm": "Rhythm",
    "app.input.select": "- select -",
    "app.input.temperature": "Temperature (°C)",
    "app.input.time": "Time",
    "app.input.unit": "Unit",
    "app.input.weight": "Weight (kg)",
    "app.ips.button": "View your Patient Summary",
    "app.ips.componentHeader.allergies": "Allergies and Intolerances",
    "app.ips.componentHeader.contacts": "Contacts",
    "app.ips.componentHeader.diagnoses": "Diagnoses",
    "app.ips.componentHeader.medications": "Medications",
    "app.ips.componentHeader.patientDetails": "Patient Details",
    "app.ips.componentHeader.procedures": "Procedures",
    "app.ips.componentHeader.results": "Results",
    "app.ips.componentHeader.summaryDetails": "Summary Details",
    "app.ips.componentHeader.vaccines": "Vaccines",
    "app.ips.description": "Your Patient Summary gives healthcare professionals a quick and reliable snapshot of your medical history, covering key details like emergency contacts, allergies, medications, vaccines, and more. All in one place, so you can receive the right care when you need it.",
    "app.ips.generating": "Creating your patient summary using these sources",
    "app.ips.pdf.count": "of",
    "app.ips.pdf.disclaimer": "The information in this document consists of your sensitive health and other personal information. You should ensure that you preserve its confidentiality and privacy and store it safely and securely.",
    "app.ips.pdf.title": "Patient Summary",
    "app.ips.pdf.wdx": "World Data Exchange B.V.",
    "app.ips.results.disclaimer": "Numerical results that have a reference range and are within it are not shown in the summary below.",
    "app.ips.summaryDetails.asOf": "As of",
    "app.ips.summaryDetails.generatedDate": "Generated",
    "app.ips.summaryDetails.sourceIntro": "Using records from these sources",
    "app.journal.title": "Journal",
    "app.laboratory.title": "Laboratory",
    "app.language.dutch": "Dutch",
    "app.language.english": " English ",
    "app.language.settings": "App Language",
    "app.linked-source.refreshing": "Refreshing...",
    "app.linked-sources.menu.button.manageAccess": "Manage access",
    "app.linked-sources.menu.button.remove": "Remove",
    "app.linked-sources.menu.removeDescription": "This will delete the source and its records from your account, not from the source itself.",
    "app.living-situation.title": "Living Situation",
    "app.loading": "Loading...",
    "app.logout": "Logout",
    "app.logout.message": "You have been logged out for your privacy and security.",
    "app.measurement.title": "Measurement",
    "app.medical-aid.title": "Medical Aid",
    "app.medical-aids.title": "Medical Aids",
    "app.medicalServices.empty": "No Rows To Show",
    "app.medicalServices.error": "ERR",
    "app.medicalServices.loading": "Loading...",
    "app.medication-agreement.title": "Medication Agreement",
    "app.medication-agreements.title": "Medication Agreement",
    "app.medication-dispense.title": "Medication Dispense",
    "app.medication-overview.administration-agreement": "Administration Agreement",
    "app.medication-overview.agreements": "Agreements",
    "app.medication-overview.title": "Medication",
    "app.medication-overview.use": "Use",
    "app.medication-request-dosage-header": "Dosage Instructions",
    "app.medication-request-dosage-subheader": "Instruction",
    "app.medication-request.title": "Medication Request",
    "app.medication-use.title": "Use",
    "app.medication.title": "Medication",
    "app.medicationStatement.title": "Medication Use",
    "app.medicationUse.title": "Medication Use",
    "app.menu.Appointments": "Appointments",
    "app.menu.Consent": "Consent",
    "app.menu.FunctionalOrMentalStatus": "Functional Or Mental status",
    "app.menu.HealthProfessional": "Health Professionals",
    "app.menu.HealthcareProviders": "Healthcare Providers",
    "app.menu.Insurance": "Health Insurance",
    "app.menu.LivingSituation": "Living Situation",
    "app.menu.Procedures": "Procedures",
    "app.menu.allergyIntolerances": "Allergies \/ Intolerances",
    "app.menu.bloodPressure": "Blood pressure",
    "app.menu.bodyHeight": "Body Height",
    "app.menu.bodyTemperature": "Body Temperature",
    "app.menu.bodyWeight": "Body Weight",
    "app.menu.contactPersons": "Contact Persons",
    "app.menu.details": "Details",
    "app.menu.diagnoses": "Diagnoses",
    "app.menu.documents": "Documents",
    "app.menu.encounters": "Encounters",
    "app.menu.episodeOfCare": "Episodes",
    "app.menu.flags": "Flags",
    "app.menu.glucose": "Glucose",
    "app.menu.gp-records": "GP Records",
    "app.menu.healthCareProvider": "Healthcare Providers",
    "app.menu.healthRecord": "Records",
    "app.menu.heartAndPulseRates": "Heart and Pulse Rates",
    "app.menu.intoxications": "Intoxications",
    "app.menu.intoxications.alcoholUse": "Alcohol",
    "app.menu.intoxications.drugUse": "Drugs",
    "app.menu.intoxications.tobaccoUse": "Tobacco",
    "app.menu.ips": "Summary",
    "app.menu.journal": "Journal",
    "app.menu.laboratory": "Laboratory",
    "app.menu.lifestyle": "Lifestyle",
    "app.menu.linkedSources": "Sources",
    "app.menu.measurement": "Measurements",
    "app.menu.medicalAids": "Medical Aids",
    "app.menu.medicalDevices": "Medical Devices",
    "app.menu.medicalServices": "Medical Services",
    "app.menu.medication": "Medication",
    "app.menu.myDetails": "Details",
    "app.menu.myHealthRecord": "My Health Record",
    "app.menu.myMedicalRecord.results.imaging": "Imaging",
    "app.menu.nutritionAdvice": "Nutrition Advice",
    "app.menu.o2Saturation": "O2 Saturation",
    "app.menu.optInResearch": "Opt In Research",
    "app.menu.pathways": "Pathways",
    "app.menu.patientDetails": "Patient Details",
    "app.menu.records": "Records",
    "app.menu.respiration": "Respiration",
    "app.menu.results": "Results",
    "app.menu.sources": "Sources",
    "app.menu.vaccinations": "Vaccinations",
    "app.menu.wellbeing": "Lifestyle",
    "app.modal.button.cancel": "Cancel",
    "app.nutrition-order.title": "Nutrition Order",
    "app.nutritionAdvice.title": "Nutrition Advice",
    "app.o2Saturation.title": "O2 Saturation",
    "app.observation.components.empty": "No components to show.",
    "app.observation.components.header": "Components",
    "app.observation.referenceRanges.empty": "No reference ranges to show.",
    "app.observation.referenceRanges.header": "Reference ranges",
    "app.onboard.button": "Find your healthcare provider",
    "app.onboard.description": "See your medical records by linking to your healthcare providers. Select from thousands of GPs and Hospitals.",
    "app.onboard.description.strong": "You can link to as many medical records as you need.",
    "app.onboard.title": "Hello",
    "app.page.page-not-found.title": "PAGE NOT FOUND",
    "app.patient-contact.title": "Contact Person",
    "app.patient.title": "Patient",
    "app.patientContact.title": "Contact Persons",
    "app.patientDetail.title": "Patient Details",
    "app.paywall.agreement": "Auto-renews, cancel at any time.",
    "app.paywall.btn.continue": "Continue",
    "app.paywall.btn.help": "Help",
    "app.paywall.btn.legals": "Legals",
    "app.paywall.btn.redeemCode": "Redeem code",
    "app.paywall.btn.restore": "Restore",
    "app.paywall.feature1": "Export your Patient Summary as a PDF as often as you like.",
    "app.paywall.feature2": "Share your Patient Summary via email or text message.",
    "app.paywall.restoreError": "Unable to find active subscription",
    "app.paywall.saving": "Save 30%",
    "app.paywall.success": "Subscribed",
    "app.paywall.title": "Unlock PDF",
    "app.pdfExport.name": "Patient Summary for {$patientName} - {$DDMMMYYYY} at {$HHMM}.pdf",
    "app.pdfExport.noName": "Patient Summary - {$DDMMMYYYY} at {$HHMM}.pdf",
    "app.practitioner-qualifications.empty": "No qualifications to show.",
    "app.practitioner.qualifications.header": "Qualifications",
    "app.procedure.title": "Procedure",
    "app.procedures.title": "Medical Procedures",
    "app.promo.ips.description": "Access care worldwide with confidence. Your International Patient Summary is a trusted document that healthcare providers anywhere can use to understand your medical needs, ensuring you receive the best care, no matter where you are.",
    "app.promo.ips.more": "Coming soon.",
    "app.promo.ips.title": "International Patient Summary",
    "app.promo.mobile.hover.android": "Tap to open in the Google Play Store or scan from your phone.",
    "app.promo.mobile.hover.ios": "Tap to open the Apple App Store or scan from your phone.",
    "app.promo.mobile.text": "Get digi.me on your phone and access all your health records anywhere. Secure biometric login means your info is just a tap away, whether at the doctor’s or on the go.",
    "app.promo.mobile.title": "Always have your records with you!",
    "app.promo.optInResearch.description": "You’ll soon have the option to participate in research programs while ensuring your privacy using our Opt In and Opt Out services. Choose when and how your data contributes to medical advancements, all while retaining full control.",
    "app.promo.optInResearch.more": "Stay turned for more details!",
    "app.promo.optInResearch.title": "Opt In Research",
    "app.promo.pathways.description": "With the clinical pathways module you have a good insight into a specific condition and you can also perform certain tasks for your healthcare provider yourself, such as completing a questionnaire or keeping track of your blood pressure.",
    "app.promo.pathways.more": "Coming soon.",
    "app.promo.pathways.title": "Pathways Support",
    "app.promo.wellbeing.description": "Track key health indicators and give you good insight to stay healthy. This module helps you prevent potential health problems and gives you control over your lifestyle.",
    "app.promo.wellbeing.more": "Coming soon.",
    "app.promo.wellbeing.title": "Lifestyle Monitoring",
    "app.ptReview.btn": "Leave a review",
    "app.ptReview.description": "Sharing your experience of digi.me will help others make an informed choice about which PGO they should use. It should take no more than 5 minutes.",
    "app.ptReview.title": "Please leave us a review!",
    "app.ptReviewThanks.description": "It can take a few days before your review is published.",
    "app.ptReviewThanks.title": "Thank you",
    "app.reauthorize.button": "Refresh my data",
    "app.reset": "Clear imported data",
    "app.reset.cancel": "No, cancel",
    "app.reset.clear": "Yes, delete",
    "app.reset.header": "Delete all imported data from digi.me?",
    "app.reset.warning": "This will remove all medical records from your digi.me account. The records will not be deleted from your healthcare providers, but may not be available for you to re-import at a later date.",
    "app.respiratoryRate.title": "Respiratory Rates",
    "app.return.errorDescription": "An unexpected error occurred.",
    "app.securityPrompt.btn.delete": "Delete Account",
    "app.securityPrompt.btn.logout": "Log Out",
    "app.securityPrompt.btn.unlock": "Unlock Health Vault",
    "app.securityPrompt.noaccess.description": "For your privacy, we automatically delete inactive health vaults. Since your digi.me vault hasn’t been used for an extended time, it has been removed.\\n\\nTo re-import your medical records, delete this account, then create a new one to set up a new health vault.",
    "app.securityPrompt.noaccess.title": "Unable to access your health vault",
    "app.securityPrompt.unlock.description": "After a period of inactivity your health vault requires that you manually unlock it. To do this you may need to log in to one of your previously linked sources. This is to protect your imported medical records.",
    "app.securityPrompt.unlock.title": "Unlock your Health Vault",
    "app.self-measurements.modal.button.addMeasurement": "Add measurement",
    "app.self-measurements.modal.title": "What measurement would you like to record?",
    "app.self-measurements.modal.title.addBloodGlucose": "Add Blood Glucose",
    "app.self-measurements.modal.title.addBloodOxygen": "Add Blood Oxygen",
    "app.self-measurements.modal.title.addBloodPressure": "Add Blood Pressure",
    "app.self-measurements.modal.title.addBodyHeight": "Add Body Height",
    "app.self-measurements.modal.title.addBodyTemperature": "Add Body Temperature",
    "app.self-measurements.modal.title.addBodyWeight": "Add Body Weight",
    "app.self-measurements.modal.title.addHeartRate": "Add Heart Rate",
    "app.self-measurements.modal.title.addRespirationRate": "Add Respiration Rate",
    "app.selfMeasurement.title": "Add",
    "app.settings": "Settings",
    "app.settings.email": "Email Address",
    "app.settings.emailNotifications": "Email Notifications",
    "app.settings.emailNotificationsDescription": "Includes reminders to check for new records or complete medical surveys and product announcements.",
    "app.settings.emailNotificationsToggleAllow": "Allow",
    "app.settings.emailNotificationsToggleDontAllow": "Don't Allow",
    "app.settings.password": "Password",
    "app.settings.phone": "Phone Number",
    "app.settings.subscription.detail": "You subscribed through the {{store}} on {{subscribed.date}}",
    "app.settings.subscription.manage": "Manage",
    "app.settings.subscription.title": "Subscription",
    "app.share": "Share",
    "app.signinSuccess.button": "Create your account",
    "app.signinSuccess.cancel": "Or, cancel and remove imported records",
    "app.signinSuccess.explainer": "Complete your account setup with multi-factor authentication to access your medical records. Don't worry—if you can't finish now, any imported data will be deleted for your privacy and won't affect your hospital records.",
    "app.signinSuccess.heading": "You've successfully imported your medical records. Final Step: Create Your Account",
    "app.signup.consent.buttonCancel": "Cancel",
    "app.signup.consent.buttonSkip": "Create an account first",
    "app.signup.consent.buttonStart": "Link your first healthcare provider",
    "app.signup.consentDetails": "1. With a PGO, any Dutch resident (aged 16 and older) who wishes to can collect, manage, and share health data in one place.\\n2. MedMij rules apply to the sign-up process, and all MedMij participants must adhere to these rules.\\n3. You will first be asked to select your healthcare provider (General Practitioner or Hospital) to retrieve your medical record.\\n4. Next, you will be asked to identify yourself with your healthcare provider using DigiD.\\n5. Once your identity is confirmed, your medical data will be retrieved from your healthcare provider and stored in your own digital encrypted data vault.\\n6. Then Digi.me will ask you to complete the PGO registration process by requesting your email address and verifying it using two-factor authentication.\\n7. Once this is done, your selected medical data will be available in your own digi.me digital medical vault.\\n\\nIf you need any further information please visit our [Help Centre](https:\/\/digi.me\/help) or our [Getting Started Guide](https:\/\/digi.me\/how).\\n\\nBy continuing, you agree that you have read, understood and are bound by our [Terms of Use](https:\/\/digi.me\/legal\/terms) and our [Privacy Policy](https:\/\/digi.me\/legal\/privacy).",
    "app.signup.consentHelp": "If you need any further information please visit our <a href=\"https:\/\/digi.me\/help\">Help Centre<\/a> or our <a href=\"https:\/\/digi.me\/how\">Getting Started Guide<\/a>.",
    "app.signup.consentPolicies": "By continuing, you agree that you have read, understood and are bound by our <a href=\"https:\/\/digi.me\/legal\/terms\">Terms and Conditions<\/a> and our <a href=\"https:\/\/digi.me\/legal\/privacy\">Privacy Policy<\/a>.",
    "app.signup.consentSkipDescription": "Alternatively, you can create an account now and choose to link healthcare providers or add self measurements later.",
    "app.signup.consentTitle": "Confirm you understand and agree with the following before you begin.",
    "app.singninSuccess.cancel": "Or, cancel and remove imported records",
    "app.source-and-timestamp": "Information Source:",
    "app.source-and-timestamp.selfMeasurement": "Self Measurement",
    "app.sources.sync.warning": "with warnings.",
    "app.specimen-accession-identifier-header": "Accession Identifier",
    "app.specimen.containers.empty": "No containers details to show.",
    "app.specimen.containers.header": "Containers",
    "app.specimen.processing.empty": "No processing details to show.",
    "app.specimen.processing.header": "Processing Details",
    "app.specimen.title": "Specimen",
    "app.summary": "Summary",
    "app.summary.noRecords": "No records.",
    "app.table.header.source": "Source",
    "app.termsAndConditions": "Terms And Conditions",
    "app.title": "digi.me",
    "app.toast.uploadFailed": "Some files failed to upload.",
    "app.toast.uploadSuccess": "Your height and weight measurements from Apple Health data has been added.",
    "app.tobacco-use.title": "Tobacco Use",
    "app.toggle.hideAll": "Hide all",
    "app.toggle.showAll": "Show all",
    "app.trustBanner.text": "As one of the select few MedMij certified companies, we ensure private and secure access to your health records.",
    "app.trustBanner.title": "Trusted & Certified",
    "app.update.forced.button": "Update now",
    "app.update.forced.description": "This version is no longer supported.",
    "app.update.forced.title": "Please update now.",
    "app.update.warning.link": "Update now ",
    "app.update.warning.text": "to keep using digi.me",
    "app.vaccination-recommendations.title": "Vaccination Recommendations",
    "app.vaccinations.title": "Vaccinations",
    "azureadb2c.button.cancel": "Cancel",
    "azureadb2c.button.change.email": "Change Email",
    "azureadb2c.button.login": "Log in",
    "azureadb2c.button.mfa.call": "Call Me",
    "azureadb2c.button.mfa.send": "Send Code",
    "azureadb2c.button.password.create": "Create",
    "azureadb2c.button.verify.code": "Verify Code",
    "azureadb2c.button.verify.email": "Send verification code",
    "azureadb2c.button.verify.mfa": "Verify Code",
    "azureadb2c.button.verify.new": "Send new code",
    "azureadb2c.error.email": "Email Address is required.",
    "azureadb2c.error.mfa": "The verification code you have entered does not match our records. Please try again, or request a new code.",
    "azureadb2c.error.user.exists": "That email address is registered to an existing digi.me account. Please use a different one.",
    "azureadb2c.footer.copyright": "© 2025",
    "azureadb2c.footer.privacy": "Privacy Policy",
    "azureadb2c.footer.terms": "Terms of Use",
    "azureadb2c.help.wait": "Please wait while we process your information.",
    "azureadb2c.instruction.verified": "Email address verified. You can now continue.",
    "azureadb2c.instruction.verify": "Verification code has been sent to your inbox. Please copy it to the input box below.",
    "azureadb2c.label.email": "Email Address",
    "azureadb2c.label.mfa.code": "Country Code",
    "azureadb2c.label.mfa.phone": "Phone Number",
    "azureadb2c.label.mfa.verify": "Enter your verification code below, or ",
    "azureadb2c.label.password.confirm": "Confirm your new password",
    "azureadb2c.label.password.new": "Create your password. It must contain any 3 of the following: uppercase, lowercase, numbers, symbols.",
    "azureadb2c.label.verify": "Verification Code",
    "azureadb2c.link.mfa.new": "send a new code",
    "azureadb2c.login.email": "Email Address",
    "azureadb2c.login.error.email": "Please enter your Email Address",
    "azureadb2c.login.error.password": "Please enter your password",
    "azureadb2c.login.forgot": "Forgot your password?",
    "azureadb2c.login.headline": "Log In",
    "azureadb2c.login.headline.mfa": "Multi-factor authentication",
    "azureadb2c.login.link.keep": "Keep me logged in",
    "azureadb2c.login.link.signup": "Sign up now",
    "azureadb2c.login.mfa.subtitle": "We have the following number on record for you. We can send a code via SMS or phone to authenticate you.",
    "azureadb2c.login.no-account.label": "Don’t have an account? ",
    "azureadb2c.login.password": "Password",
    "azureadb2c.login.subtitle": "Log in with your email address",
    "azureadb2c.marketing.headline": "Store your medical records in your personal data vault, giving you maximum privacy and security.",
    "azureadb2c.marketing.list1": "🔒 Secure Access: Only you can unlock your data.",
    "azureadb2c.marketing.list2": "🛡️ Bank-Grade Safety: Multi-factor security keeps it safe.",
    "azureadb2c.marketing.list3": "🌎 Access Anywhere: Your data, when you need it.",
    "azureadb2c.marketing.list4": "🌟 Powered by World Data Exchange: Trust in every click.",
    "azureadb2c.needHelp": "Need help?",
    "azureadb2c.placeholder.email": "Email Address",
    "azureadb2c.placeholder.password.confirm": "Confirm your new password",
    "azureadb2c.placeholder.password.new": "New Password",
    "azureadb2c.placeholder.phone": "Phone Number",
    "azureadb2c.placeholder.verify": "Verification Code",
    "azureadb2c.signup.acceptToCAndPrivacyPolicy": "I confirm that I have read and understand the Terms of Use and Privacy Policy",
    "azureadb2c.signup.emailNotifications": "Opt-in to receive reminders to check for new medical records",
    "azureadb2c.signup.headline": "Create your account",
    "azureadb2c.signup.headline.mfa": "Multi-factor authentication",
    "azureadb2c.signup.subtitle": "Before accessing your medical records, create an account by providing your email, mobile number, name, and password. By signing up, you consent to us processing this personal data.",
    "azureadb2c.signup.subtitle.mfa": "Enter a number below that we can send a code via SMS or phone to authenticate you.",
    "diagnostic-report-performers.empty": "No performers to show.",
    "docs.binary.error.modalBody": "The document may not have been imported, or is no longer attached to your medical record. You can try to refresh all your sources to see if that fixes this issue, or contact your healthcare provider directly.",
    "docs.binary.error.modalBtnPrimary": "Ok",
    "docs.binary.error.modalBtnSecondary": "Go to Sources",
    "docs.binary.error.modalTitle": "Unable to display this document",
    "fapp.AllergyIntolerances.title": "Allergies and Intolerances",
    "fhir.Address": "Address",
    "fhir.Address.city": "City",
    "fhir.Address.country": "Country",
    "fhir.Address.district": "District",
    "fhir.Address.empty": "No address to show.",
    "fhir.Address.houseNumber": "House Number",
    "fhir.Address.line": "Address",
    "fhir.Address.line.iso21090-ADXP-houseNumber": "Housenumber",
    "fhir.Address.line.iso21090-ADXP-streetName": "Street",
    "fhir.Address.postalCode": "Postcode",
    "fhir.Address.state": "State",
    "fhir.Address.street": "Street",
    "fhir.Address.type": "Address Type",
    "fhir.Address.use": "Address Use",
    "fhir.Alert": "Alert",
    "fhir.Alert.author": "Author",
    "fhir.Alert.category": "Category",
    "fhir.Alert.code": "Code",
    "fhir.Alert.detail": "Detail",
    "fhir.Alert.encounter": "Encounter",
    "fhir.Alert.period": "Period",
    "fhir.Alert.priority": "Priority",
    "fhir.Alert.status": "Status",
    "fhir.Alert.subject": "Subject",
    "fhir.Alerts": "Alerts",
    "fhir.AllergyIntolerance.asserted-date": "Asserted Date",
    "fhir.AllergyIntolerance.assertedDate": "Asserted Date",
    "fhir.AllergyIntolerance.asserter": "Asserter",
    "fhir.AllergyIntolerance.category": "Allergy Category",
    "fhir.AllergyIntolerance.clinical-status": "Allergy Status",
    "fhir.AllergyIntolerance.clinicalStatus": "Allergy Status",
    "fhir.AllergyIntolerance.code": "Causative Agent",
    "fhir.AllergyIntolerance.criticality": "Criticality",
    "fhir.AllergyIntolerance.encounter": "Encounter",
    "fhir.AllergyIntolerance.last-occurrence": "Last Occurrence",
    "fhir.AllergyIntolerance.lastOccurrence": "Last Occurrence",
    "fhir.AllergyIntolerance.onset": "Onset",
    "fhir.AllergyIntolerance.patient": "Patient",
    "fhir.AllergyIntolerance.reaction": "Reaction",
    "fhir.AllergyIntolerance.reaction.description": "Description",
    "fhir.AllergyIntolerance.reaction.exposure-route": "Exposure Route",
    "fhir.AllergyIntolerance.reaction.exposureRoute": "Exposure Route",
    "fhir.AllergyIntolerance.reaction.manifestation": "Manifestation",
    "fhir.AllergyIntolerance.reaction.onset": "Onset",
    "fhir.AllergyIntolerance.reaction.severity": "Severity",
    "fhir.AllergyIntolerance.reaction.substance": "Substance",
    "fhir.AllergyIntolerance.recorded-date": "Recorded Date",
    "fhir.AllergyIntolerance.recordedDate": "Recorded Date",
    "fhir.AllergyIntolerance.recorder": "Recorder",
    "fhir.AllergyIntolerance.substance": "Causative Agent",
    "fhir.AllergyIntolerance.type": "Type",
    "fhir.AllergyIntolerance.verification-status": "Verification Status",
    "fhir.AllergyIntolerance.verificationStatus": "Verification Status",
    "fhir.Annotation": "Note",
    "fhir.Annotation.AuthorReference": "Author Reference",
    "fhir.Annotation.author": "Author",
    "fhir.Annotation.authorReference": "Author Reference",
    "fhir.Annotation.text": "Text",
    "fhir.Annotation.time": "Time",
    "fhir.Appointment.OnlineEditable": "Online Editable",
    "fhir.Appointment.OnlineEditable.indicator": "Indicator",
    "fhir.Appointment.OnlineEditable.onlineEditableUntil": "Online Editable Until",
    "fhir.Appointment.appointmentType": "Appointment Type",
    "fhir.Appointment.comment": "Comment",
    "fhir.Appointment.created": "Created",
    "fhir.Appointment.description": "Description",
    "fhir.Appointment.end": "End",
    "fhir.Appointment.identifier": "Identifier",
    "fhir.Appointment.incomingReferral": "Incoming Referral",
    "fhir.Appointment.indication": "Indication",
    "fhir.Appointment.minutesDuration": "Minutes Duration",
    "fhir.Appointment.orderStatus": "Order Status",
    "fhir.Appointment.patientInstructions": "Patient Instructions",
    "fhir.Appointment.priority": "Priority",
    "fhir.Appointment.reason": "Reason",
    "fhir.Appointment.requestedPeriod": "Requested Period",
    "fhir.Appointment.serviceCategory": "Service Category",
    "fhir.Appointment.serviceType": "Service Type",
    "fhir.Appointment.slot": "Slot",
    "fhir.Appointment.specialty": "Specialty",
    "fhir.Appointment.start": "Start",
    "fhir.Appointment.status": "Status",
    "fhir.Appointment.supportingInformation": "Supporting Information",
    "fhir.AppointmentOnlineEditable": "Online Editable",
    "fhir.AppointmentParticipant": "Appointment Participant",
    "fhir.AppointmentParticipant.actor": "Actor",
    "fhir.AppointmentParticipant.actor.practitionerRole": "Practitioner Role",
    "fhir.AppointmentParticipant.required": "Required",
    "fhir.AppointmentParticipant.status": "Status",
    "fhir.AppointmentParticipant.type": "Type",
    "fhir.AppointmentParticipants": "Appointment Participants",
    "fhir.Composition": "Composition",
    "fhir.Composition.Attester.mode": "Role",
    "fhir.Composition.Attester.party": "Reviewing Party",
    "fhir.Composition.Attester.time": "Time",
    "fhir.Composition.Event.code": "Code",
    "fhir.Composition.Event.detail": "Detail",
    "fhir.Composition.Event.period": "Period",
    "fhir.Composition.ICPCcode": "ICPC Code",
    "fhir.Composition.ICPCdescription": "ICPC Description",
    "fhir.Composition.RelatesTo.code": "Code",
    "fhir.Composition.RelatesTo.targetReference": "Target Reference",
    "fhir.Composition.Section.code": "Code",
    "fhir.Composition.Section.emptyReason": "EmptyReason",
    "fhir.Composition.Section.entry": "Entry",
    "fhir.Composition.Section.extension.icpcCode": "ICPC Code",
    "fhir.Composition.Section.extension.icpcDescription": "ICPC description",
    "fhir.Composition.Section.mode": "Mode",
    "fhir.Composition.Section.orderedBy": "Ordered By",
    "fhir.Composition.Section.title": "Title",
    "fhir.Composition.attester": "Document reviewer",
    "fhir.Composition.author": "Author",
    "fhir.Composition.class": "Class",
    "fhir.Composition.confidentiality": "Confidentiality",
    "fhir.Composition.custodian": "Custodian",
    "fhir.Composition.date": "Date",
    "fhir.Composition.encounter": "Encounter",
    "fhir.Composition.event": "Type of event related to the document",
    "fhir.Composition.icpcCode": "ICPC Code",
    "fhir.Composition.icpcDescription": "ICPC Description",
    "fhir.Composition.identifier": "Identifier",
    "fhir.Composition.relatesTo": "Document Relates To",
    "fhir.Composition.section": "Composition Section",
    "fhir.Composition.status": "Status",
    "fhir.Composition.subject": "Subject",
    "fhir.Composition.title": "Title",
    "fhir.Composition.type": "Type",
    "fhir.Condition.Evidence.code": "Code",
    "fhir.Condition.Evidence.detail": "Detail",
    "fhir.Condition.Stage.assessment": "Assessment",
    "fhir.Condition.Stage.summary": "Summary",
    "fhir.Condition.Stage.type": "Type",
    "fhir.Condition.abatement": "Abatement",
    "fhir.Condition.asserter": "Asserter",
    "fhir.Condition.bodySite": "Body site",
    "fhir.Condition.category": "Category",
    "fhir.Condition.clinicalStatus": "Status",
    "fhir.Condition.code": "Description",
    "fhir.Condition.context": "Context",
    "fhir.Condition.encounter": "Encounter",
    "fhir.Condition.evidence": "Evidence",
    "fhir.Condition.onset": "Onset",
    "fhir.Condition.recordedDate": "Recorded date",
    "fhir.Condition.recorder": "Recorder",
    "fhir.Condition.severity": "Severity",
    "fhir.Condition.stage": "Stage",
    "fhir.Condition.subject": "Subject",
    "fhir.Condition.verificationStatus": "Verification status",
    "fhir.ConditionEvidence": "Condition Evidence",
    "fhir.ConditionEvidence.code": "Code",
    "fhir.ConditionEvidence.detail": "Detail",
    "fhir.ConditionStage": "Condition Stage",
    "fhir.Consent": "Consent",
    "fhir.Consent.action": "Action",
    "fhir.Consent.actor": "Actor",
    "fhir.Consent.actor.reference": "Reference",
    "fhir.Consent.actor.role": "Role",
    "fhir.Consent.category": "Category",
    "fhir.Consent.consentingParty": "Consenting party",
    "fhir.Consent.data": "Data",
    "fhir.Consent.data.meaning": "Meaning",
    "fhir.Consent.data.reference": "Reference",
    "fhir.Consent.dataPeriod": "Data Period",
    "fhir.Consent.dateTime": "Date",
    "fhir.Consent.except": "Except",
    "fhir.Consent.except.action": "Exceptions on Consent",
    "fhir.Consent.except.class": "Class",
    "fhir.Consent.except.code": "Code",
    "fhir.Consent.except.dataPeriod": "Data Period",
    "fhir.Consent.except.period": "Period",
    "fhir.Consent.except.purpose": "Purpose",
    "fhir.Consent.except.securityLabel": "Security Label",
    "fhir.Consent.except.type": "Type",
    "fhir.Consent.meaning": "Meaning",
    "fhir.Consent.organization": "Organization",
    "fhir.Consent.patient": "Patient",
    "fhir.Consent.period": "Period",
    "fhir.Consent.policy": "Policy",
    "fhir.Consent.policy.authority": "Authority",
    "fhir.Consent.policy.uri": "Uri",
    "fhir.Consent.policyRule": "Policy Rule",
    "fhir.Consent.purpose": "Purpose",
    "fhir.Consent.reference": "Reference",
    "fhir.Consent.role": "Role",
    "fhir.Consent.securityLabel": "Security Label",
    "fhir.Consent.source": "Source",
    "fhir.Consent.status": "Status",
    "fhir.ConsentActor": "Consent Actor",
    "fhir.ConsentData": "Consent Data",
    "fhir.ConsentExcept": "Consent Except",
    "fhir.ConsentExcept.period": "Period",
    "fhir.ConsentExcept.type": "Type",
    "fhir.ConsentPolicy": "Consent Policy",
    "fhir.ContactPoint": "Contact Point",
    "fhir.ContactPoint.type": "Telecom Type",
    "fhir.ContactPoint.use": "Number \/ Email Type",
    "fhir.Coverage.Payor.BankInformation": "Bank Information",
    "fhir.Coverage.Payor.BankInformation.accountNumber": "Account Number",
    "fhir.Coverage.Payor.BankInformation.bankCode": "Bank Code",
    "fhir.Coverage.Payor.BankInformation.bankName": "Bank Name",
    "fhir.Coverage.Payor.name": "Payer Name",
    "fhir.Coverage.beneficiary": "Insurance Beneficiary",
    "fhir.Coverage.contract": "Contract",
    "fhir.Coverage.dependent": "Payer Person",
    "fhir.Coverage.network": "Insurance package",
    "fhir.Coverage.order": "Order",
    "fhir.Coverage.payor": "Payer",
    "fhir.Coverage.payors": "Payer",
    "fhir.Coverage.period": "Insurance Period",
    "fhir.Coverage.policyHolder": "Policy Holder",
    "fhir.Coverage.relationship": "Relationship",
    "fhir.Coverage.sequence": "Sequence",
    "fhir.Coverage.status": "Status",
    "fhir.Coverage.subscriber": "Main Insurant",
    "fhir.Coverage.subscriberId": "Insurance Number",
    "fhir.Coverage.type": "Insurance Type",
    "fhir.Device.expirationDate": "Expiration Date",
    "fhir.Device.location": "Anatomical Location",
    "fhir.Device.lotNumber": "Lot Number",
    "fhir.Device.manufactureDate": "Manufacturer Date",
    "fhir.Device.manufacturer": "Manufacturer",
    "fhir.Device.model": "Model",
    "fhir.Device.owner": "Healthcare Provider",
    "fhir.Device.patient": "Patient",
    "fhir.Device.safety": "Safety",
    "fhir.Device.status": "Status",
    "fhir.Device.type": "Type",
    "fhir.Device.udi": "UDI Name",
    "fhir.Device.udi.name": "UDI Name",
    "fhir.DeviceUdi.carrierAIDC": "Product ID; AIDC",
    "fhir.DeviceUdi.carrierHRF": "Product ID; HRF",
    "fhir.DeviceUdi.deviceIdentifier": "Product ID",
    "fhir.DeviceUdi.deviceUdi": "Product ID; UDI",
    "fhir.DeviceUdi.entryType": "Entry Type",
    "fhir.DeviceUdi.issuer": "Publisher",
    "fhir.DeviceUdi.jurisdiction": "Jurisdiction",
    "fhir.DeviceUdi.name": "Name",
    "fhir.DeviceUseStatement.bodySite": "Body site",
    "fhir.DeviceUseStatement.device": "Device",
    "fhir.DeviceUseStatement.healthProfessional": "Health Professional",
    "fhir.DeviceUseStatement.healthcareProvider": "Healthcare Provider",
    "fhir.DeviceUseStatement.indication": "Indication",
    "fhir.DeviceUseStatement.reasonReference": "Reason Reference",
    "fhir.DeviceUseStatement.recordedOn": "Recorded on",
    "fhir.DeviceUseStatement.source": "Source",
    "fhir.DeviceUseStatement.status": "Status",
    "fhir.DeviceUseStatement.subject": "Subject",
    "fhir.DeviceUseStatement.timing": "Time",
    "fhir.DeviceUseStatement.whenUsed": "When Used",
    "fhir.Diagnose.onset": "Onset",
    "fhir.Diagnosis.clinicalStatus": "Problem Status",
    "fhir.Diagnosis.code": "Code",
    "fhir.Diagnosis.onset": "Onset",
    "fhir.Diagnosis.recordedDate": "Recorded date",
    "fhir.DiagnosticReport.Code": "Code",
    "fhir.DiagnosticReport.Performer.Actor": "Actor",
    "fhir.DiagnosticReport.Performer.Role": "Role",
    "fhir.DiagnosticReport.basedOn": "Based on",
    "fhir.DiagnosticReport.category": "Category",
    "fhir.DiagnosticReport.codedDiagnosis": "Coded Diagnosis",
    "fhir.DiagnosticReport.conclusion": "Conclusion",
    "fhir.DiagnosticReport.context": "Context",
    "fhir.DiagnosticReport.effective": "Effective",
    "fhir.DiagnosticReport.imagingStudy": "Imaging Study",
    "fhir.DiagnosticReport.issued": "Issued",
    "fhir.DiagnosticReport.performer": "Performer",
    "fhir.DiagnosticReport.result": "Result",
    "fhir.DiagnosticReport.specimen": "Specimen",
    "fhir.DiagnosticReport.status": "Status",
    "fhir.DiagnosticReport.subject": "Subject",
    "fhir.Dosage.additionalInstruction": "Additional Instruction",
    "fhir.Dosage.asNeeded": "As Needed",
    "fhir.Dosage.dose": "Dose",
    "fhir.Dosage.maxDosePerAdministration": "Max Dose Per Administration",
    "fhir.Dosage.maxDosePerLifetime": "Max Dose Per Lifetime",
    "fhir.Dosage.method": "Method",
    "fhir.Dosage.patientInstruction": "Patient Instruction",
    "fhir.Dosage.rate": "Rate",
    "fhir.Dosage.route": "Route",
    "fhir.Dosage.sequence": "Sequence",
    "fhir.Dosage.site": "Site",
    "fhir.Dosage.text": "Text",
    "fhir.Encounter.account": "Account",
    "fhir.Encounter.appointment": "Appointment",
    "fhir.Encounter.class": "Type",
    "fhir.Encounter.episodeOfCare": "Episode Of Care",
    "fhir.Encounter.identifier": "Identifier",
    "fhir.Encounter.incomingReferral": "Incoming Referral",
    "fhir.Encounter.length": "Length",
    "fhir.Encounter.partOf": "Part Of",
    "fhir.Encounter.period": "Period",
    "fhir.Encounter.priority": "Priority",
    "fhir.Encounter.reason": "Reason",
    "fhir.Encounter.serviceProvider": "Service Provider",
    "fhir.Encounter.status": "Status",
    "fhir.Encounter.subject": "Subject",
    "fhir.Encounter.type": "Type",
    "fhir.EncounterClassHistory.class": "Class",
    "fhir.EncounterClassHistory.period": "Period",
    "fhir.EncounterDiagnoses": "Encounter Diagnoses",
    "fhir.EncounterDiagnosis": "Encounter Diagnosis",
    "fhir.EncounterDiagnosis.condition": "Condition",
    "fhir.EncounterDiagnosis.rank": "Rank",
    "fhir.EncounterDiagnosis.role": "Role",
    "fhir.EncounterHospitalization": "Encounter Hospitalization",
    "fhir.EncounterHospitalization.admitSource": "Admit Source",
    "fhir.EncounterHospitalization.destination": "Destination after discharge",
    "fhir.EncounterHospitalization.dietPreference": "Diet Preference",
    "fhir.EncounterHospitalization.dischargeDisposition": "Discharge Disposition",
    "fhir.EncounterHospitalization.origin": "Origin patiënt",
    "fhir.EncounterHospitalization.preAdmissionIdentifier": "Pre-Admission Identifier",
    "fhir.EncounterHospitalization.reAdmission": "Re-Admission",
    "fhir.EncounterHospitalization.specialArrangement": "Special Arrangement",
    "fhir.EncounterHospitalization.specialCourtesy": "Special Courtesy",
    "fhir.EncounterLocation": "Encounter Location",
    "fhir.EncounterLocation.location": "Location",
    "fhir.EncounterLocation.period": "Period",
    "fhir.EncounterLocation.status": "Status",
    "fhir.EncounterLocations": "Encounter Locations",
    "fhir.EncounterParticipant": "Encounter Participant",
    "fhir.EncounterParticipant.individual": "Individual",
    "fhir.EncounterParticipant.period": "Period",
    "fhir.EncounterParticipant.type": "Type",
    "fhir.EncounterParticipants": "Encounter Participants",
    "fhir.EncounterStatusHistories": "Encounter Status Histories",
    "fhir.EncounterStatusHistory": "Encounter Status History",
    "fhir.EncounterStatusHistory.period": "Period",
    "fhir.EncounterStatusHistory.status": "Status",
    "fhir.EpisodeOfCare.Diagnosis.condition": "Condition",
    "fhir.EpisodeOfCare.Diagnosis.role": "Role",
    "fhir.EpisodeOfCare.StatusHistory.period": "Period",
    "fhir.EpisodeOfCare.StatusHistory.status": "Status",
    "fhir.EpisodeOfCare.careManager": "Care Manager",
    "fhir.EpisodeOfCare.diagnoses": "Title",
    "fhir.EpisodeOfCare.diagnosis": "Diagnosis",
    "fhir.EpisodeOfCare.firstEncounter": "First Encounter",
    "fhir.EpisodeOfCare.lastEncounter": "Last Encounter",
    "fhir.EpisodeOfCare.managingOrganization": "Managing Organization",
    "fhir.EpisodeOfCare.patient": "Patient",
    "fhir.EpisodeOfCare.period": "Period",
    "fhir.EpisodeOfCare.status": "Status",
    "fhir.EpisodeOfCare.statusHistory": "Status",
    "fhir.EpisodeOfCare.title": "Description",
    "fhir.EpisodeOfCare.type": "Type",
    "fhir.Flag.code": "Code",
    "fhir.Flag.identifier": "Identifier",
    "fhir.Flag.period": "Period",
    "fhir.Flag.status": "Status",
    "fhir.Flag.subject": "Subject",
    "fhir.HumanName": "Human Name",
    "fhir.HumanName.family": "Last Name",
    "fhir.HumanName.family.fathers-family": "Fathers Last Name",
    "fhir.HumanName.family.mothers-family": "Mothers Last Name",
    "fhir.HumanName.family.own-name": "Last Name",
    "fhir.HumanName.family.own-prefix": "Prefix",
    "fhir.HumanName.family.partner-name": "Partner Last Name",
    "fhir.HumanName.family.partner-prefix": "Partner Prefix",
    "fhir.HumanName.given": "Name",
    "fhir.HumanName.name": "Name Information",
    "fhir.HumanName.use": "Name Usage",
    "fhir.Identifier.assigner": "Assigner",
    "fhir.Identifier.period": "Period",
    "fhir.Identifier.system": "Reference",
    "fhir.Identifier.type": "Type",
    "fhir.Identifier.use": "Use",
    "fhir.Identifier.value": "Value",
    "fhir.ImagingStudy.availability": "Availability",
    "fhir.ImagingStudy.basedOn": "Based On",
    "fhir.ImagingStudy.context": "Context",
    "fhir.ImagingStudy.description": "Description",
    "fhir.ImagingStudy.endpoint": "Endpoint",
    "fhir.ImagingStudy.interpreter": "Interpreter",
    "fhir.ImagingStudy.modalityList": "Modality List",
    "fhir.ImagingStudy.numberOfInstances": "Number Of Instances",
    "fhir.ImagingStudy.numberOfSeries": "Number Of Series",
    "fhir.ImagingStudy.patient": "Patient",
    "fhir.ImagingStudy.procedureCode": "Procedure Code",
    "fhir.ImagingStudy.procedureReference": "Procedure Reference",
    "fhir.ImagingStudy.reason": "Reason",
    "fhir.ImagingStudy.referrer": "Referrer",
    "fhir.ImagingStudy.series": "Series",
    "fhir.ImagingStudy.started": "Started",
    "fhir.ImagingStudy.uid": "UID",
    "fhir.ImagingStudySeries.availability": "Availability",
    "fhir.ImagingStudySeries.bodySite": "Body Site",
    "fhir.ImagingStudySeries.description": "Description",
    "fhir.ImagingStudySeries.endpoint": "Endpoint",
    "fhir.ImagingStudySeries.header": "Imaging Study Series",
    "fhir.ImagingStudySeries.laterality": "Laterality",
    "fhir.ImagingStudySeries.modality": "Modality",
    "fhir.ImagingStudySeries.number": "Number",
    "fhir.ImagingStudySeries.numberOfInstances": "Number Of Instances",
    "fhir.ImagingStudySeries.performer": "Performer",
    "fhir.ImagingStudySeries.started": "Started",
    "fhir.ImagingStudySeries.uid": "UID",
    "fhir.ImagingStudySeriesInstance.header": "Imaging Study Series Instance",
    "fhir.ImagingStudySeriesInstance.number": "Number",
    "fhir.ImagingStudySeriesInstance.sopClass": "DICOM Instance Type",
    "fhir.ImagingStudySeriesInstance.title": "Title",
    "fhir.ImagingStudySeriesInstance.uid": "UID",
    "fhir.ImagingStudySeriesInstances.header": "Imaging Study Series Instances",
    "fhir.Immunization.doseQuantity": "Dose Quantity",
    "fhir.Immunization.encounter": "Encounter",
    "fhir.Immunization.expirationDate": "Expiration Date",
    "fhir.Immunization.location": "Location",
    "fhir.Immunization.lotNumber": "Lot Number",
    "fhir.Immunization.manufacturer": "Manufacturer",
    "fhir.Immunization.notGiven": "Not Given",
    "fhir.Immunization.occurrence": "Date",
    "fhir.Immunization.patient": "Patient",
    "fhir.Immunization.practitioner": "Practitioner",
    "fhir.Immunization.primarySource": "Primary Source",
    "fhir.Immunization.reportOrigin": "Report Origin",
    "fhir.Immunization.route": "Route",
    "fhir.Immunization.site": "Site",
    "fhir.Immunization.status": "Status",
    "fhir.Immunization.statusReason": "Status Reason",
    "fhir.Immunization.vaccineCode": "Vaccine Code",
    "fhir.ImmunizationRecommendation.Recommendation.dateCriterion": "Date Criteria",
    "fhir.ImmunizationRecommendation.Recommendation.supportingImmunization": "Supporting Immunization",
    "fhir.ImmunizationRecommendation.authority": "Authority",
    "fhir.ImmunizationRecommendation.date": "Date",
    "fhir.ImmunizationRecommendation.patient": "Patient",
    "fhir.ImmunizationRecommendation.recommendation.contraindicatedVaccineCode": "Contraindicated Vaccine Code",
    "fhir.ImmunizationRecommendation.recommendation.date": "Date",
    "fhir.ImmunizationRecommendation.recommendation.dateCriterion.code": "Code",
    "fhir.ImmunizationRecommendation.recommendation.dateCriterion.value": "Recommended date",
    "fhir.ImmunizationRecommendation.recommendation.description": "Description",
    "fhir.ImmunizationRecommendation.recommendation.doseNumber": "Dose number",
    "fhir.ImmunizationRecommendation.recommendation.forecastReason": "Forecast Reason",
    "fhir.ImmunizationRecommendation.recommendation.forecastStatus": "Forecast status",
    "fhir.ImmunizationRecommendation.recommendation.protocol.authority": "Authority",
    "fhir.ImmunizationRecommendation.recommendation.protocol.description": "Description",
    "fhir.ImmunizationRecommendation.recommendation.protocol.doseSequence": "Dose sequence",
    "fhir.ImmunizationRecommendation.recommendation.protocol.series": "Series",
    "fhir.ImmunizationRecommendation.recommendation.series": "Series",
    "fhir.ImmunizationRecommendation.recommendation.seriesDoses": "Series Doses",
    "fhir.ImmunizationRecommendation.recommendation.supportingImmunization": "Supporting Immunization",
    "fhir.ImmunizationRecommendation.recommendation.supportingPatientInformation": "Supporting Patient Information",
    "fhir.ImmunizationRecommendation.recommendation.targetDisease": "Target disease",
    "fhir.ImmunizationRecommendation.recommendation.vaccineCode": "Vaccine code",
    "fhir.ImmunizationRecommendationRecommendation.dateCriterion": "Date Criteria",
    "fhir.ImmunizationRecommendationRecommendation.supportingImmunization": "Supporting Immunization",
    "fhir.Immunzation.explanation": "Explanation",
    "fhir.Immunzation.explanation.reason": "Reason",
    "fhir.Immunzation.explanation.reasonNotGiven": "Reason not given",
    "fhir.Immunzation.practitioner.actor": "Actor",
    "fhir.Immunzation.practitioner.role": "Role",
    "fhir.Immunzation.reaction": "Reactions",
    "fhir.Immunzation.reaction.date": "Date",
    "fhir.Immunzation.reaction.detail": "Detail",
    "fhir.Immunzation.reaction.reported": "Self-reported",
    "fhir.Immunzation.vaccinationProtocol.authority": "Authority",
    "fhir.Immunzation.vaccinationProtocol.description": "Description",
    "fhir.Immunzation.vaccinationProtocol.doseSequence": "Dose sequence",
    "fhir.Immunzation.vaccinationProtocol.doseStatus": "Dose status",
    "fhir.Immunzation.vaccinationProtocol.doseStatusReason": "Dose status reason",
    "fhir.Immunzation.vaccinationProtocol.series": "Series",
    "fhir.Immunzation.vaccinationProtocol.seriesDoses": "Series doses",
    "fhir.Immunzation.vaccinationProtocol.targetDisease": "Target diseases",
    "fhir.MedicalAid.device": "Device",
    "fhir.MedicalAid.whenUsed": "When Used",
    "fhir.Medication.Ingredient.amount": "Amount",
    "fhir.Medication.Ingredient.isActive": "Is active",
    "fhir.Medication.Ingredient.itemCodeableConcept": "Medication Ingredient",
    "fhir.Medication.Ingredient.itemReference": "Reference code list",
    "fhir.Medication.authoredOn": "Authored on",
    "fhir.Medication.code": "Code",
    "fhir.Medication.form": "Pharmaceutical form",
    "fhir.Medication.ingredient": "Medication ingredient",
    "fhir.Medication.isBrand": "Is Brand",
    "fhir.Medication.isOverTheCounter": "OTC",
    "fhir.Medication.manufacturer": "Manufacturer",
    "fhir.Medication.medication": "Medication",
    "fhir.Medication.package": "Medication Package",
    "fhir.Medication.package.batch": "Medication Package Batch",
    "fhir.Medication.package.batch.expirationDate": "Expiration date",
    "fhir.Medication.package.batch.lotNumber": "Lot number",
    "fhir.Medication.package.container": "Container",
    "fhir.Medication.package.content": "Medication Package Content",
    "fhir.Medication.package.content.amount": "Amount",
    "fhir.Medication.package.content.itemCodeableConcept": "Package content",
    "fhir.Medication.package.content.itemReference": "Package content reference",
    "fhir.Medication.status": "Status",
    "fhir.MedicationDispense.authorizingPrescription": "Authorizing Prescription",
    "fhir.MedicationDispense.category": "Category",
    "fhir.MedicationDispense.context": "Context",
    "fhir.MedicationDispense.daysSupply": "Days Supply",
    "fhir.MedicationDispense.destination": "Destination",
    "fhir.MedicationDispense.medication": "Medication",
    "fhir.MedicationDispense.notDone": "Not Done",
    "fhir.MedicationDispense.notDoneReasonCodeableConcept": "Not Done Reason",
    "fhir.MedicationDispense.partOf": "Part Of",
    "fhir.MedicationDispense.performer.actor": "Actor",
    "fhir.MedicationDispense.performer.function": "Function",
    "fhir.MedicationDispense.performer.medicationDispensePerformer": "Supplier",
    "fhir.MedicationDispense.performer.onBehalfOf": "On Behalf Of",
    "fhir.MedicationDispense.quantity": "Quantity",
    "fhir.MedicationDispense.receiver": "Receiver",
    "fhir.MedicationDispense.status": "Status",
    "fhir.MedicationDispense.subject": "Subject",
    "fhir.MedicationDispense.substitution.medicationDispenseSubstitution": "Medication Dispense Substitution",
    "fhir.MedicationDispense.substitution.reason": "Reason",
    "fhir.MedicationDispense.substitution.responsibleParty": "Responsible Party",
    "fhir.MedicationDispense.substitution.type": "Type",
    "fhir.MedicationDispense.substitution.wasSubstituted": "Was Substituted",
    "fhir.MedicationDispense.type": "Type",
    "fhir.MedicationDispense.whenHandedOver": "When Handed Over",
    "fhir.MedicationDispense.whenPrepared": "When Prepared",
    "fhir.MedicationRequest.DispenseRequest.duration": "Duration",
    "fhir.MedicationRequest.DispenseRequest.numberOfRepeatsAllowed": "Number Of Repeats Allowed",
    "fhir.MedicationRequest.DispenseRequest.quantity": "Quantity",
    "fhir.MedicationRequest.DispenseRequest.validityPeriod": "Validity Period",
    "fhir.MedicationRequest.Requester.agent": "Agent",
    "fhir.MedicationRequest.Requester.onBehalfOf": "On behalf of",
    "fhir.MedicationRequest.Requester.performer": "Performer",
    "fhir.MedicationRequest.authoredOn": "Date Time",
    "fhir.MedicationRequest.basedOn": "Based on",
    "fhir.MedicationRequest.category": "Category",
    "fhir.MedicationRequest.context": "Context",
    "fhir.MedicationRequest.definition": "Definition",
    "fhir.MedicationRequest.dispenseRequest": "Dispense Request",
    "fhir.MedicationRequest.intent": "Intent",
    "fhir.MedicationRequest.medication": "Medication",
    "fhir.MedicationRequest.priorPrescription": "Prior Prescription",
    "fhir.MedicationRequest.priority": "Priority",
    "fhir.MedicationRequest.reasonCode": "Reason Code",
    "fhir.MedicationRequest.reasonReference": "Reason Reference",
    "fhir.MedicationRequest.recorder": "Recorder",
    "fhir.MedicationRequest.requester": "Prescriber",
    "fhir.MedicationRequest.status": "Status",
    "fhir.MedicationRequest.subject": "Subject",
    "fhir.MedicationRequest.supportingInformation": "Supporting Information",
    "fhir.MedicationStatement.basedOn": "Based on",
    "fhir.MedicationStatement.category": "Category",
    "fhir.MedicationStatement.context": "Context",
    "fhir.MedicationStatement.dateAsserted": "Asserted Date",
    "fhir.MedicationStatement.derivedFrom": "Derived From",
    "fhir.MedicationStatement.effectiveDateTime": "Effective",
    "fhir.MedicationStatement.informationSource": "Information Source",
    "fhir.MedicationStatement.medication": "Medication",
    "fhir.MedicationStatement.partOf": "Part of",
    "fhir.MedicationStatement.reasonCode": "Reason Code",
    "fhir.MedicationStatement.reasonNotTaken": "Reason Not Taken",
    "fhir.MedicationStatement.reasonReference": "Reason Reference",
    "fhir.MedicationStatement.status": "Status",
    "fhir.MedicationStatement.subject": "Subject",
    "fhir.MedicationStatement.taken": "Taken",
    "fhir.Narrative": "Narrative",
    "fhir.NutritionAdvice.dateTime": "Date",
    "fhir.NutritionAdvice.orderer": "Orderer",
    "fhir.NutritionAdvice.status": "Status",
    "fhir.NutritionOrder": "Nutrition Order",
    "fhir.NutritionOrder.allergyIntolerance": "Allergy \/ Intolerance",
    "fhir.NutritionOrder.comment": "Comment",
    "fhir.NutritionOrder.dateTime": "Date Time",
    "fhir.NutritionOrder.encounter": "Encounter",
    "fhir.NutritionOrder.excludeFoodModifier": "Exclude Food Modifier",
    "fhir.NutritionOrder.foodPreferenceModifier": "Food Preference Modifier",
    "fhir.NutritionOrder.oralDiet.consistency": "Consistency",
    "fhir.NutritionOrder.oralDiet.type": "Type",
    "fhir.NutritionOrder.orderer": "Orderer",
    "fhir.NutritionOrder.patient": "Patient",
    "fhir.NutritionOrder.status": "Status",
    "fhir.NutritionOrderEnteralFormula": "Nutrition Order Enteral Formula",
    "fhir.NutritionOrderEnteralFormula.additiveProductName": "Additive Product Name",
    "fhir.NutritionOrderEnteralFormula.additiveType": "Additive Type",
    "fhir.NutritionOrderEnteralFormula.administrationInstruction": "Administration Instruction",
    "fhir.NutritionOrderEnteralFormula.baseFormulaProductName": "Base Formula Product Name",
    "fhir.NutritionOrderEnteralFormula.baseFormulaType": "Base Formula Type",
    "fhir.NutritionOrderEnteralFormula.caloricDensity": "Caloric Density",
    "fhir.NutritionOrderEnteralFormula.maxVolumeToDeliver": "Max Volume To Deliver",
    "fhir.NutritionOrderEnteralFormula.routeofAdministration": "Route Of Administration",
    "fhir.NutritionOrderEnteralFormulaAdministration": "Nutrition Order Enteral Formula Administrations",
    "fhir.NutritionOrderEnteralFormulaAdministration.quantity": "Quantity",
    "fhir.NutritionOrderEnteralFormulaAdministration.rateQuantity": "Rate Quantity",
    "fhir.NutritionOrderEnteralFormulaAdministration.rateRatio": "Rate Ratio",
    "fhir.NutritionOrderOralDiet": "Nutrition Order Oral Diet",
    "fhir.NutritionOrderOralDiet.fluidConsistencyType": "Fluid Consistency Type",
    "fhir.NutritionOrderOralDiet.instruction": "Instruction",
    "fhir.NutritionOrderOralDiet.type": "Type",
    "fhir.NutritionOrderOralDietNutrient": "Nutrition Order Oral Diet Nutrients",
    "fhir.NutritionOrderOralDietNutrient.amount": "Amount",
    "fhir.NutritionOrderOralDietNutrient.modifier": "Modifier",
    "fhir.NutritionOrderOralDietTexture": "Nutrition Order Oral Diet Textures",
    "fhir.NutritionOrderOralDietTexture.foodType": "Food Type",
    "fhir.NutritionOrderOralDietTexture.modifier": "Modifier",
    "fhir.NutritionOrderSupplement": "Nutrition Order Supplement",
    "fhir.NutritionOrderSupplement.instruction": "Supplement Instruction",
    "fhir.NutritionOrderSupplement.productName": "Supplement Product Name",
    "fhir.NutritionOrderSupplement.quantity": "Supplement Quantity",
    "fhir.NutritionOrderSupplement.supplementType": "Supplement Type",
    "fhir.Observation.BloodPressure.diastolic": "Diastolic",
    "fhir.Observation.BloodPressure.systolic": "Systolic",
    "fhir.Observation.BodyHeight": "Height",
    "fhir.Observation.BodyHeight.height": "Height",
    "fhir.Observation.BodyHeight.source": "Source",
    "fhir.Observation.BodyTemperature": "Temperature",
    "fhir.Observation.BodyTemperature.temperature": "Temperature",
    "fhir.Observation.BodyWeight": "Weight",
    "fhir.Observation.BodyWeight.source": "Source",
    "fhir.Observation.BodyWeight.weight": "Weight",
    "fhir.Observation.Component.code": "Code",
    "fhir.Observation.Component.dataAbsentReason": "Data Absent Reason",
    "fhir.Observation.Component.interpretation": "Interpretation",
    "fhir.Observation.Component.value": "Value",
    "fhir.Observation.Glucose": "Glucose",
    "fhir.Observation.Glucose.glucose": "Glucose",
    "fhir.Observation.HeartAndPulseRates.rate": "Rate",
    "fhir.Observation.HeartAndPulseRates.type": "Type",
    "fhir.Observation.O2Saturation": "O2 Saturation",
    "fhir.Observation.O2Saturation.o2-saturation": "O2 Saturation",
    "fhir.Observation.ReferenceRange.age": "Age",
    "fhir.Observation.ReferenceRange.appliesTo": "Applies To",
    "fhir.Observation.ReferenceRange.high": "High",
    "fhir.Observation.ReferenceRange.low": "Low",
    "fhir.Observation.ReferenceRange.text": "Text",
    "fhir.Observation.ReferenceRange.type": "Type",
    "fhir.Observation.RespiratoryRate.rate": "Rate",
    "fhir.Observation.basedOn": "Based on",
    "fhir.Observation.bodySite": "Body site",
    "fhir.Observation.category": "Category",
    "fhir.Observation.clinicalStatus": "Clinical Status",
    "fhir.Observation.code": "Code",
    "fhir.Observation.comment": "Comment",
    "fhir.Observation.component": "Component",
    "fhir.Observation.context": "Context",
    "fhir.Observation.criticality": "Criticality",
    "fhir.Observation.dataAbsentReason": "Data Absent Reason",
    "fhir.Observation.date": "Date",
    "fhir.Observation.derivedFrom": "Derived from",
    "fhir.Observation.device": "Device",
    "fhir.Observation.effective": "Date",
    "fhir.Observation.effectivePeriod": "Period",
    "fhir.Observation.encounter": "Encounter",
    "fhir.Observation.focus": "Focus",
    "fhir.Observation.hasMember": "Related resource",
    "fhir.Observation.interpretation": "Interpretation",
    "fhir.Observation.issued": "Issued",
    "fhir.Observation.method": "Method",
    "fhir.Observation.name": "Name",
    "fhir.Observation.partOf": "Part of",
    "fhir.Observation.performer": "Performer",
    "fhir.Observation.period": "Period",
    "fhir.Observation.referenceRange": "Reference range",
    "fhir.Observation.respirationRate.administeredOxygen": "Administered Oxygen",
    "fhir.Observation.respirationRate.flowRate": "Flow Rate",
    "fhir.Observation.respirationRate.inspiredOxygen": "Inspired Oxygen",
    "fhir.Observation.situation": "Situation",
    "fhir.Observation.specimen": "Specimen",
    "fhir.Observation.status": "Status",
    "fhir.Observation.subject": "Patient",
    "fhir.Observation.timingEvent": "Timing Event",
    "fhir.Observation.use": "Use",
    "fhir.Observation.value": "Value",
    "fhir.Observation.valueCodeableConcept": "Value",
    "fhir.Observation.verificationStatus": "Verification Status",
    "fhir.Organization.city": "City",
    "fhir.Organization.contact.name": "Name",
    "fhir.Organization.contact.purpose": "Purpose",
    "fhir.Organization.name": "Name",
    "fhir.Organization.type": "Type",
    "fhir.OrganizationContact.name": "Name",
    "fhir.OrganizationContact.purpose": "Purpose",
    "fhir.Patient": "Patient",
    "fhir.Patient.birthDate": "Date Of Birth",
    "fhir.Patient.deceased": "Deceased",
    "fhir.Patient.deceased.no": "No",
    "fhir.Patient.deceased.yes": "Yes",
    "fhir.Patient.gender": "Gender",
    "fhir.Patient.legalStatus": "Legal Status",
    "fhir.Patient.lifeStance": "Life Stance",
    "fhir.Patient.maritalStatus": "Marital status",
    "fhir.Patient.multipleBirth": "Multiple Birth Indicator",
    "fhir.Patient.multipleBirth.No": "No",
    "fhir.Patient.multipleBirth.no": "No",
    "fhir.Patient.multipleBirth.yes": "Yes",
    "fhir.Patient.name": "Name",
    "fhir.Patient.nationality": "Nationality",
    "fhir.Patient.preferredPharmacy": "Preferred Pharmacy",
    "fhir.Patient.proficiency": "Language Proficiency",
    "fhir.Patient.telecom": "Phone",
    "fhir.PatientContact.gender": "Gender",
    "fhir.PatientContact.name": "Name",
    "fhir.PatientContact.organization": "Organization",
    "fhir.PatientContact.period": "Period",
    "fhir.PatientContact.relation": "Relation",
    "fhir.PatientContact.relationship": "Relationship",
    "fhir.PatientContact.role": "Role",
    "fhir.Period.end": "Till",
    "fhir.Period.lowerTextOfEnd": "to",
    "fhir.Period.start": "From",
    "fhir.Period.upperTextOfEnd": "To",
    "fhir.PortabilityReport.downloadReport": "Download Report",
    "fhir.PortabilityReport.downloadingReport": "Downloading Report...",
    "fhir.PortabilityReport.footer": "Your Report Will Be Downloaded As An XML File As Per Medmij Guidelines. You Can View It In A Text Editor, Excel, Or Similar. ",
    "fhir.PortabilityReport.header": " Export your Portability Report ",
    "fhir.PortabilityReport.select": "If you have ever linked this app to any of your healthcare providers, you can request a copy of your portability report. To get started, select the time period you need.",
    "fhir.Practitioner.Qualification.code": "Code",
    "fhir.Practitioner.Qualification.period": "Period",
    "fhir.Practitioner.birthDate": "Birth date",
    "fhir.Practitioner.birthdate": "Birth date",
    "fhir.Practitioner.communication": "Language",
    "fhir.Practitioner.gender": "Gender",
    "fhir.Practitioner.name": "Name",
    "fhir.Practitioner.qualification": "Qualification",
    "fhir.PractitionerRole.active": "Active",
    "fhir.PractitionerRole.availabilityExceptions": "Availability Exceptions",
    "fhir.PractitionerRole.code": "Code",
    "fhir.PractitionerRole.healthcareService": "Healthcare Service",
    "fhir.PractitionerRole.location": "Location",
    "fhir.PractitionerRole.organization": "Organization",
    "fhir.PractitionerRole.period": "Period",
    "fhir.PractitionerRole.practitioner": "Practitioner",
    "fhir.PractitionerRole.specialty": "Specialty",
    "fhir.Procedure": "Procedure",
    "fhir.Procedure.basedOn": "Based On",
    "fhir.Procedure.bodySite": "Body Site",
    "fhir.Procedure.category": "Category",
    "fhir.Procedure.code": "Procedure",
    "fhir.Procedure.complication": "Complication",
    "fhir.Procedure.complicationDetail": "Complication Detail",
    "fhir.Procedure.followUp": "Follow Up",
    "fhir.Procedure.laterality": "Side",
    "fhir.Procedure.location": "Location",
    "fhir.Procedure.method": "Method",
    "fhir.Procedure.outcome": "Outcome",
    "fhir.Procedure.partOf": "Part Of",
    "fhir.Procedure.performed": "Performed",
    "fhir.Procedure.reasonCode": "Reason Code",
    "fhir.Procedure.reasonReference": "Reason Reference",
    "fhir.Procedure.report": "Report",
    "fhir.Procedure.status": "Status",
    "fhir.Procedure.subject": "Subject",
    "fhir.Procedure.usedCode": "Used Code",
    "fhir.Procedure.usedReference": "Used Reference",
    "fhir.ProcedureFocalDevice": "Focal Device",
    "fhir.ProcedureFocalDevice.action": "Action",
    "fhir.ProcedureFocalDevice.header": "Focal Device",
    "fhir.ProcedureFocalDevice.manipulated": "Manipulated Device",
    "fhir.ProcedureFocalDevices.header": "Focal Devices",
    "fhir.ProcedurePerformer": "Performer",
    "fhir.ProcedurePerformer.actor": "Actor",
    "fhir.ProcedurePerformer.header": "Procedure Performer",
    "fhir.ProcedurePerformer.onBehalfOf": "On Behalf Of",
    "fhir.ProcedurePerformers.header": "Procedure Performers",
    "fhir.Range.high": "Till",
    "fhir.Range.low": "From",
    "fhir.Specimen.Collection.bodySite": "Body Site",
    "fhir.Specimen.Collection.collected": "Collected",
    "fhir.Specimen.Collection.collector": "Collector",
    "fhir.Specimen.Collection.method": "Method",
    "fhir.Specimen.Collection.quantity": "Quantity",
    "fhir.Specimen.Container.additive": "Additive",
    "fhir.Specimen.Container.capacity": "Capacity",
    "fhir.Specimen.Container.description": "Description",
    "fhir.Specimen.Container.specimenQuantity": "Specimen Quantity",
    "fhir.Specimen.Container.type": "Type",
    "fhir.Specimen.Processing.additive": "Additive",
    "fhir.Specimen.Processing.description": "Description",
    "fhir.Specimen.Processing.procedure": "Procedure",
    "fhir.Specimen.Processing.time": "Time",
    "fhir.Specimen.collection": "Collection",
    "fhir.Specimen.container": "Container",
    "fhir.Specimen.parent": "Parent",
    "fhir.Specimen.processing": "Processing",
    "fhir.Specimen.receivedTime": "Time receipt",
    "fhir.Specimen.request": "Request",
    "fhir.Specimen.status": "Status",
    "fhir.Specimen.subject": "Subject",
    "fhir.Specimen.type": "Type",
    "fhir.Timing.code": "Code",
    "fhir.Timing.event": "Event",
    "fhir.Timing.header": "Timing",
    "fhir.TimingRepeat.boundsDuration": "Bounds Duration",
    "fhir.TimingRepeat.boundsPeriod": "Bounds Period",
    "fhir.TimingRepeat.boundsRange": "Bounds Range",
    "fhir.TimingRepeat.count": "Count",
    "fhir.TimingRepeat.countMax": "Count Max",
    "fhir.TimingRepeat.dayOfWeek": "Day of Week",
    "fhir.TimingRepeat.duration": "Duration",
    "fhir.TimingRepeat.durationMax": "Duration Max",
    "fhir.TimingRepeat.durationUnit": "Duration Unit",
    "fhir.TimingRepeat.frequency": "Frequency",
    "fhir.TimingRepeat.frequencyMax": "Frequency Max",
    "fhir.TimingRepeat.offset": "Offset",
    "fhir.TimingRepeat.period": "Period",
    "fhir.TimingRepeat.periodMax": "Period Max",
    "fhir.TimingRepeat.periodUnit": "Period Unit",
    "fhir.TimingRepeat.timeOfDay": "Time of Day",
    "fhir.TimingRepeat.when": "When",
    "fhir.address.city": "City",
    "fhir.administration-agreement.medication": "Medication",
    "fhir.administration-agreement.status": "Status",
    "fhir.administration-agreement.whenHandedOver": "When Handed Over",
    "fhir.administrative-gender.female": "Female",
    "fhir.administrative-gender.male": "Male",
    "fhir.administrative-gender.other": "Other",
    "fhir.administrative-gender.unknown": "Unknown",
    "fhir.allergy-intolerance-category.biologic": "Biologic",
    "fhir.allergy-intolerance-category.environment": "Environment",
    "fhir.allergy-intolerance-category.food": "Food",
    "fhir.allergy-intolerance-category.medication": "Medication",
    "fhir.allergy-intolerance-clinical-status.active": "Active",
    "fhir.allergy-intolerance-clinical-status.inactive": "Inactive",
    "fhir.allergy-intolerance-clinical-status.resolved": "Resolved",
    "fhir.allergy-intolerance-criticality.high": "High Risk",
    "fhir.allergy-intolerance-criticality.low": "Low Risk",
    "fhir.allergy-intolerance-criticality.unable-to-assess": "Unable to Assess Risk",
    "fhir.allergy-intolerance-reaction-severity.mild": "Mild",
    "fhir.allergy-intolerance-reaction-severity.moderate": "Moderate",
    "fhir.allergy-intolerance-reaction-severity.severe": "Severe",
    "fhir.allergy-intolerance-type.allergy": "Allergy",
    "fhir.allergy-intolerance-type.intolerance": "Intolerance",
    "fhir.allergy-intolerance-verification-status.confirmed": "Confirmed",
    "fhir.allergy-intolerance-verification-status.entered-in-error": "Entered in Error",
    "fhir.allergy-intolerance-verification-status.refuted": "Refuted",
    "fhir.allergy-intolerance-verification-status.unconfirmed": "Unconfirmed",
    "fhir.allergyintolerance-verification.presumed": "Presumed",
    "fhir.appointment-participant-required.information-only": "Information Only",
    "fhir.appointment-participant-required.optional": "Optional",
    "fhir.appointment-participant-required.required": "Required",
    "fhir.appointment-participant-status.accepted": "Accepted",
    "fhir.appointment-participant-status.declined": "Declined",
    "fhir.appointment-participant-status.needs-action": "Needs Action",
    "fhir.appointment-participant-status.tentative": "Tentative",
    "fhir.appointment-status.arrived": "Arrived",
    "fhir.appointment-status.booked": "Booked",
    "fhir.appointment-status.cancelled": "Cancelled",
    "fhir.appointment-status.entered-in-error": "Entered in Error",
    "fhir.appointment-status.fulfilled": "Fulfilled",
    "fhir.appointment-status.noshow": "No Show",
    "fhir.appointment-status.pending": "Pending",
    "fhir.appointment-status.proposed": "Proposed",
    "fhir.appointment.appointmentType": "Type",
    "fhir.appointment.end": "End",
    "fhir.appointment.minutesDuration": "Duration",
    "fhir.appointment.reason": "Reason",
    "fhir.appointment.serviceType": "Service",
    "fhir.appointment.specialty": "Specialty",
    "fhir.appointment.start": "Start",
    "fhir.appointment.status": "Status",
    "fhir.availability.nearline": "Nearline",
    "fhir.availability.offline": "Offline",
    "fhir.availability.online": "Online",
    "fhir.availability.unavailable": "Unavailable",
    "fhir.boolean.no": "No",
    "fhir.boolean.yes": "Yes",
    "fhir.composition-section-mode.changes": "Changes",
    "fhir.composition-section-mode.snapshot": "Snapshot",
    "fhir.composition-section-mode.working": "Working",
    "fhir.composition-status.amended": "Amended",
    "fhir.composition-status.appends": "Appends",
    "fhir.composition-status.entered-in-error": "Entered in Error",
    "fhir.composition-status.final": "Final",
    "fhir.composition-status.legal": "Legal",
    "fhir.composition-status.official": "Official",
    "fhir.composition-status.personal": "Personal",
    "fhir.composition-status.preliminary": "Preliminary",
    "fhir.composition-status.professional": "Professional",
    "fhir.composition-status.replaces": "Replaces",
    "fhir.composition-status.signs": "Signs",
    "fhir.composition-status.transforms": "Transforms",
    "fhir.condition-clinical-status.active": "Active",
    "fhir.condition-clinical-status.inactive": "Inactive",
    "fhir.condition-clinical-status.recurrence": "Recurrence",
    "fhir.condition-clinical-status.relapse": "Relapse",
    "fhir.condition-clinical-status.remission": "Remission",
    "fhir.condition-clinical-status.resolved": "Resolved",
    "fhir.condition-clinical-status.unknown": "Unknown",
    "fhir.condition-verification-status.confirmed": "Confirmed",
    "fhir.condition-verification-status.differential": "Differential",
    "fhir.condition-verification-status.entered-in-error": "Entered In Error",
    "fhir.condition-verification-status.provisional": "Provisional",
    "fhir.condition-verification-status.refuted": "Refuted",
    "fhir.condition-verification-status.unconfirmed": "Unconfirmed",
    "fhir.condition-verification-status.unknown": "Unknown",
    "fhir.consent.category": "Category",
    "fhir.consent.comment": "Comment",
    "fhir.consent.dateTime": "Date",
    "fhir.consent.disorder": "Disorder",
    "fhir.consent.exceptType": "Except Type",
    "fhir.consent.treatment": "Treatment",
    "fhir.consent.treatmentPermitted": "Treatment Permitted",
    "fhir.consent.type": "Type",
    "fhir.consent.verifiedWith": "Verified With",
    "fhir.contact-entity-type.admin": "Administrative",
    "fhir.contact-entity-type.bill": "Billing",
    "fhir.contact-entity-type.hr": "Human Resource",
    "fhir.contact-entity-type.patinf": "Patient",
    "fhir.contact-entity-type.payor": "Payer",
    "fhir.contact-entity-type.press": "Press",
    "fhir.contact-point-system.email": "Email",
    "fhir.contact-point-system.fax": "Fax",
    "fhir.contact-point-system.mail": "Email",
    "fhir.contact-point-system.other": "Other",
    "fhir.contact-point-system.pager": "Pager",
    "fhir.contact-point-system.phone": "Phone",
    "fhir.contact-point-system.sms": "SMS",
    "fhir.contact-point-system.url": "URL",
    "fhir.contact-point-use.home": "Private Phonenumber \/ Email Address",
    "fhir.contact-point-use.mobile": "Mobile",
    "fhir.contact-point-use.old": "Old",
    "fhir.contact-point-use.temp": "Temp",
    "fhir.contact-point-use.work": "Work",
    "fhir.coverage-status.active": "Active",
    "fhir.coverage-status.cancelled": "Cancelled",
    "fhir.coverage-status.draft": "Draft",
    "fhir.coverage-status.entered-in-error": "Entered in Error",
    "fhir.device-udi-entry-type.barcode": "Barcode",
    "fhir.device-udi-entry-type.card": "Card",
    "fhir.device-udi-entry-type.manual": "Manual",
    "fhir.device-udi-entry-type.rfid": "RFID",
    "fhir.device-udi-entry-type.self-reported": "Self Reported",
    "fhir.device-udi-entry-type.unknown": "Unknown",
    "fhir.device-use-statement.active": "Active",
    "fhir.device-use-statement.completed": "Completed",
    "fhir.device-use-statement.entered-in-error": "Entered in Error",
    "fhir.device-use-statement.intended": "Intended",
    "fhir.device-use-statement.on-hold": "On Hold",
    "fhir.device-use-statement.stopped": "Stopped",
    "fhir.diagnostic-report-status.amended": "Amended",
    "fhir.diagnostic-report-status.appended": "Appended",
    "fhir.diagnostic-report-status.cancelled": "Cancelled",
    "fhir.diagnostic-report-status.corrected": "Corrected",
    "fhir.diagnostic-report-status.entered-in-error": "Entered in Error",
    "fhir.diagnostic-report-status.final": "Final",
    "fhir.diagnostic-report-status.partial": "Partial",
    "fhir.diagnostic-report-status.preliminary": "Preliminary",
    "fhir.diagnostic-report-status.registered": "Registered",
    "fhir.diagnostic-report-status.unknown": "Unknown",
    "fhir.document-manifest-status.current": "Current",
    "fhir.document-manifest-status.entered-in-error": "Entered in Error",
    "fhir.document-manifest-status.superseded": "Superseded",
    "fhir.document-reference-doc-status.amended": "Amended",
    "fhir.document-reference-doc-status.appended": "Appended",
    "fhir.document-reference-doc-status.entered-in-error": "Entered in Error",
    "fhir.document-reference-doc-status.final": "Final",
    "fhir.document-reference-doc-status.preliminary": "Preliminary",
    "fhir.document-reference-relates-to-code.appends": "Appends",
    "fhir.document-reference-relates-to-code.replaces": "Replaces",
    "fhir.document-reference-relates-to-code.signs": "Signs",
    "fhir.document-reference-relates-to-code.transforms": "Transforms",
    "fhir.document-reference-status.current": "Current",
    "fhir.document-reference-status.entered-in-error": "Entered in Error",
    "fhir.document-reference-status.superseded": "Superseded",
    "fhir.email": "Email",
    "fhir.encounter-status.active": "Active",
    "fhir.encounter-status.arrived": "Arrived",
    "fhir.encounter-status.cancelled": "Cancelled",
    "fhir.encounter-status.completed": "Completed",
    "fhir.encounter-status.entered-in-error": "Entered in Error",
    "fhir.encounter-status.finished": "Finished",
    "fhir.encounter-status.in-progress": "In Progress",
    "fhir.encounter-status.onleave": "On Leave",
    "fhir.encounter-status.planned": "Planned",
    "fhir.encounter-status.reserved": "Reserved",
    "fhir.encounter-status.triaged": "Triaged",
    "fhir.encounter-status.unknown": "Unknown",
    "fhir.encounter.class": "Type",
    "fhir.encounter.episodeOfCare": "Episode of Care",
    "fhir.encounter.participants": "Participants",
    "fhir.encounter.period": "Period",
    "fhir.encounterClassHistories": "Encounter Class Histories",
    "fhir.encounterClassHistory": "Encounter Class History",
    "fhir.episode-of-care-status.active": "Active",
    "fhir.episode-of-care-status.cancelled": "Cancelled",
    "fhir.episode-of-care-status.entered-in-error": "Entered in Error",
    "fhir.episode-of-care-status.finished": "Finished",
    "fhir.episode-of-care-status.onhold": "On Hold",
    "fhir.episode-of-care-status.planned": "Planned",
    "fhir.episode-of-care-status.waitlist": "Waitlist",
    "fhir.fhir.AppointmentParticipant.actor.practitionerRole": "Practitioner Role",
    "fhir.flag-status.active": "Active",
    "fhir.flag-status.entered-in-error": "Entered in Error",
    "fhir.flag-status.inactive": "Inactive",
    "fhir.functionalOrMentalStatus.period": "Period",
    "fhir.functionalOrMentalStatus.status": "Status",
    "fhir.functionalOrMentalStatus.value": "Value",
    "fhir.healthInsurance.name": "Name",
    "fhir.healthInsurance.status": "Status",
    "fhir.healthInsurance.subscriberId": "Insurance Number",
    "fhir.identifier-use.official": "Official",
    "fhir.identifier-use.old": "Old",
    "fhir.identifier-use.secondary": "Secondary",
    "fhir.identifier-use.temp": "Temp",
    "fhir.identifier-use.usual": "Usual",
    "fhir.immunization-status.completed": "Completed",
    "fhir.immunization-status.entered-in-error": "Entered in Error",
    "fhir.immunization-status.not-done": "Not Done",
    "fhir.medication-request-intent.instance-order": "Instance Order",
    "fhir.medication-request-intent.order": "Order",
    "fhir.medication-request-intent.plan": "Plan",
    "fhir.medication-request-intent.proposal": "Proposal",
    "fhir.medication-request-on-hold.on-hold": "On Hold",
    "fhir.medication-request-routine.asap": "ASAP",
    "fhir.medication-request-routine.routine": "Routine",
    "fhir.medication-request-routine.stat": "Stat",
    "fhir.medication-request-routine.urgent": "Urgent",
    "fhir.medication-request-status.active": "Active",
    "fhir.medication-request-status.cancelled": "Cancelled",
    "fhir.medication-request-status.completed": "Completed",
    "fhir.medication-request-status.draft": "Draft",
    "fhir.medication-request-status.entered-in-error": "Entered in Error",
    "fhir.medication-request-status.stopped": "Stopped",
    "fhir.medication-request-status.unknown": "Unknown",
    "fhir.medication-statement-status.active": "Active",
    "fhir.medication-statement-status.completed": "Completed",
    "fhir.medication-statement-status.entered-in-error": "Entered in Error",
    "fhir.medication-statement-status.intended": "Intended",
    "fhir.medication-statement-status.on-hold": "On Hold",
    "fhir.medication-statement-status.stopped": "Stopped",
    "fhir.medication-statement-taken.n": "No",
    "fhir.medication-statement-taken.na": "Not Applicable",
    "fhir.medication-statement-taken.unk": "Unknown",
    "fhir.medication-statement-taken.y": "Yes",
    "fhir.medication-status.active": "Active",
    "fhir.medication-status.completed": "Completed",
    "fhir.medication-status.entered-in-error": "Entered in Error",
    "fhir.medication-status.in-progress": "In Progress",
    "fhir.medication-status.inactive": "Inactive",
    "fhir.medication-status.on-hold": "On Hold",
    "fhir.medication-status.preparation": "Preparation",
    "fhir.medication-status.stopped": "Stopped",
    "fhir.medicationUse.effective": "Date of use",
    "fhir.medicationUse.medication": "Medication",
    "fhir.medicationUse.status": "Status",
    "fhir.name-part-qualifier.BR": "First Names",
    "fhir.name-part-qualifier.CL": "Given Name",
    "fhir.name-part-qualifier.IN": "Initials",
    "fhir.name-use.anonymous": "Anonymous",
    "fhir.name-use.maiden": "Name changed for Marriage",
    "fhir.name-use.nickname": "Nickname",
    "fhir.name-use.official": "Official",
    "fhir.name-use.old": "Old",
    "fhir.name-use.temp": "Temporary",
    "fhir.name-use.usual": "Usual",
    "fhir.narrative-status.additional": "Additional",
    "fhir.narrative-status.empty": "Empty",
    "fhir.narrative-status.extensions": "Extensions",
    "fhir.narrative-status.generated": "Generated",
    "fhir.nl-core-address.adresSoort": "Address Kind",
    "fhir.nutrition-order-status.active": "Active",
    "fhir.nutrition-order-status.cancelled": "Cancelled",
    "fhir.nutrition-order-status.completed": "Completed",
    "fhir.nutrition-order-status.draft": "Draft",
    "fhir.nutrition-order-status.entered-in-error": "Entered In Error",
    "fhir.nutrition-order-status.on-hold": "On Hold",
    "fhir.nutrition-order-status.planned": "Planned",
    "fhir.nutrition-order-status.proposed": "Proposed",
    "fhir.nutrition-order-status.requested": "Requested",
    "fhir.nutritionAdvice.dateTime": "Date Time",
    "fhir.nutritionAdvice.oralDietFluidConsistencyType": "Consistency",
    "fhir.nutritionAdvice.oralDietType": "Diet",
    "fhir.nutritionAdvice.orderer": "Orderer",
    "fhir.nutritionAdvice.status": "Status",
    "fhir.observation-status.amended": "Amended",
    "fhir.observation-status.cancelled": "Cancelled",
    "fhir.observation-status.corrected": "Corrected",
    "fhir.observation-status.entered-in-error": "Entered in Error",
    "fhir.observation-status.final": "Final",
    "fhir.observation-status.preliminary": "Preliminary",
    "fhir.observation-status.registered": "Registered",
    "fhir.observation-status.unknown": "Unknown",
    "fhir.observation.components.header": "Components",
    "fhir.patientContact.name": "Name",
    "fhir.patientContact.relation": "Relation",
    "fhir.patientContact.role": "Role",
    "fhir.procedure-status.aborted": "Aborted",
    "fhir.procedure-status.completed": "Completed",
    "fhir.procedure-status.entered-in-error": "Entered In Error",
    "fhir.procedure-status.in-progress": "In Progress",
    "fhir.procedure-status.preparation": "Preparation",
    "fhir.procedure-status.suspended": "Suspended",
    "fhir.procedure-status.unknown": "Unknown",
    "fhir.specimen-status.available": "Available",
    "fhir.specimen-status.entered-in-error": "Entered in Error",
    "fhir.specimen-status.unavailable": "Unavailable",
    "fhir.specimen-status.unsatisfactory": "Unsatisfactory",
    "fhir.timing-repeat-day-of-week.friday": "Friday",
    "fhir.timing-repeat-day-of-week.monday": "Monday",
    "fhir.timing-repeat-day-of-week.saturday": "Saturday",
    "fhir.timing-repeat-day-of-week.sunday": "Sunday",
    "fhir.timing-repeat-day-of-week.thursday": "Thursday",
    "fhir.timing-repeat-day-of-week.tuesday": "Tuesday",
    "fhir.timing-repeat-day-of-week.wednesday": "Wednesday",
    "fhir.timing-repeat-unit.days": "Days",
    "fhir.timing-repeat-unit.hours": "Hours",
    "fhir.timing-repeat-unit.minutes": "Minutes",
    "fhir.timing-repeat-unit.months": "Months",
    "fhir.timing-repeat-unit.seconds": "Seconds",
    "fhir.timing-repeat-unit.weeks": "Weeks",
    "fhir.timing-repeat-unit.years": "Years",
    "fhir.v3-MaritalStatus.A": "No marriage contract",
    "fhir.v3-MaritalStatus.C": "Common Law",
    "fhir.v3-MaritalStatus.D": "Divorced",
    "fhir.v3-MaritalStatus.I": "Interlocutory",
    "fhir.v3-MaritalStatus.L": "Legally separated",
    "fhir.v3-MaritalStatus.M": "Married",
    "fhir.v3-MaritalStatus.P": "Polygamous",
    "fhir.v3-MaritalStatus.S": "Never married",
    "fhir.v3-MaritalStatus.T": "Domestic partner",
    "fhir.v3-MaritalStatus.U": "Unmarried",
    "fhir.v3-MaritalStatus.W": "Widowed",
    "fhir.v3-NullFlavor.UNK": "unknown",
    "grid.UnableToAdd": "Unable to add. Please try again.",
    "key": "en-us",
    "nav.btn.add": "New ",
    "nav.btn.add.applehealth": "Apple Health",
    "nav.btn.add.device": "Add Device",
    "nav.btn.add.measurement": "Add Measurement",
    "nav.btn.add.records": "Import Records",
    "ngb.alert.close": "Close",
    "ngb.carousel.next": "Next",
    "ngb.carousel.previous": "Previous",
    "ngb.carousel.slide-number": "Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.datepicker.next-month": "Next month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.select-year": "Select year",
    "ngb.pagination.first": "««",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.last": "»»",
    "ngb.pagination.last-aria": "Last",
    "ngb.pagination.next": "»",
    "ngb.pagination.next-aria": "Next",
    "ngb.pagination.previous": "«",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.AM": "{$INTERPOLATION}",
    "ngb.timepicker.HH": "HH",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.SS": "SS",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.hours": "Hours",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.toast.close-aria": "Close",
    "table.alcohol-use.column.period": "Period",
    "table.alcohol-use.column.use": "Use",
    "table.column.consistency": "Consistency",
    "table.column.date": "Date",
    "table.column.details": "Details",
    "table.column.diet": "Diet",
    "table.column.disease": "Disease",
    "table.column.email": "Email Addresses",
    "table.column.healthcareProvider": "Healthcare Provider",
    "table.column.name": "Name",
    "table.column.participants": "Health professional",
    "table.column.period": "Period",
    "table.column.phone": "Telephone Numbers",
    "table.column.role": "Role",
    "table.column.situation": "House Type",
    "table.column.source": "Source",
    "table.column.status": "Status",
    "table.column.substance": "Substance",
    "table.column.type": "Type",
    "table.column.use": "Use",
    "table.column.vaccine": "Vaccine",
    "table.column.value": "Value",
    "table.column.weight": "Weight",
    "table.drug-use.column.period": "Period",
    "table.drug-use.column.use": "Use",
    "table.entry.manual": "Manual",
    "table.tobacco-use.column.period": "Period",
    "table.tobacco-use.column.use": "Use"
}
  }